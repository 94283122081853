import React from "react";
import "./styles.scss";

interface Props {
	fill?: string;
}

const UserIcon = ({ fill = "#F8F9FA" }: Props) => {
	return (
		<svg
			width="31"
			height="30"
			viewBox="0 0 31 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.0407 15.1307C18.5995 15.1307 21.5115 11.9508 21.5115 8.06533C21.5115 4.17987 18.6004 1 15.0407 1C11.4809 1 8.57081 4.17987 8.57081 8.06533C8.57081 11.9508 11.4819 15.1307 15.0407 15.1307ZM15.0407 16.5736C10.7548 16.5745 2.10001 18.9592 2.10001 23.6399V27.8987C6.3807 28.5828 10.7059 28.9509 15.0407 29C19.3754 28.9506 23.7007 28.5822 27.9813 27.8977V23.6399C27.9813 18.9592 19.3275 16.5736 15.0407 16.5736Z"
				stroke={fill}
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export default UserIcon;
