import React, { useState } from "react";
import ArrowDropIcon from "../../assets/SvgIcons/ArrowDropIcon";
import UserIcon from "../../assets/images/usericon2.svg";
import { UserConfigModal } from "../UserConfigModal";
import { UserSelector } from "./UserSelector";
import "./styles.scss";

const UserDropdown = () => {
	const [showConfigModal, setShowConfigModal] = useState<boolean>(false);
	const [showUserSelector, setShowUserSelector] = useState<boolean>(false);

	const handleConfigModalToggle = () => {
		setShowConfigModal(!showConfigModal);
	};

	const handlerUserSelectorToggle = () => {
		setShowUserSelector(!showUserSelector);
	};

	const handleClientDropDownIcon = () => {
		return showUserSelector ? (
			<div onClick={handlerUserSelectorToggle}>
				<ArrowDropIcon
					width="10px"
					height="10px"
					fill="#025CB7"
					direction="up"
				/>
			</div>
		) : (
			<div onClick={handlerUserSelectorToggle}>
				<ArrowDropIcon
					width="10px"
					height="10px"
					fill="#025CB7"
					direction="down"
				/>
			</div>
		);
	};

	const userSelectorContent = () => {
		return showUserSelector ? (
			<UserSelector
				{...{
					handleConfigModalToggle,
					onClose: handlerUserSelectorToggle
				}}
			/>
		) : (
			<></>
		);
	};

	const userContentBadge = () => {
		return (
			<>
				<div className="user-area">
					<img
						width="30px"
						src={UserIcon}
						alt="Usuário ícone"
						style={{ border: "solid #red " }}
						onClick={handlerUserSelectorToggle}
					/>
					{handleClientDropDownIcon()}
					{userSelectorContent()}
				</div>
			</>
		);
	};

	return (
		<>
			{userContentBadge()}
			{showConfigModal && <UserConfigModal onClose={handleConfigModalToggle} />}
		</>
	);
};

export default UserDropdown;
