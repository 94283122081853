import React from "react";
import "./styles.scss";

interface AlertIconProps {
	width: string;
	height: string;
	fill?: string;
	onClick?: () => void;
}

const SearchIcon = ({ fill, width, height, onClick }: AlertIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 50 40"
			onClick={onClick}
		>
			<defs></defs>
			<g transform="translate(-1603 -7423)" fill={fill}>
				<rect
					className="a"
					width="50"
					height="40"
					transform="translate(1603 7423)"
				/>
				<path
					d="M0,18.191A11.8,11.8,0,0,1,18.83,8.7L27.015.512a1.748,1.748,0,0,1,2.472,2.472L21.3,11.17A11.809,11.809,0,1,1,0,18.191Zm3.374,0a8.437,8.437,0,1,0,14.864-5.459l-.969-.969a8.432,8.432,0,0,0-13.9,6.429Z"
					transform="translate(1645.161 7455.47) rotate(171)"
				/>
			</g>
		</svg>
	);
};

export default SearchIcon;
