import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowDropIcon from "../../assets/SvgIcons/ArrowDropIcon";
import { store } from "../../store";
import ClickAwayListener from "../../utils/ClickAwayListener";
import { LanguageSelector } from "./LanguageSelector";
import "./styles.scss";

export const SwitchLanguage = () => {
	const [showLanguageSelector, setShowLanguageSelector] =
		useState<boolean>(false);

	const language =
		store.getState().auth.language || location.pathname.split("/")[1] || "pt";

	const { t, i18n } = useTranslation();

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [store.getState().auth.language]);

	const languageOptions = [
		{
			value: "en",
			label: t("language.english"),
			language: "EN",
			alt: t("language.english")
		},
		{
			value: "pt",
			label: t("language.portuguese"),
			language: "PT",
			alt: t("language.portuguese")
		},
		{
			value: "es",
			label: t("language.spanish"),
			language: "ES",
			alt: t("language.spanish")
		},
		{
			value: "ru",
			label: t("language.russian"),
			language: "RU",
			alt: t("language.russian")
		}
	];

	const handlerLanguageSelectorToggle = () => {
		setShowLanguageSelector(!showLanguageSelector);
	};

	const LanguageSelectorContent = () => {
		return showLanguageSelector ? (
			<LanguageSelector
				{...{
					options: languageOptions,
					onClose: handlerLanguageSelectorToggle
				}}
			/>
		) : (
			<></>
		);
	};

	const handleLanguageDropDownIcon = () => {
		return showLanguageSelector ? (
			<ArrowDropIcon width="10px" height="10px" fill="#025CB7" direction="up" />
		) : (
			<ArrowDropIcon
				width="10px"
				height="10px"
				fill="#025CB7"
				direction="down"
			/>
		);
	};

	return (
		<ClickAwayListener onClickAway={() => setShowLanguageSelector(false)}>
			<div className="language-area" onClick={handlerLanguageSelectorToggle}>
				<p>
					{
						languageOptions.filter((option) => option.value === language)[0]
							?.language
					}
				</p>
				{handleLanguageDropDownIcon()}
				{LanguageSelectorContent()}
			</div>
		</ClickAwayListener>
	);
};
