import { Button, Input, LoadingProgress, Modal } from "@praticabr/ppsa";
import React from "react";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ProgressBarCircular } from "..";
import movIcon from "../../assets/images/MOV.svg";
import mp4Icon from "../../assets/images/MP4.svg";
import pdfIcon from "../../assets/images/PDF.svg";
import { UploadFile } from "../../models";
import { upload } from "../../services";

type Props = {
	onClose: () => void;
	filesForm: UseFormReturn<UploadFile, "files", undefined>;
	fieldFiles: UseFieldArrayReturn<UploadFile, "files", "id">;
	indexItem?: number;
};

const acceptedFileTypes = [
	"image/png",
	"image/jpeg",
	"image/jpg",
	"application/pdf",
	"video/mp4",
	"video/mov",
	"video/quicktime"
];

const MAX_SIZE = 30 * 1024 * 1024;

export const UploadModal = ({
	onClose,
	filesForm,
	fieldFiles,
	indexItem
}: Props) => {
	const { t } = useTranslation();

	const [uploading, setUploading] = React.useState(false);
	const [sumProgress, setSumProgress] = React.useState(
		fieldFiles.fields.filter((field) => field.progress === 100).length * 100
	);

	const addItem = () => {
		const element = document.getElementById("file-upload");
		element?.click();
	};

	const {
		register,
		formState: { errors }
	} = filesForm;

	const { fields, replace, update } = fieldFiles;

	const filePreview = (file: any) => {
		switch (file.type) {
			case "video/mp4":
				return mp4Icon;
			case "video/mov":
				return movIcon;
			case "video/quicktime":
				return movIcon;
			case "application/pdf":
				return pdfIcon;
			default:
				return URL.createObjectURL(file);
		}
	};

	const onUploadFile = (files: File[]) => {
		const newFormattedFile = Array.from(files)
			.map((file) => {
				let newDefaultFile;
				if (acceptedFileTypes.includes(file.type) && file.size < MAX_SIZE) {
					newDefaultFile = {
						name: file.name,
						preview: filePreview(file),
						url: "",
						type: file.type,
						file
					};
				} else {
					file.size < MAX_SIZE && toast.error(t("uploadFiles.toast.typeError"));
					file.size > MAX_SIZE && toast.error(t("uploadFiles.toast.sizeError"));
				}
				return newDefaultFile;
			})
			.filter((file) => file !== undefined);

		const newUploadedFiles = [...fields, ...newFormattedFile];

		replace(
			newUploadedFiles.map((field, index) => ({
				...field,
				description: filesForm.getValues(`files.${index}.description`)
			})) as any
		);
	};

	const onUploadClick = (data: UploadFile) => {
		let sum = sumProgress;
		Array.from(data.files).forEach((file, index) => {
			if (file.url === "") {
				upload(file, (e) => {
					setUploading(true);
					file.progress = e;
					update(index, file);
				}).then((response) => {
					const { key, location } = response.data;
					if (response.data) {
						file.url = location;
						file.name = key;
						file.item = indexItem;
						update(index, file);
						sum += file.progress;
						if (sum === fields.length * 100) {
							setUploading(false);
							onClose();
						}
					}
				});
			}
		});
	};

	const handleCancel = () => {
		filesForm.reset();
		clearFields();
		onClose();
	};

	const clearFields = () => {
		fields.forEach((field) => {
			const removeIndex = fields.findIndex(
				(f) => f.url === "" || f.url === undefined
			);
			if (removeIndex !== -1) {
				fieldFiles.remove(removeIndex);
			}
		});
	};

	return (
		<>
			<Modal.root>
				<Modal.header
					onClose={handleCancel}
					title={t("uploadFiles.titleModal")}
				/>
				<Modal.body>
					<Modal.body.main>
						<div className="modal-upload-files">
							<header>{t("uploadFiles.subText")}:</header>
							<div className="upload-file-container">
								{fields.map((file, index) => (
									<div key={file.id} className="preview-file-container">
										<div className="preview-files">
											{uploading && (
												<ProgressBarCircular
													progress={file.progress}
													width={50}
												/>
											)}
											<img
												id="file-preview"
												src={file.preview || file.url}
												alt=""
											/>
										</div>
										<Input
											placeholder={t("uploadFiles.input")}
											variant="light"
											{...register(`files.${index}.description`, {
												required: {
													value: true,
													message: t("uploadFiles.requiredAlert")
												}
											})}
											errors={errors?.files?.[index]?.description}
											defaultValue={file.description}
										/>
									</div>
								))}
								<div className="preview-file-container">
									<div className="preview-files-empty" onClick={addItem}>
										<div>+</div>
										<input
											id="file-upload"
											type="file"
											multiple
											accept=".jpg, .png, .jpeg, .mp4, .mov, .pdf"
											style={{ display: "none" }}
											onChange={(e) =>
												onUploadFile(e.target.files as unknown as File[])
											}
										/>
									</div>
									<Input
										placeholder={t("uploadFiles.input")}
										variant="light"
										disabled
									/>
								</div>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="upload-files-actions">
							<Button
								variant="confirmation-solid"
								size="lg"
								title={t("uploadFiles.submitAction")}
								onClick={filesForm.handleSubmit(onUploadClick)}
								disabled={uploading}
							/>
							<Button
								variant="cancellation"
								size="lg"
								title={t("uploadFiles.cancelAction")}
								onClick={handleCancel}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{uploading && <LoadingProgress text={t("uploadFiles.loading")} />}
		</>
	);
};
