import React from "react";
import "./styles.scss";

interface PaymentIconProps {
	width?: string;
	height?: string;
	fill?: string;
}

const PaymentIcon = ({ fill = "#F8F9FA", width, height }: PaymentIconProps) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1_24)">
				<path
					d="M15 30C12.0333 30 9.13319 29.1203 6.66645 27.472C4.19972 25.8238 2.27713 23.4811 1.14181 20.7403C0.00649924 17.9994 -0.290551 14.9834 0.288227 12.0736C0.867006 9.16393 2.29562 6.49119 4.3934 4.3934C6.49119 2.29562 9.16393 0.867006 12.0736 0.288227C14.9834 -0.290551 17.9994 0.00649924 20.7403 1.14181C23.4811 2.27713 25.8238 4.19972 27.472 6.66645C29.1203 9.13319 30 12.0333 30 15C29.9952 18.9768 28.4134 22.7893 25.6013 25.6013C22.7893 28.4134 18.9768 29.9952 15 30ZM15 1.65841C12.3613 1.65841 9.78182 2.44088 7.58781 3.90687C5.39379 5.37286 3.68377 7.45654 2.67397 9.8944C1.66418 12.3323 1.39997 15.0148 1.91476 17.6028C2.42955 20.1908 3.70021 22.5681 5.56607 24.4339C7.43193 26.2998 9.80917 27.5705 12.3972 28.0852C14.9852 28.6 17.6678 28.3358 20.1056 27.326C22.5435 26.3162 24.6271 24.6062 26.0931 22.4122C27.5591 20.2182 28.3416 17.6387 28.3416 15C28.3375 11.4629 26.9305 8.07177 24.4294 5.57063C21.9282 3.06949 18.5371 1.66253 15 1.65841Z"
					fill={fill}
				/>
				<path
					d="M15.39 13.8996C13.1196 13.3092 12.39 12.6996 12.39 11.7492C12.39 10.6596 13.4004 9.8988 15.09 9.8988C16.8696 9.8988 17.5296 10.7484 17.5896 11.9988H19.8C19.7772 11.0936 19.4469 10.2231 18.8635 9.53059C18.28 8.8381 17.4782 8.36487 16.59 8.1888V6H13.59V8.16C11.6496 8.58 10.0896 9.84 10.0896 11.7696C10.0896 14.0796 12 15.2292 14.79 15.9C17.2896 16.5 17.79 17.3796 17.79 18.3096C17.79 18.9996 17.3004 20.1 15.09 20.1C13.0296 20.1 12.2196 19.1796 12.1104 18H9.90959C9.98573 18.9655 10.394 19.8748 11.0651 20.5731C11.7361 21.2715 12.6283 21.7158 13.59 21.8304V24H16.59V21.8496C18.54 21.48 20.0904 20.3496 20.0904 18.3C20.0892 15.4596 17.6592 14.49 15.39 13.8996Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_24">
					<rect width="30" height="30" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PaymentIcon;
