import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GetDistributors } from "../models/usecases/get-distributors";
import { getListDistributors } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const DistributorContext = createContext({
	list: {} as GetDistributors.Return,
	isLoading: false,
	limit: 19,
	total: 1,
	offset: 1,
	onPageChange: (page: number) => {},
	onSearchParams: (searchTerm: string) => {},
	onGetDistributorLists: () => {}
});

const defaultParams = defaultListParams();

export const DistributorProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [distributorsList, setDistributorsList] =
		useState<GetDistributors.Return>({} as GetDistributors.Return);

	const params = useRef<ListParams>(defaultParams);

	const { t } = useTranslation();

	const handleGetDistributorLists = async () => {
		try {
			setIsLoading(true);
			const currentPage = params.current.offset - 1;
			const response = await getListDistributors({
				...params.current,
				offset: currentPage,
				term: params.current.term || ""
			});
			setDistributorsList(response.data);
			updateParams(
				Math.ceil(response.data.total / params.current.limit),
				"total"
			);
		} catch (error) {
			toast.error(t("userContext.getAccountsListsError"));
		} finally {
			setIsLoading(false);
		}
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		handleGetDistributorLists();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		params.current = updateListParams(value, field, params.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetDistributorLists();
	};

	const value = {
		list: distributorsList,
		...params.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetDistributorLists: handleGetDistributorLists
	};

	return (
		<DistributorContext.Provider value={value}>
			{children}
		</DistributorContext.Provider>
	);
};
