import { GenericTable, LoadingProgress, Paginator } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertSvg from "../../assets/SvgIcons/AlertSvg";
import AproveCircularSvg from "../../assets/SvgIcons/AproveCircularSvg";
import CircularConfigSvg from "../../assets/SvgIcons/CircularConfigSvg";
import FinancialSvg from "../../assets/SvgIcons/FinancialSvg";
import { HeaderSection, IncludeServiceModal } from "../../components";
import { AuthContext, TechnicalSupportsContext } from "../../context";
import i18n from "../../i18n/i18n";
import { store } from "../../store";
import "./styles.scss";

const distributorCodes = ["DE", "ITD", "FD"];

export const ServiceList = () => {
	const {
		list,
		total,
		offset,
		isLoading,
		onPageChange,
		onSearchParams,
		onLegendChange
	} = useContext(TechnicalSupportsContext);
	const { user } = useContext(AuthContext);

	const [showModal, setShowModal] = useState(false);

	const [legendSelect, setLegendSelect] = useState<string>("");
	const language = i18n.language || store.getState().auth.language;

	const { availableDistributors = [], distributorId = null } = useSelector(
		(state: any) => state.auth
	);

	const navigate = useNavigate();

	const { t } = useTranslation();

	const isDistributor =
		user?.role?.split("_")[0] === "distributor" ||
		user?.role?.split("_")[0] === "comex";

	const isDistributorAvailable = (): boolean => {
		if (!availableDistributors?.length) {
			return false;
		}

		const distributorCode = availableDistributors?.filter(
			(item: any) => item.distributor.id === distributorId
		)[0].distributor.codeProtheus;

		return !distributorCodes.includes(distributorCode);
	};

	useEffect(() => {
		onLegendChange(legendSelect);
	}, [legendSelect]);

	const onSearch = (search: string) => {
		onSearchParams(search);
	};

	const handleSelectService = (e: any) => {
		navigate(`/${language}/services/${e.id}`);
	};

	const legends = {
		created: {
			key: "created",
			status: <CircularConfigSvg />,
			label: t("servicePage.legends.technical")
		},
		"technical-approved": {
			key: "technical-approved",
			status: <FinancialSvg />,
			label: t("servicePage.legends.financial")
		},
		"financial-approved": {
			key: "financial-approved",
			status: <AproveCircularSvg />,
			label: t("servicePage.legends.approved")
		},
		reproved: {
			key: "reproved",
			status: <AlertSvg />,
			label: t("servicePage.legends.reproved")
		}
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	return (
		<>
			<article className="service-list">
				<HeaderSection.root>
					<HeaderSection.search
						text={t("servicePage.searchHeader")}
						onChangeValues={onSearch}
					/>
					<HeaderSection.legend>
						{Object.values(legends).map((legend) => {
							return (
								<div
									className="legend no-select"
									key={legend.key}
									onClick={() =>
										setLegendSelect((prevLegend) =>
											prevLegend === legend.key ? "" : legend.key
										)
									}
								>
									{legend.status}
									<span
										className={`${legendSelect === legend.key && "active"}`}
									>
										{legend.label}
									</span>
								</div>
							);
						})}
					</HeaderSection.legend>
					{isDistributor && isDistributorAvailable() && (
						<HeaderSection.button
							text={t("servicePage.buttonHeader")}
							onClick={() => setShowModal(true)}
						/>
					)}
				</HeaderSection.root>
				<div className="service-table">
					<GenericTable
						onRowClicked={handleSelectService}
						data={[
							...(list?.technicalSupport?.map((item) => {
								const value = item?.replacedParts?.reduce(
									(sum, part) => sum + part?.amount * part?.historicalValue,
									0
								);
								return {
									...item,
									status:
										legends[
											item.status === "financial-reproved" ||
											item.status === "technical-reproved"
												? "reproved"
												: (item.status as keyof typeof legends)
										]?.status,
									createdAt: new Date(item?.createdAt).toLocaleDateString(
										i18n.language,
										{
											timeZone: "UTC"
										}
									),
									distributorCode: `${item?.equipment?.salesRecord?.distributor?.codeProtheus || ""} - ${item?.equipment?.salesRecord?.distributor?.name || ""}`,
									updatedAt: new Date(
										item?.events[item.events?.length - 1].createdAt
									).toLocaleDateString(i18n.language, {
										timeZone: "UTC"
									}),
									clientId: item?.equipment?.salesRecord?.client.name,
									serialNumber: item?.equipment?.serialNumber,
									model:
										i18n.language === "en"
											? item.equipment?.equipmentModel.descriptionEn
											: i18n.language === "es"
												? item.equipment?.equipmentModel.descriptionEs
												: item.equipment?.equipmentModel.descriptionPt || "-",
									value: value.toLocaleString("en")
								};
							}) || [])
						]}
						columns={
							isDistributor
								? [
										{
											key: "status",
											header: "",
											width: "32px"
										},
										{
											key: "id",
											header: t("servicePage.tableColumns.serviceId"),
											width: "calc(15% - 32px)"
										},
										{
											key: "createdAt",
											header: t("servicePage.tableColumns.createdAt"),
											width: "10%"
										},
										{
											key: "updatedAt",
											header: t("servicePage.tableColumns.updatedAt"),
											width: "10%"
										},
										{
											key: "clientId",
											header: t("servicePage.tableColumns.client"),
											width: "15%"
										},
										{
											key: "serialNumber",
											header: t("servicePage.tableColumns.serialNumber"),
											width: "5%"
										},
										{
											key: "model",
											header: t("servicePage.tableColumns.description"),
											width: "35%"
										},
										{
											key: "value",
											header: t("servicePage.tableColumns.value"),
											width: "10%"
										}
									]
								: [
										{
											key: "status",
											header: "",
											width: "32px"
										},
										{
											key: "id",
											header: t("servicePage.tableColumns.serviceId"),
											width: "calc(15% - 32px)"
										},
										{
											key: "createdAt",
											header: t("servicePage.tableColumns.createdAt"),
											width: "10%"
										},
										{
											key: "distributorCode",
											header: t("servicePage.tableColumns.distributorId"),
											width: "15%"
										},
										{
											key: "updatedAt",
											header: t("servicePage.tableColumns.updatedAt"),
											width: "10%"
										},
										{
											key: "clientId",
											header: t("servicePage.tableColumns.client"),
											width: "10%"
										},
										{
											key: "serialNumber",
											header: t("servicePage.tableColumns.serialNumber"),
											width: "5%"
										},
										{
											key: "model",
											header: t("servicePage.tableColumns.description"),
											width: "25%"
										},
										{
											key: "value",
											header: t("servicePage.tableColumns.value"),
											width: "10%"
										}
									]
						}
					/>
					{!!list?.technicalSupport?.length && (
						<div className="total-value">
							<p>TOTAL:</p>
							<p>{list.totalValue?.toLocaleString("en")}</p>
						</div>
					)}
				</div>
				{!!list?.technicalSupport?.length && (
					<Paginator
						totalPages={total}
						currentPage={offset}
						onPageChange={onPageChange}
					/>
				)}
			</article>
			{showModal && <IncludeServiceModal onClose={handleCloseModal} />}
			{isLoading && <LoadingProgress />}
		</>
	);
};
