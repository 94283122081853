import React from "react";

interface Props {}

const NotificationBellSvg: React.FC<Props> = () => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 28 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="notification-bell-svg"
		>
			<path
				className="notification-svg"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.4581 28.7171H10.4746C10.5041 29.6147 10.8883 30.4639 11.5428 31.0789C12.1974 31.694 13.0691 32.0247 13.9669 31.9986C14.8645 32.0242 15.7357 31.6934 16.39 31.0784C17.0442 30.4634 17.4283 29.6144 17.4581 28.7171ZM0 26.3706H27.9296V25.4344L24.4384 24.2614V13.9484C24.4391 13.2867 24.3881 12.6259 24.2858 11.9721C23.7746 12.2971 23.2115 12.5321 22.6208 12.6666C22.9993 15.7939 23.1283 18.9462 23.0069 22.0941C22.9984 22.9238 22.9909 23.7078 22.9909 24.497C22.4281 21.6765 22.0472 18.8228 21.8507 15.9535C21.7557 14.7793 21.6726 13.7597 21.5307 12.7999H21.4336C20.0195 12.7984 18.6638 12.236 17.6639 11.2363C16.664 10.2366 16.1017 8.88127 16.1003 7.46747C16.0983 6.11885 16.5797 4.98596 17.5 4C17.1949 3.90188 16.9045 3.6495 16.5845 3.57805V2.46236C16.5625 1.78936 16.2747 1.15264 15.7841 0.691317C15.2935 0.229997 14.6401 -0.0181859 13.9669 0.00107409C13.2935 -0.0184733 12.6399 0.229446 12.1491 0.690796C11.6583 1.15215 11.3703 1.78917 11.3483 2.46236V3.57805C9.02558 4.06217 6.96149 5.38212 5.54779 7.28729C4.16674 9.23028 3.44621 11.5651 3.49232 13.9484V24.2614L0 25.4344V26.3706Z"
				fill="black"
			/>
			<path
				className="bell-svg"
				d="M26 7.5C26 9.98528 23.9853 12 21.5 12C19.0147 12 17 9.98528 17 7.5C17 5.01472 19.0147 3 21.5 3C23.9853 3 26 5.01472 26 7.5Z"
				fill="#FF0000"
			/>
		</svg>
	);
};

export default NotificationBellSvg;
