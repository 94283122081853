import React, { ReactNode, createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetDistributorDashboard } from "../models/usecases/get-distributor-dashboard";
import { getDashboardList } from "../services";
import { store } from "../store";

export const DashboardContext = createContext({
	list: {} as GetDistributorDashboard.Return | undefined,
	isLoading: false,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onGetDashboardList: () => {},
	onChangeDate: (startDate: string, endDate: string) => {}
});

export const DashboardProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [DashboardList, setDashboardList] =
		useState<GetDistributorDashboard.Return>();

	const user = store.getState().auth;

	const navigate = useNavigate();

	useEffect(() => {
		user.role !== "distributor_user" &&
			location.pathname === `/${user.language}/home` &&
			navigate(`/${user.language}/sales`);
	}, [user]);

	const { t } = useTranslation();

	const handleGetDashboardList = async (
		startDate?: string,
		endDate?: string
	) => {
		try {
			setIsLoading(true);
			const data: GetDistributorDashboard.Params = {
				equipmentParams: {
					equipmentStartDate: undefined,
					equipmentEndDate: undefined,
					equipmentLimit: 5
				},
				partParams: {
					partStartDate: startDate,
					partEndDate: endDate,
					partLimit: 6
				}
			};
			const response = await getDashboardList(data);
			setDashboardList(response.data);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast.error(t("dashboardContext.getDashboardError"));
		}
	};

	const onSearchParams = (term: string) => {
		handleGetDashboardList();
	};

	const onPageChange = (page: number) => {
		handleGetDashboardList();
	};

	const onChangeDate = (startDate: string, endDate: string) => {
		handleGetDashboardList(startDate, endDate);
	};

	const value = {
		list: DashboardList,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetDashboardList: handleGetDashboardList,
		onChangeDate
	};

	return (
		<DashboardContext.Provider value={value}>
			{children}
		</DashboardContext.Provider>
	);
};
