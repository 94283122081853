import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	CommentModal,
	FinancialApprovalModal,
	ReproveModal,
	TechnicalApprovalModal
} from "../../../components";
import { ExtraCostModal } from "../../../components/ExtraCostModal";
import { AuthContext, TechnicalSupportsContext } from "../../../context";
import ClickAwayListener from "../../../utils/ClickAwayListener";
import "./styles.scss";

export const ActionButton = () => {
	const { user } = useContext(AuthContext);
	const { technicalSupport } = useContext(TechnicalSupportsContext);
	const [showMoreOptions, setShowMoreOptions] = useState(false);
	const [showFinancialApproveModal, setShowFinancialApproveModal] =
		useState(false);
	const [showTechnicalApproveModal, setShowTechnicalApproveModal] =
		useState(false);
	const [showReproveModal, setShowReproveModal] = useState(false);
	const [showCommentModal, setShowCommentModal] = useState(false);
	const [showExtraCostModal, setShowExtraCostModal] = useState(false);
	const [typeComment, setTypeComment] = useState<
		"NEW-COMMENT" | "NEW-INTERNAL-COMMENT"
	>("NEW-COMMENT");

	const { t } = useTranslation();

	const partsToApprove = technicalSupport?.replacedParts?.filter(
		(item) =>
			!technicalSupport?.approvals
				.map((b) =>
					b.approvedParts
						?.filter((part) => part.part.code !== "extraCost")
						.some((part) => part.part.id === item.part.id)
				)
				.includes(true)
	);

	const technicalSupportToApprove = technicalSupport?.approvals?.filter(
		(item) =>
			!item.approvedParts.filter((part) => part.part.code === "extraCost")
	);

	const hasComexUser = user?.role?.split("_")[0] === "comex";

	const hasDistributorUser = user?.role?.split("_")[0] === "distributor";

	const hasTechnicalRequest =
		hasComexUser && partsToApprove && partsToApprove?.length > 0;

	const hasTechnicalApproval =
		hasComexUser &&
		technicalSupportToApprove &&
		technicalSupportToApprove?.length === 0;

	const approvedParts = technicalSupport?.approvals
		.filter((approval) => approval.type === "TECHNICAL-APPROVED")
		.map((approval) =>
			approval.approvedParts.filter((part) => !part.partReplacement)
		);

	const creditValue =
		user?.role?.split("_")[0] === "financial" &&
		approvedParts?.reduce(
			(acc, part) =>
				acc +
				part.reduce((acc, part) => acc + part.amount * part.historicalValue, 0),
			0
		);

	const handleMoreOptions = () => {
		setShowMoreOptions(!showMoreOptions);
	};

	const handleClose = () => {
		setShowMoreOptions(false);
		setShowReproveModal(false);
		setShowCommentModal(false);
		setShowExtraCostModal(false);
		setShowFinancialApproveModal(false);
		setShowTechnicalApproveModal(false);
	};

	const dueDate = new Date();
	dueDate.setDate(dueDate.getDate() + 60);

	return (
		<>
			<div className="service-action">
				<ClickAwayListener onClickAway={() => setShowMoreOptions(false)}>
					<div className="main">
						<button onClick={handleMoreOptions}>
							{showMoreOptions ? "-" : "+"}
						</button>
						{showMoreOptions && (
							<div
								className="more-options"
								style={{ width: hasDistributorUser ? "100px" : "160px" }}
							>
								{(creditValue || hasTechnicalRequest) && (
									<span
										className="no-select"
										onClick={() => {
											if (creditValue) {
												setShowMoreOptions(false);
												setShowFinancialApproveModal(true);
											}
											if (hasTechnicalRequest) {
												setShowMoreOptions(false);
												setShowTechnicalApproveModal(true);
											}
										}}
									>
										{t("serviceDetailPage.actions.approve")}
									</span>
								)}
								{(creditValue ||
									(hasTechnicalRequest && hasTechnicalApproval)) && (
									<span
										className="no-select"
										onClick={() => {
											setShowMoreOptions(false);
											setShowReproveModal(true);
										}}
									>
										{t("serviceDetailPage.actions.reprove")}
									</span>
								)}
								<span
									className="no-select"
									onClick={() => {
										setTypeComment("NEW-COMMENT");
										setShowMoreOptions(false);
										setShowCommentModal(true);
									}}
								>
									{t("serviceDetailPage.actions.comment")}
								</span>
								{!hasDistributorUser && (
									<span
										className="no-select"
										onClick={() => {
											setTypeComment("NEW-INTERNAL-COMMENT");
											setShowMoreOptions(false);
											setShowCommentModal(true);
										}}
									>
										{t("serviceDetailPage.actions.internalComment")}
									</span>
								)}
								{hasComexUser && (
									<span
										className="no-select"
										onClick={() => {
											setShowMoreOptions(false);
											setShowExtraCostModal(true);
										}}
									>
										{t("serviceDetailPage.actions.cost")}
									</span>
								)}
							</div>
						)}
					</div>
				</ClickAwayListener>
			</div>
			{showFinancialApproveModal && (
				<FinancialApprovalModal onClose={handleClose} />
			)}
			{showTechnicalApproveModal && (
				<TechnicalApprovalModal onClose={handleClose} />
			)}
			{showReproveModal && <ReproveModal onClose={handleClose} />}
			{showCommentModal && (
				<CommentModal onClose={handleClose} type={typeComment} />
			)}
			{showExtraCostModal && <ExtraCostModal onClose={handleClose} />}
		</>
	);
};
