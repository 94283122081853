import React from "react";

interface Props {}

const AproveCircularSvg: React.FC<Props> = () => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.7157 0 0 6.71573 0 15C0 23.2843 6.7157 30 15 30ZM4.32202 15.7124L11.4722 22.6859C11.6785 22.887 11.9583 23 12.25 23C12.5416 23 12.8214 22.887 13.0277 22.6859L25.678 10.3481C25.8842 10.1469 26 9.87411 26 9.58962C26 9.30515 25.8842 9.03231 25.678 8.83112L24.1224 7.31412C23.9163 7.11299 23.6365 7 23.3447 7C23.0531 7 22.7733 7.11299 22.567 7.31412L12.2496 17.376L7.43298 12.6784C7.22668 12.4772 6.9469 12.3642 6.65527 12.3642C6.36353 12.3642 6.08374 12.4772 5.87756 12.6784L4.32202 14.1954C4.11584 14.3966 4 14.6694 4 14.9539C4 15.2383 4.11584 15.5112 4.32202 15.7124Z"
				fill="black"
				className="aprove-circular-svg"
			/>
		</svg>
	);
};

export default AproveCircularSvg;
