import { Button, Input, Modal, Textarea } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AuthContext, TechnicalSupportsContext } from "../../context";
import { AddFinancialReprove } from "../../models/usecases/add-financial-reprove";
import "./styles.scss";

interface Props {
	onClose: () => void;
}

export const ReproveModal = ({ onClose }: Props) => {
	const { user } = useContext(AuthContext);
	const { onAddFinancialReprove, onAddTechnicalReprove, technicalSupport } =
		useContext(TechnicalSupportsContext);

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors }
	} = useForm<AddFinancialReprove.Params>({
		defaultValues: {
			technicalSupportId: Number(technicalSupport?.id)
		}
	});

	const isComexUser = user?.role?.split("_")[0] === "comex";
	const isFinancialUser = user?.role?.split("_")[0] === "financial";

	useEffect(() => {
		reset({
			observation: "",
			technicalSupportId: Number(technicalSupport?.id)
		});
	}, [technicalSupport?.id]);

	const handleAddReprove = (data: AddFinancialReprove.Params) => {
		isFinancialUser && onAddFinancialReprove(data, onClose);
		isComexUser && onAddTechnicalReprove(data, onClose);
	};

	return (
		<Modal.root>
			<Modal.header title={t("reproveModal.title")} onClose={onClose} />
			<Modal.body>
				<Modal.body.main>
					<div className="reproval">
						<div className="content">
							<div className="double">
								<div className="technicalSupportInput">
									<label htmlFor="technicalSupportId">
										{t("reproveModal.registerInput.label")}
									</label>
									<Input
										disabled
										variant="light"
										value={technicalSupport?.id}
									/>
								</div>
								<div className="issueDateInput">
									<label htmlFor="issueDate">
										{t("reproveModal.issueDateInput.label")}
									</label>
									<Input
										disabled
										type="date"
										variant="light"
										value={new Date().toISOString().split("T")[0]}
									/>
								</div>
							</div>
							<div className="observationInput">
								<Textarea>
									<Textarea.Label
										title={t("reproveModal.observationInput.label")}
										htmlFor="observation"
									/>
									<Textarea.Field
										rows={3}
										id="observation"
										placeholder={t("reproveModal.observationInput.placeholder")}
										{...register("observation", {
											required: {
												value: true,
												message: t(
													"reproveModal.observationInput.requiredAlert"
												)
											},
											maxLength: 240
										})}
										maxLength={240}
									/>
									<Textarea.Error
										hasError={!!errors.observation}
										errorMessage={errors.observation?.message}
									/>
								</Textarea>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="actions">
						<Button
							size="lg"
							variant="confirmation-solid"
							title={t("reproveModal.submitAction")}
							onClick={handleSubmit(handleAddReprove)}
						/>
						<Button
							size="lg"
							variant="cancellation"
							title={t("reproveModal.cancelAction")}
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	);
};
