import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactJoyride, { Step } from "react-joyride";
import { useDispatch } from "react-redux";
import { updateAccountTutorials } from "../../services";
import { store } from "../../store";
import { updateTutorials } from "../../store/modules/auth/actions";
import "./styles.scss";

const listTutorials = [
	{ id: 1, title: "MainFunctions" },
	{ id: 2, title: "RegisterSale" }
];

export const JoyRide = ({ ...rest }) => {
	const dispatch = useDispatch();
	const { tutorials, language } = store.getState().auth;

	const { t } = useTranslation();

	const [steps, setSteps] = useState<Step[]>([]);
	const handleJoyrideCallback = async (e: any) => {
		if (
			(e.action === "next" &&
				e.status === "finished" &&
				e.type === "tour:end") ||
			e.action === "skip"
		) {
			if (rest?.steps.length > 0) {
				try {
					const currentIndex = tutorials?.indexOf({ id: rest.id });
					if (currentIndex === -1) {
						const newTutorials = tutorials.length
							? [...tutorials.map(({ id }: any) => id), rest.id]
							: [rest.id];
						dispatch(updateTutorials(tutorials.concat([{ id: rest.id }])));
						updateAccountTutorials(newTutorials as []);
					}
				} catch (error) {
					console.error(error);
				}
			}
		}
	};

	useEffect(() => {
		setSteps([
			...rest.steps.map((step: Step) => ({
				...step,
				title: <p>{t(`${step.title}`)}</p>,
				content: <Trans>{t(`${step.content}`)}</Trans>
			}))
		]);
	}, [rest.steps, language]);

	return (
		<ReactJoyride
			continuous
			hideCloseButton
			scrollToFirstStep
			showProgress
			showSkipButton
			hideBackButton={rest.backButton}
			styles={{
				options: {
					arrowColor: "#fff",
					backgroundColor: "#fff",
					beaconSize: 36,
					overlayColor: "rgba(0, 0, 0, 0.5)",
					primaryColor: "#C21618",
					spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
					textColor: "#333",
					width: "500px",
					zIndex: 100
				}
			}}
			steps={steps}
			callback={(e) => handleJoyrideCallback(e)}
			locale={{
				back: t("joyrideStep.actions.back"),
				close: t("joyrideStep.actions.close"),
				last: t("joyrideStep.actions.last"),
				next: t("joyrideStep.actions.next"),
				open: t("joyrideStep.actions.open"),
				skip: t("joyrideStep.actions.skip")
			}}
		/>
	);
};
