import {
	EmptyList,
	GenericTable,
	LoadingProgress,
	Modal
} from "@praticabr/ppsa";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CreditContext } from "../../context/CreditContext";
import i18n from "../../i18n/i18n";
import "./styles.scss";

type ViewCreditDetailsModal = {
	data: any;
	onClose: () => void;
};

export const CreditDetailsViewModal = ({
	onClose,
	data
}: ViewCreditDetailsModal) => {
	const {
		creditDetailLoading,
		onGetCreditDetails,
		onClearCreditDetails,
		creditDetails
	} = useContext(CreditContext);

	const { t } = useTranslation();

	useEffect(() => {
		onGetCreditDetails(data?.creditId, data?.distributorCode);
	}, []);

	const isFinancialUser =
		useSelector((state: any) => state.auth.role) === "financial_user";

	const formatData = (date: string) => {
		const year = parseInt(date?.slice(0, 4), 10);
		const month = parseInt(date?.slice(4, 6), 10) - 1;
		const day = parseInt(date?.slice(6, 8), 10);

		return new Date(year, month, day);
	};

	return (
		<>
			<Modal.root>
				<Modal.header
					onClose={() => {
						onClose();
						onClearCreditDetails();
					}}
					title={t("creditDetailsModal.title")}
				/>
				<Modal.body>
					<div className="credit-details-view-modal">
						{creditDetails?.length > 0 ? (
							<>
								<div className="credit-details-view-modal-header">
									<div className="credit-details-view-modal-item">
										<label>{t("creditDetailsModal.serviceNumber")}</label>
										<span>{data?.serviceOrderId}</span>
									</div>
									<div className="credit-details-view-modal-item">
										<label>{t("creditDetailsModal.initialValue")}</label>
										<span>R{data?.totalValue}</span>
									</div>
									<div className="credit-details-view-modal-item">
										<label>{t("creditDetailsModal.currentBalance")}</label>
										<span>
											R{data?.totalBalance === 0 && `$`}
											{data?.totalBalance}
										</span>
									</div>
								</div>
								<div className="credit-details-view-modal-table">
									{creditDetails?.length > 0 ? (
										<GenericTable
											data={[
												...creditDetails?.map((item) => {
													return {
														id: item?.R_E_C_N_O,
														createdAt:
															formatData(item?.E5_DATA)?.toLocaleDateString(
																i18n.language,
																{
																	timeZone: "UTC"
																}
															) || "-",
														idNote: item?.E5_DOCUMEN || "-",
														value: `R$ ${item?.E5_VALOR}`,
														history: item?.E5_HISTOR || "-",
														externalId: data?.externalId || "-"
													};
												})
											]}
											columns={
												isFinancialUser
													? [
															{
																key: "createdAt",
																header: t(
																	"creditDetailsModal.creditDetailsDate"
																),
																width: "15%"
															},
															{
																key: "value",
																header: t(
																	"creditDetailsModal.creditDetailsValue"
																),
																width: "15%"
															},
															{
																key: "idNote",
																header: t("creditDetailsModal.invoice"),
																width: "23%"
															},
															{
																key: "history",
																header: t("creditDetailsModal.creditHistory"),
																width: "24%"
															},
															{
																key: "externalId",
																header: t("creditDetailsModal.externalId"),
																width: "23%"
															}
														]
													: [
															{
																key: "createdAt",
																header: t(
																	"creditDetailsModal.creditDetailsDate"
																),
																width: "33%"
															},
															{
																key: "value",
																header: t(
																	"creditDetailsModal.creditDetailsValue"
																),
																width: "33%"
															},
															{
																key: "idNote",
																header: t("creditDetailsModal.invoice"),
																width: "34%"
															}
														]
											}
										/>
									) : (
										<EmptyList text={t("emptyList.text")} />
									)}
								</div>
							</>
						) : (
							<EmptyList text={t("emptyList.text")} />
						)}
					</div>
				</Modal.body>
			</Modal.root>
			{creditDetailLoading && <LoadingProgress />}
		</>
	);
};
