import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

interface Props {
	sendAgain: () => void;
}

export const ResetPasswordSuccessContain = ({ sendAgain }: Props) => {
	const { t } = useTranslation();
	return (
		<div className="reset-pass-form">
			<div className="reset-pass-completed">
				<h4>
					{t("loginPage.sendedEmailText1")}
					<br />
					{t("loginPage.sendedEmailText2")}
				</h4>
				<p
					onClick={() => {
						sendAgain();
					}}
				>
					{t("loginPage.resendAction")}
				</p>
			</div>
		</div>
	);
};
