import {
	Button,
	EmptyList,
	LoadingList,
	Paginator,
	Search
} from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { ClientContext } from "../../context";
import { ClientModel } from "../../models";
import "./styles.scss";

interface SelectionClientProps {
	onChangeClient: (item: any) => void;
	handleNewClient: () => void;
}

export const SelectionClient = ({
	onChangeClient,
	handleNewClient
}: SelectionClientProps) => {
	const {
		list,
		total,
		offset,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetClientsList
	} = useContext(ClientContext);

	useEffect(() => {
		onGetClientsList();
	}, []);

	const ClientSearchBodyWeb = () => {
		return (
			<div className="selection-client-body-list">
				{list.clients?.map((client: ClientModel, index: number) => {
					return (
						<div
							className="selection-client-body-item"
							key={index}
							onClick={() => onChangeClient(client)}
						>
							<div className="selection-client-details-list">
								<ul className="selection-client-details-list-item selection-client-details-list-name">
									<span title={client?.name}>{client?.name}</span>
								</ul>
								<ul className="selection-client-details-list-item selection-client-details-list-email">
									<span title={client?.email}>{client?.email}</span>
								</ul>
								<ul className="selection-client-details-list-item selection-client-details-list-city">
									<span title={client?.city}>{client?.city}</span>
								</ul>
								<ul className="selection-client-details-list-item selection-client-details-list-city">
									<span
										title={t(`searchClientModal.typeOptions.${client?.type}`)}
									>
										{client.type
											? t(`searchClientModal.typeOptions.${client?.type}`)
											: "-"}
									</span>
								</ul>
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<div className="selection-client-list-container">
			<div className="selection-client-input-container">
				<div className="selection-client-input-header">
					<Search.root>
						<Search.icon>
							<SearchSvg />
						</Search.icon>
						<Search.input
							name="order_customer"
							placeholder={t("searchClientModal.searchPlaceholder")}
							onChangeValues={onSearchParams}
						/>
					</Search.root>
					<Button
						size="lg"
						title={t("searchClientModal.buttonTitle")}
						variant="confirmation-solid"
						onClick={() => handleNewClient()}
					/>
				</div>
			</div>
			{isLoading ? (
				<LoadingList />
			) : (
				<>
					<div className="selection-client-header-list">
						<div className="selection-client-details-list">
							<ul className="selection-client-details-list-item selection-client-details-list-name">
								<span>{t("searchClientModal.tableColumns.name")}</span>
							</ul>
							<ul className="selection-client-details-list-item selection-client-details-list-email">
								<span>{t("searchClientModal.tableColumns.email")}</span>
							</ul>
							<ul className="selection-client-details-list-item selection-client-details-list-city">
								<span>{t("searchClientModal.tableColumns.city")}</span>
							</ul>
							<ul className="selection-client-details-list-item selection-client-details-list-type">
								<span>{t("searchClientModal.tableColumns.type")}</span>
							</ul>
						</div>
					</div>
					{list?.clients?.length > 0 ? (
						ClientSearchBodyWeb()
					) : (
						<EmptyList text={t("emptyList.text")} />
					)}
					<div className="paginator">
						<Paginator
							totalPages={total}
							currentPage={offset}
							onPageChange={onPageChange}
						/>
					</div>
				</>
			)}
		</div>
	);
};
