import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowBack from "../../assets/SvgIcons/ArrowBack";
import "./styles.scss";

type TParams = {
	backButton?: boolean;
	children?: React.ReactNode;
};

export const HeaderArticle = ({ backButton = false, children }: TParams) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="header-article">
			{backButton && (
				<div className="back-link" onClick={() => history?.back()}>
					<ArrowBack width="24px" height="24px" />
					<span>{t("navbar.backLink")}</span>
				</div>
			)}
		</div>
	);
};
