import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AddAccount } from "../models/usecases/add-accounts";
import { GetAccounts } from "../models/usecases/get-accounts";
import { updateAccountRequest } from "../models/usecases/users";
import { getAccountsList, newUser, updateAccount } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const UserContext = createContext({
	list: {} as GetAccounts.Return,
	isLoading: false,
	limit: 19,
	total: 1,
	offset: 1,
	onPageChange: (page: number) => {},
	onSearchParams: (searchTerm: string) => {},
	onGetAccountsLists: () => {},
	onUpdateAccount: (params: {
		data: updateAccountRequest;
		userId: number;
		callback?: () => void;
	}) => {},
	handleNewUser: (data: AddAccount.Params, callback?: () => void) => {}
});

const defaultParams = defaultListParams();

export const UserProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [accountsLists, setAccountsLists] = useState<GetAccounts.Return>(
		{} as GetAccounts.Return
	);

	const params = useRef<ListParams>(defaultParams);

	const { t } = useTranslation();

	const handleUpdateAccount = (params: {
		data: updateAccountRequest;
		userId: number;
		callback?: () => void;
	}) => {
		setIsLoading(true);
		updateAccount({ data: params.data, id: params.userId })
			.then((response) => {
				toast.success(t("authContext.updateUserSuccess"));
				params.callback && params.callback();
				handleGetAccountsLists();
			})
			.catch(() => toast.error(t("authContext.updateUserError")))
			.finally(() => setIsLoading(false));
	};

	const handleNewUser = async (
		data: AddAccount.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await newUser(data);
			toast.success(t("authContext.registerUser.success"));
			callback && callback();
		} catch (error) {
			toast.error(t("authContext.registerUser.error"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleGetAccountsLists = async () => {
		setIsLoading(true);
		try {
			const currentPage = params.current.offset - 1;
			const response = await getAccountsList({
				...params.current,
				offset: currentPage,
				term: params.current.term || ""
			});
			setAccountsLists(response.data);
			updateParams(
				Math.ceil(response.data.total / params.current.limit),
				"total"
			);
		} catch (error) {
			toast.error(t("userContext.getAccountsListsError"));
		} finally {
			setIsLoading(false);
		}
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		handleGetAccountsLists();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		params.current = updateListParams(value, field, params.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetAccountsLists();
	};

	const value = {
		list: accountsLists,
		...params.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onUpdateAccount: handleUpdateAccount,
		onGetAccountsLists: handleGetAccountsLists,
		handleNewUser
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
