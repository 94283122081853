export interface FilterData {
	id: number;
	initialDate: Date | string;
	finalDate: Date | string;
	statusList: string[];
	statusOrder: "desc" | "asc";
}

export default interface ListParams {
	limit: number;
	offset: number;
	total: number;
	term?: string;
	clientId?: number;
	distributorId?: number;
	sold?: boolean;
	filter?: string;
}

export type FilterField =
	| "filter"
	| "limit"
	| "offset"
	| "search"
	| "total"
	| "client"
	| "distributor"
	| "sold";
export type FilterValue = number | string | FilterData | boolean | null;

export const INITIAL_PAGE = 1;
export const DEFAULT_LIMIT = 19;

/**
 * @description Return a clear or saved list params.
 * @param storedListParams Saved List Params Object
 * @param clearAll Flag to clear all values
 * @returns Returns a new List Params Object
 */
export function defaultListParams(
	storedListParams?: ListParams,
	clearAll = true
): ListParams {
	if (!clearAll) {
		return {
			limit: storedListParams?.limit || DEFAULT_LIMIT,
			offset: storedListParams?.offset || INITIAL_PAGE,
			total: storedListParams?.total || INITIAL_PAGE,
			term: storedListParams?.term || "",
			filter: storedListParams?.filter || ""
		};
	} else {
		return {
			limit: DEFAULT_LIMIT,
			offset: INITIAL_PAGE,
			total: INITIAL_PAGE,
			term: "",
			filter: ""
		};
	}
}

/**
 * @description Update a List Params Object
 * @param value Value to update in object
 * @param field Field to be updated
 * @param listParams List Params Object to be updated
 * @returns Returns a new List Params Object
 */
export const updateListParams = (
	value: FilterValue,
	field: FilterField,
	listParams: ListParams
) => {
	const newListParams = { ...listParams };

	switch (field) {
		case "limit":
			newListParams.limit = value as number;
			break;
		case "offset":
			newListParams.offset = value as number;
			break;
		case "total":
			newListParams.total = value as number;
			break;
		case "search":
			newListParams.term = value as string;
			newListParams.offset = 1 as number;
			break;
		case "client":
			value
				? (newListParams.clientId = value as number)
				: delete newListParams.clientId;
			break;
		case "distributor":
			newListParams.distributorId = value as number;
			break;
		case "sold":
			newListParams.sold = value as boolean;
			break;
		case "filter":
			newListParams.filter = value as string;
			newListParams.offset = 1 as number;
			break;
	}

	return newListParams;
};

/**
 * @description Clear basic attributes from List Params Object
 * @param listParams List Params Object
 * @param clearAll Flag to clear all values
 * @returns Returns a new List Params Object
 */
export const clearListParams = (listParams: ListParams, clearAll = false) => {
	if (clearAll) {
		return defaultListParams();
	} else {
		const newListParams = { ...listParams };
		newListParams.offset = INITIAL_PAGE;
		newListParams.total = INITIAL_PAGE;
		return newListParams;
	}
};
