import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AddSaleRecord } from "../models/usecases/add-sale-record";
import { DeleteSaleRecordById } from "../models/usecases/delete-sale-record-by-id";
import { GetSaleRecordById } from "../models/usecases/get-sale-record-by-id";
import { GetSalesResponse } from "../models/usecases/sales";
import { UpdateSaleRecordById } from "../models/usecases/update-sale-record-by-id";
import {
	deleteSaleRecord,
	getListSales,
	getSaleRecordById,
	newSaleRecord,
	updateSaleRecord
} from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const SalesContext = createContext({
	list: {} as GetSalesResponse | undefined,
	saleRecord: {} as GetSaleRecordById.Return | undefined,
	isLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onGetSalesLists: () => {},
	onGetSaleRecord: (data: GetSaleRecordById.Params) => {},
	onDeleteSaleRecord: (
		data: DeleteSaleRecordById.Params,
		callback?: () => void
	) => {},
	handleCleanup: () => {},
	handleNewSale: (data: AddSaleRecord.Params, callback?: () => void) => {},
	handleUpdateSaleRecord: (
		id: number,
		data: UpdateSaleRecordById.Params,
		callback?: () => void
	) => {}
});

const defaultParams = defaultListParams();

export const SalesProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [salesLists, setSalesLists] = useState<GetSalesResponse>();
	const [saleRecord, setSaleRecord] = useState<GetSaleRecordById.Return>();
	const listParams = useRef<ListParams>(defaultParams);

	const { t } = useTranslation();

	const sold = true;

	const handleGetSalesLists = async () => {
		try {
			const { limit, offset, term } = { ...listParams.current };
			const currentPage = offset - 1;
			setIsLoading(true);

			const response = await getListSales({
				limit,
				offset: currentPage,
				sold,
				term
			});
			setSalesLists(response.data);
			updateParams(Math.ceil(response.data.total / limit), "total");
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast.error(t("salesContext.getSalesListsError"));
		}
	};

	const onSearchParams = (term: string) => {
		updateParams(term, "search");
		handleGetSalesLists();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetSalesLists();
	};

	const handleNewSale = async (
		data: AddSaleRecord.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await newSaleRecord(data);
			toast.success(t("salesContext.newSaleRecordSuccess"));
			callback && callback();
		} catch (error) {
			toast.error(t("salesContext.newSaleRecordError"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleUpdateSaleRecord = async (
		id: number,
		data: UpdateSaleRecordById.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await updateSaleRecord(data, id);
			toast.success(t("salesContext.updateSaleRecordSuccess"));
			await handleGetSaleRecordById({ id });
			callback && callback();
		} catch (error) {
			toast.error(t("salesContext.updateSaleRecordError"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleGetSaleRecordById = async (data: GetSaleRecordById.Params) => {
		try {
			setIsLoading(true);
			const response = await getSaleRecordById(data);
			setSaleRecord(response.data);
		} catch (error) {
			toast.error(t("salesContext.getSaleRecordError"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleDeleteSaleRecordById = async (
		data: DeleteSaleRecordById.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await deleteSaleRecord(data);
			callback && callback();
			toast.success(t("salesContext.deleteSaleRecordSuccess"));
		} catch (error) {
			toast.error(t("salesContext.deleteSaleRecordError"));
		} finally {
			setIsLoading(false);
		}
	};

	const value = {
		list: salesLists,
		saleRecord,
		...listParams.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetSalesLists: handleGetSalesLists,
		onGetSaleRecord: handleGetSaleRecordById,
		onDeleteSaleRecord: handleDeleteSaleRecordById,
		handleCleanup: () => setSaleRecord(undefined),
		handleNewSale,
		handleUpdateSaleRecord
	};

	return (
		<SalesContext.Provider value={value}>{children}</SalesContext.Provider>
	);
};
