import { produce } from "immer";

const INITIAL_STATE = {
	token: null,
	contact: null,
	id: null,
	distributorId: null,
	email: null,
	language: null,
	phone: null,
	role: null,
	tutorials: [],
	availableDistributors: [],
	notificationManager: [],
	allowEmailNotification: null
};

export default function auth(state = INITIAL_STATE, action: any) {
	return produce(state, (draft) => {
		switch (action.type) {
			case "@auth/SIGN_IN_SUCCESS": {
				draft.token = action.payload.token;
				draft.contact = action.payload.contact;
				draft.id = action.payload.id;
				draft.distributorId = action.payload.distributorId;
				draft.email = action.payload.email;
				draft.language = action.payload.language;
				draft.phone = action.payload.phone;
				draft.role = action.payload.role;
				draft.tutorials = action.payload.tutorials;
				draft.availableDistributors = action.payload.availableDistributors;
				draft.notificationManager = action.payload.notificationManager;
				draft.allowEmailNotification = action.payload.allowEmailNotification;
				break;
			}
			case "@auth/SIGN_OUT": {
				draft.token = null;
				draft.contact = null;
				draft.id = null;
				draft.distributorId = null;
				draft.email = null;
				draft.language = null;
				draft.phone = null;
				draft.role = null;
				draft.tutorials = [];
				draft.availableDistributors = [];
				draft.notificationManager = [];
				draft.allowEmailNotification = null;
				break;
			}
			case "@auth/SET_LANGUAGE":
				draft.language = action.payload;
				break;
			case "@auth/SET_DISTRIBUTOR":
				draft.distributorId = action.payload;
				break;
			case "@auth/SET_TUTORIALS":
				draft.tutorials = action.payload;
				break;
			case "@auth/SET_UPDATE_NOTIFICATION_MANAGER":
				draft.notificationManager = action.payload.notificationManager;
				draft.allowEmailNotification = action.payload.allowEmailNotification;
				break;
			default:
		}
	});
}
