import {
	Button,
	ConfirmModal,
	Divider,
	GenericTable,
	Input,
	Select
} from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { EquipmentSearch, InputPhoneIntl, Upload } from "..";
import AddIcon from "../../assets/SvgIcons/AddIcon";
import TrashIcon from "../../assets/SvgIcons/TrashIcon";
import { AuthContext, ClientContext, SalesContext } from "../../context";
import i18n from "../../i18n/i18n";
import { UploadFile } from "../../models";
import { UpdateSaleRecordById } from "../../models/usecases/update-sale-record-by-id";
import { onDeleteUpload } from "../../services/upload";
import "./styles.scss";

type Props = {
	onClose: () => void;
	onView: () => void;
};

export const EditSaleRecord = ({ onClose, onView }: Props) => {
	const { saleRecord, handleUpdateSaleRecord } = useContext(SalesContext);
	const { countries } = useContext(ClientContext);
	const { user } = useContext(AuthContext);

	const { t } = useTranslation();

	const [fileDeleted, setFileDeleted] = useState<any[]>([]);
	const [showModalSelectEquipment, setShowModalSelectEquipment] =
		useState<boolean>(false);
	const [modalUploadFiles, setModalUploadFiles] = useState<boolean>(false);
	const [equipmentList, setEquipmentList] = useState(saleRecord?.equipments);
	const [selectedEquipment, setSelectedEquipment] = useState<any>();
	const [selectedItens, setSelectedEquipments] = useState<any[]>([]);
	const [saleDate, setSaleDate] = useState<string>(
		saleRecord?.saleDate.toLocaleString() || ""
	);

	const isComexUser = user?.role?.split("_")[0] === "comex";
	const countriesOptions = countries.map((country) => ({
		value: String(country.id),
		label:
			i18n.language === "en"
				? country.nameEn
				: i18n.language === "es"
					? country.nameEs
					: country.namePt || "-"
	}));

	const {
		control,
		getValues,
		register,
		setValue,
		reset,
		handleSubmit,
		formState: { errors },
		getFieldState
	} = useForm<UpdateSaleRecordById.Params>({
		defaultValues: {
			client: {
				name: saleRecord?.client?.name,
				phone: saleRecord?.client?.phone,
				email: saleRecord?.client?.email,
				type: saleRecord?.client?.type,
				city: saleRecord?.client?.city,
				countryId: saleRecord?.client?.country?.id
			},
			files: saleRecord?.files,
			distributorOrder: saleRecord?.distributorOrder,
			observation: saleRecord?.observation,
			saleDate: saleRecord?.saleDate
		}
	});

	const filesForm = useForm<UploadFile, "files">();
	const filesFieldArray = useFieldArray({
		control: filesForm.control,
		name: "files"
	});

	const equipmentsFieldArray = useFieldArray({
		control,
		name: "equipments"
	});

	useEffect(() => {
		if (equipmentList && equipmentList?.length > 0 && saleRecord) {
			equipmentsFieldArray.replace(
				equipmentList?.map((equipment) => {
					const dateUtc = equipment.saleRecordId
						? new Date(equipment.installationDate)
						: new Date(saleRecord?.saleDate);

					dateUtc.setHours(dateUtc.getHours() + 5);

					return {
						id: equipment.id,
						installationDate: dateUtc
					};
				})
			);
		} else {
			toast.info(t("saleRecordModal.emptyListAlert"), {
				autoClose: false,
				closeButton: true,
				closeOnClick: true
			});
		}
	}, [equipmentList]);

	useEffect(() => {
		if (saleRecord) {
			filesForm.setValue("files", saleRecord.files as any);
		}
	}, [saleRecord]);

	const handleDeleteItem = (data: any) => {
		setEquipmentList((equipments) => {
			const index = equipments?.indexOf(data);
			index && equipmentsFieldArray.remove(index);
			return equipments?.filter((_, i) => i !== index);
		});
		setSelectedEquipments((equipments) => {
			const index = equipments?.indexOf(data);
			return equipments?.filter((_, i) => i !== index);
		});
		setSelectedEquipment(undefined);
	};

	const handleAddItem = (selectedItens: any) => {
		setShowModalSelectEquipment(false);
		setSelectedEquipments(selectedItens);
		setEquipmentList(
			(equipments) =>
				(equipments && [...new Set([...equipments, ...selectedItens])]) ||
				selectedItens
		);
	};

	const handleOnSubmit = (data: UpdateSaleRecordById.Params) => {
		const equipments = getValues().equipments;

		if (equipments && equipments?.length > 0 && saleRecord) {
			if (fileDeleted.length > 0) {
				fileDeleted.forEach((file) => {
					onDeleteUpload(file);
				});
			}

			const files = filesFieldArray.fields;
			const params = {
				...data,
				saleDate: new Date(saleDate),
				equipments,
				files: files.map((file) => ({
					description: file.description,
					extension: file.type || file.extension,
					url: file.url
				}))
			};
			handleUpdateSaleRecord(saleRecord.id, params, onView);
		}
	};

	const onFileDeleted = (file: any) => {
		setFileDeleted((files) => [...files, file]);
	};

	const formatDate = (dateString: string): string => {
		const date = new Date(dateString);
		const localISOTime = new Date(date.getTime()).toISOString().split("T")[0];
		return localISOTime;
	};

	return (
		<>
			<div className="edit-sale-record">
				<div className="form">
					<div className="form-item">
						<label htmlFor="saleDate">
							{t("saleRecordModal.form.createdAtInput.label")}
						</label>
						<Input
							variant="light"
							placeholder={t("saleRecordModal.form.saleDateInput.placeholder")}
							type="date"
							disabled={true}
							value={
								saleRecord?.createdAt
									? new Date(saleRecord?.createdAt).toISOString().split("T")[0]
									: ""
							}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="saleDate">
							{t("saleRecordModal.form.saleDateInput.label")}
						</label>
						<Input
							type="date"
							variant="light"
							disabled={!isComexUser}
							value={saleDate ? formatDate(saleDate.toLocaleString()) : ""}
							onChange={(e) =>
								setSaleDate(formatDate(e.target.value)?.toLocaleString())
							}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="distributorOrder">
							{t("saleRecordModal.form.distributorOrderInput.label")}
						</label>
						<Input
							variant="light"
							placeholder={t(
								"saleRecordModal.form.distributorOrderInput.placeholder"
							)}
							errors={errors.distributorOrder}
							{...register("distributorOrder")}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="type">
							{`${t("saleRecordModal.form.typeInput.label")}*`}
						</label>
						<Select
							id="type"
							onSelectOptions={(option) => setValue("client.type", option[0])}
							options={[
								{
									value: "",
									label: t("saleRecordModal.form.typeOptions.0")
								},
								{
									value: "finalCustomer",
									label: t("saleRecordModal.form.typeOptions.1")
								},
								{
									value: "dealer",
									label: t("saleRecordModal.form.typeOptions.2")
								}
							]}
							placeholder={t("saleRecordModal.form.typeInput.placeholder")}
							selectedOption={[String(getValues("client.type"))]}
							{...register("client.type", {
								required: {
									value: true,
									message: t("saleRecordModal.form.typeInput.requiredAlert")
								}
							})}
							errors={getFieldState("client.type").error}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="name">
							{`${t("saleRecordModal.form.nameInput.label")}*`}
						</label>
						<Input
							variant="light"
							placeholder={t("saleRecordModal.form.nameInput.placeholder")}
							errors={errors.client?.name}
							{...register("client.name", {
								required: {
									value: true,
									message: t("saleRecordModal.form.nameInput.requiredAlert")
								}
							})}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="email">
							{`${t("saleRecordModal.form.emailInput.label")}*`}
						</label>
						<Input
							variant="light"
							placeholder={t("saleRecordModal.form.emailInput.placeholder")}
							errors={errors.client?.email}
							{...register("client.email", {
								required: {
									value: true,
									message: t("saleRecordModal.form.emailInput.requiredAlert")
								},
								pattern: {
									value:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: t("saleRecordModal.form.emailInput.patternAlert")
								}
							})}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="client.phone">
							{t("saleRecordModal.form.phoneInput.label")}
						</label>
						<InputPhoneIntl
							name="client.phone"
							control={control}
							inputRef={{
								...register("client.phone")
							}}
							errors={errors.client?.phone}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="city">
							{`${t("saleRecordModal.form.cityInput.label")}*`}
						</label>
						<Input
							variant="light"
							placeholder={t("saleRecordModal.form.cityInput.placeholder")}
							errors={errors.client?.city}
							{...register("client.city", {
								required: {
									value: true,
									message: t("saleRecordModal.form.cityInput.requiredAlert")
								}
							})}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="country">
							{`${t("saleRecordModal.form.countryInput.label")}*`}
						</label>
						<Select
							id="countryId"
							onSelectOptions={(option) =>
								setValue("client.countryId", +option[0])
							}
							options={countriesOptions}
							selectedOption={[String(getValues("client.countryId"))]}
							placeholder={t("saleRecordModal.form.countryInput.placeholder")}
							errors={errors.client?.countryId}
							{...register("client.countryId", {
								required: {
									value: true,
									message: t("saleRecordModal.form.countryInput.requiredAlert")
								}
							})}
						/>
					</div>
					<div className="form-item" />
					<div className="form-item observation">
						<label htmlFor="observation">
							{t("saleRecordModal.form.observationInput.label")}
						</label>
						<Input
							variant="light"
							placeholder={t(
								"saleRecordModal.form.observationInput.placeholder"
							)}
							errors={errors.observation}
							{...register("observation")}
						/>
					</div>
					<div className="form-item">
						<Upload.root
							label={t("uploadFiles.salesDocuments")}
							text={t("uploadFiles.filesAccepted")}
							onAddItem={() => setModalUploadFiles(true)}
						>
							{modalUploadFiles && (
								<Upload.modal
									onClose={() => {
										setModalUploadFiles(false);
									}}
									filesForm={filesForm}
									fieldFiles={filesFieldArray}
								/>
							)}
							<Upload.files
								fieldFiles={filesFieldArray}
								{...{ onFileDeleted }}
							/>
						</Upload.root>
					</div>
				</div>
				<Divider title={t("saleRecordModal.divider")} side="left" />
				<div className="equipment-list">
					<GenericTable
						data={equipmentList?.map((equipment, index) => {
							return {
								...equipment,
								serialNumber: equipment?.serialNumber || "-",
								description:
									i18n.language === "en"
										? equipment.equipmentModel.descriptionEn
										: i18n.language === "es"
											? equipment.equipmentModel.descriptionEs
											: equipment.equipmentModel.descriptionPt || "-",
								installationDate: equipment?.saleRecordId ? (
									new Date(equipment?.installationDate).toLocaleDateString(
										i18n.language,
										{
											timeZone: "UTC"
										}
									)
								) : (
									<div className="installation-date-input">
										<Input
											type="date"
											variant="light"
											placeholder={t(
												"saleRecordModal.saleContain.saleDateInput.placeholder"
											)}
											{...register(`equipments.${index}.installationDate`, {
												valueAsDate: true
											})}
										/>
									</div>
								),
								actionTable: (
									<div
										className="trashAction"
										key={index}
										onClick={() => setSelectedEquipment(equipment)}
									>
										<TrashIcon width="24" height="24" fill="#5daeff" />
									</div>
								)
							};
						})}
						columns={[
							{
								key: "serialNumber",
								header: t("saleRecordModal.tableColumns.serialNumber"),
								width: "20%"
							},
							{
								key: "description",
								header: t("saleRecordModal.tableColumns.description"),
								width: "calc(60% - 24px)"
							},
							{
								key: "installationDate",
								header: t("saleRecordModal.tableColumns.installationDate"),
								width: "20%"
							},
							{
								key: "actionTable",
								header: "",
								width: "24px"
							}
						]}
					/>
				</div>
				<div
					className="action-add-equipments"
					onClick={() => setShowModalSelectEquipment(true)}
				>
					<AddIcon width="24" height="24" />
					<span>{t("saleRecordModal.addItemAction")}</span>
				</div>
			</div>
			<div className="actions">
				{isComexUser && (
					<>
						<Button
							title={t("saleRecordModal.submitAction")}
							size="lg"
							disabled={!equipmentList?.length}
							variant="confirmation-solid"
							onClick={handleSubmit(handleOnSubmit)}
						/>
						<Button
							title={t("saleRecordModal.cancelAction")}
							size="lg"
							variant="cancellation"
							onClick={() => {
								filesFieldArray.fields.forEach((field) => {
									saleRecord?.files.forEach((file) => {
										if (file.url !== field.url) {
											onDeleteUpload(field);
										}
									});
								});
								onClose();
							}}
						/>
					</>
				)}
			</div>
			{selectedEquipment?.id && (
				<ConfirmModal
					buttonWidth="68px"
					onConfirm={() => handleDeleteItem(selectedEquipment)}
					title={t("saleRecordModal.deleteModal.title")}
					text={
						<p style={{ textAlign: "center" }}>
							{`${t("saleRecordModal.deleteModal.text1")}`} <br />
							{`${t("saleRecordModal.deleteModal.text2")}`}
							<strong>{getValues("client.name")}</strong>?
						</p>
					}
					subText={t("saleRecordModal.deleteModal.subText")}
					confirmLabel={t("saleRecordModal.deleteModal.submitAction")}
					cancelLabel={t("saleRecordModal.deleteModal.cancelAction")}
					onCancel={() => setSelectedEquipment(undefined)}
				/>
			)}
			{showModalSelectEquipment && (
				<EquipmentSearch
					distributorId={saleRecord?.distributorId}
					onClose={() => setShowModalSelectEquipment(false)}
					previousSelectedItens={selectedItens}
					onConfirmSetSelectedEquipments={handleAddItem}
				/>
			)}
		</>
	);
};
