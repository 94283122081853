import { LoadingList, Modal, Paginator, Search } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { FieldArrayWithId } from "react-hook-form";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { DistributorContext } from "../../context/DistributorContext";
import { DistributorModel } from "../../models/distributor";
import { updateAccountRequest } from "../../models/usecases/users";
import "./styles.scss";

interface DistributorSearchProps {
	onClose: (value: boolean) => void;
	onChangeDistributor: (item: any) => void;
	arrayDistributors?: FieldArrayWithId<
		updateAccountRequest,
		"availableDistributors",
		"id"
	>[];
}

export const DistributorSearch = ({
	onClose,
	onChangeDistributor,
	arrayDistributors
}: DistributorSearchProps) => {
	const {
		list,
		total,
		offset,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetDistributorLists
	} = useContext(DistributorContext);

	useEffect(() => {
		onGetDistributorLists();
	}, []);

	const compareItems = (item1: any, item2: any) => {
		return item1?.codeProtheus === item2?.codeProtheus;
	};

	const DistributorSearchBodyWeb = () => {
		return (
			<div className="distributors-body-list">
				{list.distributors
					?.filter(
						(item) => !arrayDistributors?.some((i) => compareItems(item, i))
					)
					.map(
						(
							distributor: Pick<DistributorModel, "name" | "codeProtheus">,
							index: number
						) => {
							return (
								<div
									className="distributors-body-item"
									key={index}
									onClick={() => {
										onChangeDistributor(distributor);
										onPageChange(1);
									}}
								>
									<div className="distributors-details-list">
										<ul className="distributors-details-list-item distributors-details-list-code">
											<span>{distributor.codeProtheus}</span>
										</ul>
										<ul className="distributors-details-list-item distributors-details-list-name">
											<span>{distributor.name}</span>
										</ul>
									</div>
								</div>
							);
						}
					)}
			</div>
		);
	};

	return (
		<Modal.root>
			<Modal.header
				title={t("searchDistributorModal.title")}
				onClose={() => {
					onClose(false);
					onPageChange(1);
				}}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="distributors-list-container">
						<div className="client-input-container">
							<div>
								<Search.root>
									<Search.icon>
										<SearchSvg />
									</Search.icon>
									<Search.input
										name="order_customer"
										placeholder={t("searchDistributorModal.searchPlaceholder")}
										onChangeValues={onSearchParams}
									/>
								</Search.root>
							</div>
						</div>
						<div className="distributors-header-list">
							<div className="distributors-details-list">
								<ul className="distributors-details-list-item distributors-details-list-code">
									<span>{t("searchDistributorModal.tableColumns.code")}</span>
								</ul>
								<ul className="distributors-details-list-item distributors-details-list-name">
									<span>{t("searchDistributorModal.tableColumns.name")}</span>
								</ul>
							</div>
						</div>
						{isLoading ? <LoadingList /> : DistributorSearchBodyWeb()}
						<div className="paginator">
							<Paginator
								totalPages={total}
								currentPage={offset}
								onPageChange={onPageChange}
							/>
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	);
};
