import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { authenticateResponse } from "../../../models/usecases/users";
import { api, authenticateZendesk, login } from "../../../services";
import { parseQueryString } from "../../../utils/NormalizerHelpers";
import { signInSuccess } from "./actions";

interface SignInRequestAction extends PayloadAction<any> {
	resolve: () => void;
	reject: (reason?: any) => void;
}

function setApiHeaders(accessToken: string) {
	api.interceptors.request.use(function (config) {
		config.headers["x-access-token"] = accessToken;
		return config;
	});
}

function* signIn({
	payload,
	resolve,
	reject
}: SignInRequestAction): Generator<any, void, any> {
	try {
		const response: AxiosResponse<authenticateResponse> = yield call(
			login,
			payload
		);
		const {
			id,
			distributorId,
			token,
			contact,
			email,
			language,
			phone,
			role,
			tutorials,
			availableDistributors,
			allowEmailNotification,
			notificationManager
		} = response.data;

		yield call(setApiHeaders, token);

		const signInSuccessPayload = {
			token,
			contact,
			distributorId,
			id,
			email,
			language,
			phone,
			role,
			tutorials,
			availableDistributors,
			allowEmailNotification,
			notificationManager
		};

		yield put(signInSuccess(signInSuccessPayload));

		const zendeskRequest = parseQueryString(location.search.substring(1));
		const { brand_id, locale_id, return_to } = zendeskRequest;

		if (brand_id && locale_id && return_to) {
			authenticateZendesk({ name: contact, email })
				.then((response) => {
					const tokenZendesk = response.data.token;
					if (tokenZendesk) {
						window.location.replace(
							`https://praticabr.zendesk.com/access/jwt?jwt=${tokenZendesk}&return_to=${return_to}`
						);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			role === "distributor_user"
				? window.location.replace(`/${language?.split("-")[0]}/home`)
				: window.location.replace(`/${language?.split("-")[0]}/sales`);
		}
		resolve();
	} catch (error) {
		reject(error);
	}
}

export function setToken(data: any) {
	if (!data?.payload?.auth?.token) return;
	const { token } = data.payload.auth;
	setApiHeaders(token);
}

export function signOut(data: any) {
	localStorage.clear();
}

export default all([
	takeLatest("persist/REHYDRATE", setToken),
	takeLatest("@auth/SIGN_IN_REQUEST", signIn),
	takeLatest("@auth/SIGN_OUT", signOut)
]);
