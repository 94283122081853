import React from "react";

import "./styles.scss";
interface ArrowBackProps extends React.SVGProps<SVGSVGElement> {
	width?: string;
	height?: string;
	fill?: string;
}
const ArrowBack: React.FC<ArrowBackProps> = ({ width, height, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="6.507"
			height="11.381"
			viewBox="0 0 6.507 11.381"
			{...rest}
		>
			<path
				id="arrow-back"
				data-name="Icon ionic-ios-arrow-back"
				d="M13.212,11.883l4.306-4.3a.813.813,0,0,0-1.152-1.149l-4.879,4.876a.812.812,0,0,0-.024,1.121l4.9,4.909a.813.813,0,0,0,1.152-1.149Z"
				transform="translate(-11.251 -6.194)"
			/>
		</svg>
	);
};

export default ArrowBack;
