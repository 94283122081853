import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context";
import { store } from "../../store";
import "./styles.scss";

type LinkListContainProps = {
	link: string;
	title: string;
	icon: React.JSX.Element;
	nextToBottom?: boolean;
	target?: boolean;
	joyride?: string;
};

const languageTechnicalMaterials: any = {
	pt: "pt-br",
	en: "en-us",
	es: "es-bo"
};

const LinkListContain = ({
	link,
	title,
	icon,
	nextToBottom = false,
	target = false,
	joyride
}: LinkListContainProps) => {
	const { zendeskAuthentication } = useContext(AuthContext);

	const { i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	const pathname = window.location.pathname;

	const navigator = useNavigate();

	return (
		<li className={"list-item"} style={{ flex: nextToBottom ? "auto" : "" }}>
			<a
				id={joyride}
				className={`list-link ${pathname === link ? "active" : ""}`}
				onClick={() => {
					if (target) {
						zendeskAuthentication((token) => {
							if (!token) {
								return;
							}
							const url = `https://praticabr.zendesk.com/access/jwt?jwt=${token}&return_to=https://suportetecnico.praticabr.com/hc/${languageTechnicalMaterials[language]}`;
							window.open(url, "_blank");
						});
					} else {
						navigator(link);
					}
				}}
			>
				{icon}
				<span className={"span-link show-span"}>{title}</span>
			</a>
		</li>
	);
};

export default LinkListContain;
