import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { history } from "../routes/history";
import { store } from "../store";
import { updateLanguage } from "../store/modules/auth/actions";
import { parseQueryString } from "../utils/NormalizerHelpers";
import { LANGUAGES } from "./languages";

export function LanguageSwitcher() {
	const { onUpdateUserLanguage, user, zendeskAuthentication } =
		useContext(AuthContext);

	const { i18n } = useTranslation();
	const location = useLocation();

	const storeLanguage = store.getState().auth.language;

	const token = store.getState().auth.token;

	const dispatch = useDispatch();

	const onChangeLanguage = (language: string) => {
		onUpdateUserLanguage({
			language
		});
	};

	useEffect(() => {
		const currentUrlLang = location.pathname.split("/")[1] || storeLanguage;

		const currentLanguage = LANGUAGES[currentUrlLang];

		const currentPathName = location.pathname.replace(/^\/[a-zA-Z0-9]+/, "");

		const zendeskRequest = parseQueryString(location.search.substring(1));

		if (!currentLanguage) {
			i18n.changeLanguage("en");
			dispatch(updateLanguage("en"));
			if (currentPathName) {
				const { brand_id, locale_id, return_to } = zendeskRequest;
				window.location.href = `/${
					store.getState().auth.language?.split("-")[0]
				}${
					currentPathName !== "/"
						? currentPathName
						: token
							? "/home"
							: `/login${
									brand_id && locale_id && return_to
										? `/?brand_id=${brand_id}&locale_id=${locale_id}&return_to=${return_to}`
										: ""
								}`
				}`;
			} else {
				history.replace(
					`/${store.getState().auth.language?.split("-")[0] || "en"}/home`
				);
			}
		} else {
			i18n.changeLanguage(currentLanguage.urlLang);
			dispatch(updateLanguage(currentLanguage.urlLang));
			token &&
				user?.language !== currentLanguage.urlLang &&
				onChangeLanguage(currentLanguage.urlLang);
			if (!location.pathname.split("/")[1]) {
				const { brand_id, locale_id, return_to } = zendeskRequest;
				if (user && token && brand_id && locale_id && return_to) {
					zendeskAuthentication((tokenZendesk) => {
						if (!tokenZendesk) {
							return;
						}
						const url = `https://praticabr.zendesk.com/access/jwt?jwt=${tokenZendesk}&return_to=${return_to}`;
						window.location.href = url;
					});
				} else {
					window.location.href = `/${
						store.getState().auth.language?.split("-")[0]
					}${
						currentPathName !== "/"
							? currentPathName
							: token
								? "/home"
								: `/login${
										brand_id && locale_id && return_to
											? `/?brand_id=${brand_id}&locale_id=${locale_id}&return_to=${return_to}`
											: ""
									}`
					}`;
				}
			}
		}
	}, []);

	return null;
}
