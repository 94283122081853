import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n/i18n";
import "./styles.scss";

type Props = {
	credits?: {
		consumed: number;
		open: number;
	};
};

export const CreditConsultation = ({ credits }: Props) => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	return (
		<div className="credit-consultation">
			<div
				className="item"
				onClick={() => {
					navigate(`/${i18n.language}/credits`);
				}}
			>
				<p>
					{t("dashboardPage.creditConsultation.creditOpen.row1")}
					<br /> {t("dashboardPage.creditConsultation.creditOpen.row2")}
				</p>
				<span>
					{credits?.open?.toString().split(".")[0]}
					<p>,{credits?.open.toFixed(2)?.toString().split(".")[1] || "00"} </p>
				</span>
			</div>
			<div
				className="item"
				onClick={() => {
					navigate(`/${i18n.language}/credits?legends=applied`);
				}}
			>
				<p>
					{t("dashboardPage.creditConsultation.creditConsumed.row1")} <br />
					{t("dashboardPage.creditConsultation.creditConsumed.row2")}
				</p>
				<span>
					{credits?.consumed.toString().split(".")[0]}
					<p>
						,{credits?.consumed.toFixed(2)?.toString().split(".")[1] || "00"}
					</p>
				</span>
			</div>
		</div>
	);
};
