import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import AttachSvg from "../../assets/SvgIcons/AttachSvg";
import "./styles.scss";

type Props = {
	children: ReactNode;
	onAddItem: () => void;
	label: string;
	text: string;
};

export const UploadRoot = ({ children, onAddItem, label, text }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="upload-file-root">
			<div className="upload-file-main-content">
				<button
					type="button"
					className="upload-file-action"
					onClick={onAddItem}
				>
					<AttachSvg />
					<span>{label}</span>
				</button>
				<span className="upload-file-warning">{text}</span>
			</div>
			{children}
		</div>
	);
};
