import React, { ReactNode, createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
	GetNotificationsTypes,
	updateNotificationsTypes
} from "../models/usecases";
import { GetNotifications } from "../models/usecases/get-notifications";
import {
	getNotificationsList,
	getNotificationsTypes,
	updateNotificationStatusAll,
	updateNotificationStatusById,
	updateNotificationUserType
} from "../services";

export const NotificationContext = createContext({
	list: {} as GetNotifications.Return | undefined,
	notificationTypes: {} as GetNotificationsTypes.Return | undefined,
	isLoading: false,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onGetNotificationList: () => {},
	onGetNotificationTypes: () => {},
	onChangeDate: (startDate: string, endDate: string) => {},
	onChangeStatus: (id: number, status: string) => {},
	onChangeAllStatus: () => {},
	handleUpdateNotificationUserType: (
		params: updateNotificationsTypes.Params,
		callback: (data: updateNotificationsTypes.Return) => void
	) => {}
});

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [notificationList, setNotificationList] =
		useState<GetNotifications.Return>();
	const [notificationTypes, setNotificationTypes] =
		useState<GetNotificationsTypes.Return>();
	const { t } = useTranslation();

	const handleGetNotificationList = async (
		startDate?: string,
		endDate?: string
	) => {
		setIsLoading(true);
		try {
			const data: GetNotifications.Params = {
				term: "",
				offset: 0,
				limit: 20,
				status: ""
			};
			const response = await getNotificationsList(data);
			setNotificationList(response.data);
		} catch (error) {
			toast.error(t("notificationContext.error.getList"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleGetNotificationsTypes = async () => {
		setIsLoading(true);
		try {
			const response = await getNotificationsTypes();
			setNotificationTypes(response?.data);
		} catch (error) {
			toast.error(t("notificationContext.error.getTypes"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleUpdateNotificationUserType = async (
		params: updateNotificationsTypes.Params,
		callback: (data: updateNotificationsTypes.Return) => void
	) => {
		setIsLoading(true);
		try {
			const filteredNullNumbers = params.notificationTypesId.filter(
				(id) => !!id
			);
			params.notificationTypesId = filteredNullNumbers;
			const response = await updateNotificationUserType(params);
			callback(response.data);
			toast.success(t("notificationContext.success.updateTypes"));
		} catch (error) {
			toast.error(t("notificationContext.error.updateTypes"));
		} finally {
			setIsLoading(false);
		}
	};

	const onSearchParams = (term: string) => {
		handleGetNotificationList();
	};

	const onPageChange = (page: number) => {
		handleGetNotificationList();
	};

	const onChangeDate = (startDate: string, endDate: string) => {
		handleGetNotificationList(startDate, endDate);
	};

	const onChangeStatus = async (id: number, status: string) => {
		setIsLoading(true);
		const params = [{ id, status }];
		await updateNotificationStatusById({ notifications: params });
		handleGetNotificationList();
	};

	const onChangeAllStatus = async () => {
		setIsLoading(true);
		await updateNotificationStatusAll();
		handleGetNotificationList();
	};

	const value = {
		list: notificationList,
		notificationTypes,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetNotificationList: handleGetNotificationList,
		onGetNotificationTypes: handleGetNotificationsTypes,
		onChangeDate,
		onChangeStatus,
		onChangeAllStatus,
		handleUpdateNotificationUserType
	};

	return (
		<NotificationContext.Provider value={value}>
			{children}
		</NotificationContext.Provider>
	);
};
