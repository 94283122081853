import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ArrowIcon from "../../assets/SvgIcons/ArrowIcon";
import { store } from "../../store";
import { updateMenuShowStatus } from "../../store/modules/user/actions";
import "./styles.scss";

type NavBarRootProps = {
	logo: {
		src: string;
		alt: string;
	};
	children?: React.ReactNode;
};

export const NavBarRoot = ({ children, logo }: NavBarRootProps) => {
	const [menuShowing, setMenuShowing] = useState<boolean>(
		!store.getState().user.menuShow
	);

	const dispatch = useDispatch();

	useEffect(() => {
		const menuContent = document.querySelector(
			".menu-content"
		) as HTMLDivElement;
		const mainArticle = document.querySelector(
			".main-article"
		) as HTMLDivElement;
		const spanLink = document.querySelectorAll(".span-link");
		const listLink = document.querySelectorAll(".list-link");

		if (menuShowing) {
			menuContent.style.width = "250px";
			menuContent.style.transition = "width 0.5s";

			mainArticle.style.width = "calc( 100% - 250px )";

			spanLink.forEach((span) => {
				span.classList.replace("back-off-span", "show-span");
			});

			listLink.forEach((link) => {
				(link as HTMLElement).style.justifyContent = "";
				(link as HTMLElement).style.paddingLeft = "20px";
			});
		} else {
			menuContent.style.width = "100px";
			menuContent.style.transition = "width 0.5s";

			mainArticle.style.width = "calc( 100% - 100px )";

			spanLink.forEach((span) => {
				span.classList.replace("show-span", "back-off-span");
			});

			listLink.forEach((link) => {
				(link as HTMLElement).style.justifyContent = "center";
				(link as HTMLElement).style.paddingLeft = "0px";
			});
		}
	}, [menuShowing]);

	const handleShowMenu = () => {
		setMenuShowing(!menuShowing);
		dispatch(updateMenuShowStatus(menuShowing));
	};

	return (
		<>
			<nav className="menu-content">
				<img
					src={logo.src}
					alt={logo.alt}
					className="image"
					style={{ visibility: menuShowing ? "visible" : "hidden" }}
				/>
				<ul className="links-list">{children}</ul>
				<div className="show-menu" onClick={handleShowMenu}>
					<button>
						<ArrowIcon
							style={{ transform: menuShowing ? "" : "rotate(180deg)" }}
						/>
					</button>
				</div>
			</nav>
		</>
	);
};
