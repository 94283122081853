import React, { useState } from "react";
import OptionIcon from "../../assets/images/Option.svg";
import ClickAwayListener from "../../utils/ClickAwayListener";
import { MenuItem } from "./MenuItems";
import "./styles.scss";

export interface MoreOptionsItem {
	item?: any;
	label: string;
	onClick: (item?: any) => void;
}

type Props = {
	id: any;
	options: MoreOptionsItem[];
};

export const MoreOptions = ({ id, options }: Props) => {
	const [showOptions, setShowOptions] = useState<boolean>(false);

	const handlerMenuItemsToggle = () => {
		setShowOptions(!showOptions);
	};

	addEventListener("wheel", function () {
		setShowOptions(false);
	});

	const menuItemsContent = () => {
		if (showOptions) {
			const element = document.getElementById(id) as HTMLElement;
			if (element instanceof HTMLElement) {
				const position = element.getBoundingClientRect();

				const menuItem = document.querySelectorAll(
					".more-options-area-selector"
				);

				menuItem.forEach((item) => {
					(item as HTMLElement).style.left = `${
						position.x - (item as HTMLElement).offsetWidth
					}px`;
					(item as HTMLElement).style.top = `${position.y}px`;
				});
			}
		}
		return showOptions ? (
			<MenuItem options={options} />
		) : (
			<MenuItem options={[]} />
		);
	};

	return (
		<ClickAwayListener onClickAway={() => setShowOptions(false)}>
			<div
				id={id}
				className="more-option-area"
				onClick={handlerMenuItemsToggle}
			>
				<img
					className="more-option-item-click"
					aria-controls="simple-menu"
					aria-haspopup="true"
					src={OptionIcon}
					alt=""
				/>
				{menuItemsContent()}
			</div>
		</ClickAwayListener>
	);
};
