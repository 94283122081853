import { t } from "i18next";
import React, { ReactNode, createContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import { GetEquipments } from "../models/usecases/get-equipments";
import { getEquipmentsList } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const EquipmentContext = createContext({
	list: {} as GetEquipments.Return,
	isLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	onSearchParams: (searchTerm: string) => {},
	onSearchByClient: (id: number) => {},
	onSearchByDistributor: (id: number) => {},
	onGetEquipmentsList: () => {},
	onSearchOnlySold: () => {},
	onPageChange: (page: number) => {}
});

const defaultParams = defaultListParams();

export const EquipmentProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const listParams = useRef<ListParams>(defaultParams);

	const [list, setList] = useState<GetEquipments.Return>(
		{} as GetEquipments.Return
	);

	const handleGetEquipmentsList = async () => {
		try {
			setIsLoading(true);
			const {
				limit,
				offset,
				term,
				clientId,
				sold = false,
				distributorId
			} = { ...listParams.current };
			const params = {
				limit,
				offset: offset - 1,
				sold,
				term,
				clientId,
				distributorId
			};
			const response = await getEquipmentsList(params);
			updateParams(Math.ceil(response.data.total / limit), "total");
			setList(response.data);
		} catch (error) {
			toast.error(t("equipmentContext.getEquipmentsListError"));
		} finally {
			setIsLoading(false);
		}
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		handleGetEquipmentsList();
	};

	const onSearchOnlySold = () => {
		updateParams(true, "sold");
		updateParams(null, "client");
		handleGetEquipmentsList();
	};

	const onSearchByClient = (id: number) => {
		updateParams(id, "client");
		updateParams(true, "sold");
		handleGetEquipmentsList();
	};

	const onSearchByDistributor = (id: number) => {
		updateParams(id, "distributor");
		handleGetEquipmentsList();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetEquipmentsList();
	};

	const value = {
		list,
		...listParams.current,
		isLoading,
		onSearchParams,
		onSearchByClient,
		onSearchByDistributor,
		onGetEquipmentsList: handleGetEquipmentsList,
		onPageChange,
		onSearchOnlySold
	};

	return (
		<EquipmentContext.Provider value={value}>
			{children}
		</EquipmentContext.Provider>
	);
};
