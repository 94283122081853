import { Divider } from "@praticabr/ppsa";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ArrowIcon from "../../../assets/SvgIcons/ArrowIcon";
import { updateAccountTutorials } from "../../../services";
import { store } from "../../../store";
import { signOut, updateTutorials } from "../../../store/modules/auth/actions";
import ClickAwayListener from "../../../utils/ClickAwayListener";
import { UserLanguageSelector } from "../UserLanguageSelector";
import "./styles.scss";

interface Params {
	onClose: () => void;
	handleConfigModalToggle: () => void;
}

export const UserSelector: React.FC<Params> = ({
	handleConfigModalToggle,
	onClose
}) => {
	const [showLanguages, setShowLanguages] = React.useState<boolean>(false);

	const user = store.getState().auth;
	const dispatch = useDispatch();

	const {
		i18n: { language },
		t
	} = useTranslation();

	const handleLogout = () => {
		dispatch(signOut());
		window.location.href = `/${language}/login`;
	};

	const handleUpdateTutorial = async () => {
		await updateAccountTutorials([]);
		dispatch(updateTutorials([]));
		window.location.reload();
	};

	const handlerLanguageSelectorToggle = () => {
		setShowLanguages(!showLanguages);
	};

	const languageOptions = [
		{
			value: "en",
			label: t("language.english"),
			language: "EN",
			alt: t("language.english")
		},
		{
			value: "pt",
			label: t("language.portuguese"),
			language: "PT",
			alt: t("language.portuguese")
		},
		{
			value: "es",
			label: t("language.spanish"),
			language: "ES",
			alt: t("language.spanish")
		},
		{
			value: "ru",
			label: t("language.russian"),
			language: "RU",
			alt: t("language.russian")
		}
	];

	const userOption = () => (
		<>
			<div
				className="user-area-selector-item"
				onClick={handleConfigModalToggle}
			>
				<span>{t("navbar.userDropdown.editProfile")}</span>
			</div>
			<div className="user-area-selector-item" onClick={handleUpdateTutorial}>
				<span>{t("navbar.userDropdown.reviewTutorials")}</span>
			</div>
			<div
				className="user-area-selector-item arrow-icon"
				onClick={handlerLanguageSelectorToggle}
			>
				<span>{t("navbar.userDropdown.changeLanguage")}</span>
				<ArrowIcon
					fill="var(--primary-pure)"
					style={{ transform: "rotate(180deg)", height: "10px" }}
				/>
			</div>
			<div className="user-area-selector-item" onClick={handleLogout}>
				<span>{t("navbar.userDropdown.logout")}</span>
			</div>
		</>
	);

	const handleClose = () => {
		setShowLanguages(false);
	};

	return (
		<ClickAwayListener onClickAway={() => onClose()}>
			<section className="user-area-selector">
				<Divider title={`${user.contact.split(" ")[0]}`} />
				{!showLanguages ? (
					userOption()
				) : (
					<UserLanguageSelector
						options={languageOptions}
						{...{ handleClose }}
					/>
				)}
			</section>
		</ClickAwayListener>
	);
};
