import React from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "../../assets/SvgIcons/AddIcon";
import "./styles.scss";

type Props = {
	onAddItem: () => void;
	label: string;
	warning?: string;
};

export const AddButton = ({ onAddItem, label, warning }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="add-button-container">
			<button type="button" className="add-button-action" onClick={onAddItem}>
				<div className="icon">
					<AddIcon width={"20px"} height={"20px"} />
				</div>
				<span>{label}</span>
			</button>
		</div>
	);
};
