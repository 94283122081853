import { Button, Input, Modal, Select } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { TechnicalSupportsContext } from "../../context";
import { AddFinancialApproval } from "../../models/usecases/add-financial-approval";
import { customNormalizeStringHelper } from "../../utils/NormalizerHelpers";
import "./styles.scss";

interface Props {
	onClose: () => void;
}

export const FinancialApprovalModal = ({ onClose }: Props) => {
	const { onAddFinancialApproval, technicalSupport } = useContext(
		TechnicalSupportsContext
	);

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		getValues,
		formState: { errors },
		watch
	} = useForm<AddFinancialApproval.Params>({
		defaultValues: {
			technicalSupportId: Number(technicalSupport?.id)
		}
	});

	const watchObservation = watch("observation");

	useEffect(() => {
		reset({
			technicalSupportId: Number(technicalSupport?.id)
		});
	}, [technicalSupport?.id]);

	const dueDate = new Date();
	dueDate.setDate(dueDate.getDate() + 60);

	const approvedParts = technicalSupport?.approvals
		.filter((approval) => approval.type === "TECHNICAL-APPROVED")
		.map((approval) =>
			approval.approvedParts.filter((part) => !part.partReplacement)
		);

	const creditValue = approvedParts?.reduce(
		(acc, part) =>
			acc +
			part.reduce((acc, part) => acc + part.amount * part.historicalValue, 0),
		0
	);

	const handleAddFinancialApproval = (data: AddFinancialApproval.Params) => {
		onAddFinancialApproval(data, onClose);
	};

	return (
		<Modal.root>
			<Modal.header
				title={t("financialApprovalModal.title")}
				onClose={onClose}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="financial-approve">
						<div className="content">
							<div className="form">
								<div className="technical-support-input disabled">
									<label htmlFor="technical-support-id">
										{t("financialApprovalModal.registerInput.label")}
									</label>
									<Input
										disabled
										variant="light"
										errors={errors.technicalSupportId}
										{...register("technicalSupportId")}
									/>
								</div>
								<div className="value-input disabled">
									<label htmlFor="value">
										{t("financialApprovalModal.creditValueInput.label")}
									</label>
									<Input disabled variant="light" value={creditValue} />
								</div>
								<div className="nature-input">
									<label htmlFor="nature" id="label-nature">
										{t("financialApprovalModal.natureInput.label")}
									</label>
									<Select
										id="nature"
										onSelectOptions={(option) => setValue("nature", option[0])}
										options={[
											{
												value: "",
												label: t("financialApprovalModal.natureInput.options.0")
											},
											{
												value: "2.04027",
												label: t("financialApprovalModal.natureInput.options.1")
											},
											{
												value: "2.06008",
												label: t("financialApprovalModal.natureInput.options.2")
											},
											{
												value: "2.06009",
												label: t("financialApprovalModal.natureInput.options.3")
											},
											{
												value: "2.07001",
												label: t("financialApprovalModal.natureInput.options.4")
											},
											{
												value: "2.07004",
												label: t("financialApprovalModal.natureInput.options.5")
											}
										]}
										placeholder={t(
											"financialApprovalModal.natureInput.placeholder"
										)}
										selectedOption={[String(getValues("nature"))]}
										errors={errors.nature}
										{...register("nature", {
											required: {
												value: true,
												message: t(
													"financialApprovalModal.natureInput.requiredAlert"
												)
											}
										})}
									/>
								</div>
								<div className="issue-date-input disabled">
									<label htmlFor="issueDate">
										{t("financialApprovalModal.issueDateInput.label")}
									</label>
									<Input
										disabled
										type="date"
										variant="light"
										value={new Date().toISOString().split("T")[0]}
									/>
								</div>
								<div className="due-date-input disabled">
									<label htmlFor="dueDate">
										{t("financialApprovalModal.dueDateInput.label")}
									</label>
									<Input
										disabled
										type="date"
										variant="light"
										value={dueDate.toISOString().split("T")[0]}
									/>
								</div>
								<div className="ptax-input">
									<label htmlFor="ptax">
										{t("financialApprovalModal.ptaxInput.label")}
									</label>
									<Input
										variant="light"
										errors={errors.ptax}
										placeholder={t(
											"financialApprovalModal.ptaxInput.placeholder"
										)}
										{...register("ptax", {
											required: {
												value: true,
												message: t(
													"financialApprovalModal.ptaxInput.requiredAlert"
												)
											},
											validate: (value) => {
												if (!(Number(value) > 0)) {
													return t(
														"financialApprovalModal.ptaxInput.invalidAlert"
													);
												}
											},
											valueAsNumber: true
										})}
									/>
								</div>
							</div>
							<div className="observation-input">
								<label htmlFor="observation">
									{t("financialApprovalModal.observationInput.label")}
								</label>
								<Input
									variant="light-big"
									errors={errors.observation}
									placeholder={t(
										"financialApprovalModal.observationInput.placeholder"
									)}
									{...register("observation", {
										required: {
											value: true,
											message: t(
												"financialApprovalModal.observationInput.requiredAlert"
											)
										}
									})}
									maxLength={253}
									onChange={(e) =>
										setValue(
											"observation",
											customNormalizeStringHelper(e.target.value)
										)
									}
								/>
								<span>
									<Trans
										count={
											253 - (watchObservation ? watchObservation.length : 0)
										}
									>
										{t("financialApprovalModal.observationInput.alert")}
									</Trans>
								</span>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="actions">
						<Button
							size="lg"
							variant="confirmation-solid"
							title={t("financialApprovalModal.submitAction")}
							onClick={handleSubmit(handleAddFinancialApproval)}
						/>
						<Button
							size="lg"
							variant="cancellation"
							title={t("financialApprovalModal.cancelAction")}
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	);
};
