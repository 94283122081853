import React, { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context";
import { api } from "../../services";
import { store } from "../../store";

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
	const { user, onLogout } = useContext(AuthContext);

	api.interceptors.response.use(
		(response) => response,
		async function (error) {
			if (error?.response?.status === 401 && user?.token) {
				return onLogout();
			}
			return Promise.reject(error);
		}
	);

	const language =
		store.getState().auth.language || navigator.language?.split("-")[0] || "pt";

	return user.token ? <>{children}</> : <Navigate to={`/${language}/login`} />;
};
