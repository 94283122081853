import React from "react";
import "./styles.scss";

interface DatasheetIconProps {
	width?: string;
	height?: string;
	fill?: string;
}

const MaterialSheetIcon = ({
	fill = "#F8F9FA",
	width,
	height
}: DatasheetIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30.003"
			height="30"
			viewBox="0 0 30.003 30"
		>
			<g
				id="Grupo_744"
				data-name="Grupo 744"
				transform="translate(-487.996 -479)"
			>
				<rect
					id="Retângulo_1496"
					data-name="Retângulo 1496"
					width="17"
					height="2"
					rx="1"
					transform="translate(497.011 492)"
					fill={fill}
				/>
				<rect
					id="Retângulo_1497"
					data-name="Retângulo 1497"
					width="11.703"
					height="2"
					rx="1"
					transform="translate(502.308 496)"
					fill={fill}
				/>
				<rect
					id="Retângulo_1498"
					data-name="Retângulo 1498"
					width="11.703"
					height="2"
					rx="1"
					transform="translate(502.308 500)"
					fill={fill}
				/>
				<rect
					id="Retângulo_1499"
					data-name="Retângulo 1499"
					width="17"
					height="2"
					rx="1"
					transform="translate(497.011 488)"
					fill={fill}
				/>
				<rect
					id="Retângulo_1500"
					data-name="Retângulo 1500"
					width="17"
					height="2"
					rx="1"
					transform="translate(497.011 484)"
					fill={fill}
				/>
				<path
					id="Subtração_99"
					data-name="Subtração 99"
					d="M-1127.908-5977h-20.109c-1.736,0-2.441-.081-2.445-2.1h2.962v-.02h20.272v-25.761H-1148.7v11.78h-1.767v-11.78c0-2.039.706-2.12,2.445-2.12h20.109c1.739,0,2.445.081,2.445,2.12v25.761C-1125.463-5977.081-1126.169-5977-1127.908-5977Z"
					transform="translate(1643.461 6486)"
					fill={fill}
				/>
				<path
					id="Subtração_304"
					data-name="Subtração 304"
					d="M6.763,12H5.239a.717.717,0,0,1-.715-.717v-.327a5.157,5.157,0,0,1-.983-.407l-.232.232a.716.716,0,0,1-1.012,0L1.22,9.7a.718.718,0,0,1,0-1.013l.231-.231a5.163,5.163,0,0,1-.407-.982H.717A.717.717,0,0,1,0,6.761V5.239a.718.718,0,0,1,.717-.717h.327a5.119,5.119,0,0,1,.407-.982L1.22,3.309a.719.719,0,0,1,0-1.014L2.3,1.219a.718.718,0,0,1,1.012,0l.232.232a5.182,5.182,0,0,1,.983-.408V.717A.717.717,0,0,1,5.239,0H6.763a.717.717,0,0,1,.715.717v.326a5.2,5.2,0,0,1,.983.408l.232-.231a.717.717,0,0,1,1.012,0l1.077,1.076a.719.719,0,0,1,0,1.014l-.232.231a5.151,5.151,0,0,1,.407.982h.327A.718.718,0,0,1,12,5.239V6.761a.717.717,0,0,1-.717.716h-.327a5.2,5.2,0,0,1-.407.982l.231.231a.718.718,0,0,1,0,1.013L9.7,10.781a.715.715,0,0,1-1.012,0l-.232-.232a5.18,5.18,0,0,1-.983.407v.327A.717.717,0,0,1,6.763,12ZM6,2.2A3.8,3.8,0,1,0,9.8,6,3.8,3.8,0,0,0,6,2.2Z"
					transform="translate(487.995 494)"
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export default MaterialSheetIcon;
