import { GenericTable, LoadingList, Paginator, Search } from "@praticabr/ppsa";
import React, { SetStateAction, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { EquipmentContext } from "../../context";
import i18n from "../../i18n/i18n";

type Props = {
	client: {
		name: string;
		email: string;
	};
	selectedItens: any[];
	setSelectedEquipments: (value: SetStateAction<any[]>) => void;
};

export const RegisterSaleItensInfo = ({
	client,
	selectedItens,
	setSelectedEquipments
}: Props) => {
	const {
		list,
		onSearchParams,
		total,
		offset,
		onPageChange,
		isLoading,
		onGetEquipmentsList
	} = useContext(EquipmentContext);

	const { t } = useTranslation();

	useEffect(() => {
		onGetEquipmentsList();
	}, []);

	const handleSelectItems = (item: any) => {
		const currentIndex = selectedItens.findIndex(
			(equipment) => equipment.id === item.id && equipment.code === item.code
		);
		const newChecked = [...selectedItens];

		if (currentIndex === -1) {
			newChecked.push(item);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setSelectedEquipments(newChecked);
	};

	const paginationStyled: React.CSSProperties = {
		visibility: total > 1 ? "visible" : "hidden"
	};

	return (
		<div className="itens-contain">
			<div className="client">
				<div className="info">
					<span>{t("newSaleRecordModal.itensContain.clientName")}</span>
					<span>{client.name}</span>
				</div>
				<div className="info">
					<span>{t("newSaleRecordModal.itensContain.clientEmail")}</span>
					<span>{client.email}</span>
				</div>
			</div>
			<div className="search">
				<Search.root>
					<Search.icon>
						<SearchSvg />
					</Search.icon>
					<Search.input
						onChangeValues={onSearchParams}
						placeholder={t("newSaleRecordModal.itensContain.searchPlaceholder")}
					/>
				</Search.root>
			</div>
			{isLoading ? (
				<div style={{ height: "290px" }}>
					<LoadingList />
				</div>
			) : (
				<>
					<div className="list">
						<div className="available-equipments">
							<GenericTable
								onRowClicked={handleSelectItems}
								data={list.equipments?.map((equipment) => ({
									...equipment,
									code: equipment.equipmentModel.code,
									serialNumber: equipment.serialNumber,
									acquisitionDate: new Date(
										equipment.acquisitionDate
									).toLocaleDateString(i18n.language, {
										timeZone: "UTC"
									}),
									description:
										i18n.language === "en"
											? equipment.equipmentModel.descriptionEn
											: i18n.language === "es"
												? equipment.equipmentModel.descriptionEs
												: equipment.equipmentModel.descriptionPt || "-"
								}))}
								columns={[
									{
										key: "serialNumber",
										checkbox: true,
										header: t(
											"newSaleRecordModal.itensContain.tableColumns.serialNumber"
										),
										width: "15%",
										selected: selectedItens
									},
									{
										key: "acquisitionDate",
										header: t(
											"newSaleRecordModal.itensContain.tableColumns.issueDate"
										),
										width: "15%"
									},
									{
										key: "code",
										header: t(
											"newSaleRecordModal.itensContain.tableColumns.code"
										),
										width: "15%"
									},
									{
										key: "description",
										header: t(
											"newSaleRecordModal.itensContain.tableColumns.description"
										),
										width: "55%"
									}
								]}
							/>
						</div>
					</div>
					<div className="pagination" style={paginationStyled}>
						<Paginator
							totalPages={total}
							currentPage={offset}
							onPageChange={onPageChange}
						/>
					</div>
				</>
			)}
		</div>
	);
};
