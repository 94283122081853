import React from "react";
import "./styles.scss";

interface LockIconProps {
	width: string;
	height: string;
	fill?: string;
	onClick?: () => void;
}

const LockIcon: React.FC<LockIconProps> = ({
	fill,
	width,
	height,
	onClick
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="10 5 30 30"
		>
			<g transform="translate(-1603 -7423)">
				<path
					fill={fill}
					d="M22.406,9.833H21.234V7.452a5.86,5.86,0,1,0-11.719,0V9.833H8.344A2.37,2.37,0,0,0,6,12.214v11.9A2.37,2.37,0,0,0,8.344,26.5H22.406a2.37,2.37,0,0,0,2.344-2.381v-11.9A2.37,2.37,0,0,0,22.406,9.833ZM15.375,20.548a2.381,2.381,0,1,1,2.344-2.381A2.37,2.37,0,0,1,15.375,20.548ZM19.008,9.833H11.742V7.452a3.633,3.633,0,1,1,7.266,0Z"
					transform="translate(1612.625 7429.5)"
				/>
			</g>
		</svg>
	);
};

export default LockIcon;
