import { LanguageDetectorAsyncModule } from "i18next";
import { store } from "../store";

export const customLanguageDetector: LanguageDetectorAsyncModule = {
	type: "languageDetector",
	async: true,
	detect: (callback) => {
		const language = store.getState().auth.language;

		const detectedLanguage =
			language || location.pathname.split("/")[1] || "en";

		callback(detectedLanguage);
	},
	init: () => {},
	cacheUserLanguage: () => {}
};

export default customLanguageDetector;
