import { useEffect } from "react";
import { authenticateZendeskSunco } from "../../services";

interface ZendeskOptions {
	// Defina as opções do Zendesk, se necessário
}

export default function useZendeskWidget(
	key: string,
	options: ZendeskOptions,
	{ contact, email, language, token }: any
) {
	useEffect(() => {
		const zendeskInitScript = document.createElement("script");
		zendeskInitScript.id = "ze-snippet";
		zendeskInitScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
		zendeskInitScript.async = true;

		document.body.appendChild(zendeskInitScript);

		(window as any).zESettings = { ...options };

		zendeskInitScript.onload = async () => {
			if (contact && token && email) {
				authZendesk({ contact, email });
			}
		};

		return () => {
			document.body.removeChild(zendeskInitScript);
		};
	}, [key, options]);
}

const authZendesk = async ({ contact, email }: any) => {
	const response = await authenticateZendeskSunco({
		name: contact,
		email
	});
	(window as any).zE("messenger", "loginUser", function (callback: any) {
		callback(response.data.jwt);
	});
};

export const ZendeskWidget = (...args: any) => {
	if (canUseDOM() && (window as any).zE) {
		(window as any).zE.apply(null, args);
	} else {
		console.warn("Zendesk has not finished booting");
	}
};

const canUseDOM = () => {
	return !(
		typeof window === "undefined" ||
		!window.document ||
		!window.document.createElement
	);
};
