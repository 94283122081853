import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";

const secretAccessKey = import.meta.env.VITE_AWS_SECRET_ACCESS_KEY;
const accessKeyId = import.meta.env.VITE_AWS_ACCESS_KEY_ID;
const region = import.meta.env.VITE_AWS_DEFAULT_REGION;
const bucket = import.meta.env.VITE_BUCKET_NAME;

const client = new S3Client({
	region,
	credentials: { secretAccessKey, accessKeyId }
});

export const onDeleteUpload = async (data: any) => {
	const key = data.url.substring(1).split(".com/")[1];

	const command = {
		Bucket: bucket,
		Key: key
	};

	try {
		await client.send(new DeleteObjectCommand(command));
		return data;
	} catch (error) {
		console.log("Error on delete archive AWS");
	}
};

export const onDownloadFile = async (data: any) => {
	fetch(data.url)
		.then((res) => res.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const file = document.createElement("a");
			file.href = url;
			file.download = "";
			file.click();
		});
};
