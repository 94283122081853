import React from "react";

interface Props {}

const CreditSvg: React.FC<Props> = () => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM15 9C16.1046 9 17 8.10457 17 7C17 5.89543 16.1046 5 15 5C13.8954 5 13 5.89543 13 7C13 8.10457 13.8954 9 15 9ZM7 17C8.10457 17 9 16.1046 9 15C9 13.8954 8.10457 13 7 13C5.89543 13 5 13.8954 5 15C5 16.1046 5.89543 17 7 17ZM11 21C11 22.1046 10.1046 23 9 23C7.89543 23 7 22.1046 7 21C7 19.8954 7.89543 19 9 19C10.1046 19 11 19.8954 11 21ZM15 25C16.1046 25 17 24.1046 17 23C17 21.8954 16.1046 21 15 21C13.8954 21 13 21.8954 13 23C13 24.1046 13.8954 25 15 25ZM23 21C23 22.1046 22.1046 23 21 23C19.8954 23 19 22.1046 19 21C19 19.8954 19.8954 19 21 19C22.1046 19 23 19.8954 23 21ZM23 17C24.1046 17 25 16.1046 25 15C25 13.8954 24.1046 13 23 13C21.8954 13 21 13.8954 21 15C21 16.1046 21.8954 17 23 17ZM23 9C23 10.1046 22.1046 11 21 11C19.8954 11 19 10.1046 19 9C19 7.89543 19.8954 7 21 7C22.1046 7 23 7.89543 23 9ZM9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11Z"
				fill="black"
				className="credit-svg"
			/>
		</svg>
	);
};

export default CreditSvg;
