import { GenericTable, LoadingProgress, Paginator } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderSection, ManageUserModal } from "../../components";
import { RegisterUserModal } from "../../components/RegisterUserModal";
import { UserContext } from "../../context";
import "./styles.scss";

export const UsersList = () => {
	const {
		list,
		isLoading,
		onSearchParams,
		total,
		onPageChange,
		offset,
		onGetAccountsLists
	} = useContext(UserContext);
	const [showModal, setShowModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState<any>();

	const { t } = useTranslation();

	useEffect(() => {
		onGetAccountsLists();
	}, []);

	const onSearch = (search: string) => {
		onSearchParams(search);
	};

	const handleCloseModal = () => {
		onGetAccountsLists();
		setSelectedUser(undefined);
		setShowModal(false);
	};

	const roleOptions = [
		{
			value: "distributor_user",
			label: "Distributor User"
		},
		{
			value: "comex_user",
			label: "Comex User"
		},
		{
			value: "financial_user",
			label: "Financial User"
		},
		{
			value: "quality_user",
			label: "Quality User"
		},
		{
			value: "digital_administrator",
			label: "Administrator"
		}
	];

	const languageOptions = [
		{
			value: "en",
			label: t("language.english")
		},
		{
			value: "pt",
			label: t("language.portuguese")
		},
		{
			value: "es",
			label: t("language.spanish")
		}
	];

	return (
		<>
			<article className="sales-list">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={onSearch}
						text={t("usersPage.searchHeader")}
					/>
					<HeaderSection.button
						text={t("usersPage.buttonHeader")}
						onClick={() => setShowModal(true)}
					/>
				</HeaderSection.root>
				<GenericTable
					onRowClicked={(e) => {
						setShowModal(true);
						setSelectedUser(e);
					}}
					data={
						list?.accounts && [
							...list.accounts.map((account) => ({
								...account,
								codeProtheus: account.distributor.codeProtheus,
								languageView: languageOptions.filter(
									(option) => option.value === account.language
								)[0]?.label,
								roleView: roleOptions.filter(
									(option) => option.value === account.role
								)[0]?.label
							}))
						]
					}
					columns={[
						{
							key: "contact",
							header: t("usersPage.tableColumns.name"),
							width: "20%"
						},
						{
							key: "email",
							header: t("usersPage.tableColumns.email"),
							width: "30%"
						},
						{
							key: "codeProtheus",
							header: t("usersPage.tableColumns.distributorCode"),
							width: "15%"
						},
						{
							key: "languageView",
							header: t("usersPage.tableColumns.language"),
							width: "15%"
						},
						{
							key: "roleView",
							header: t("usersPage.tableColumns.role"),
							width: "20%"
						}
					]}
				/>
				<Paginator
					totalPages={total}
					currentPage={offset}
					onPageChange={(value) => onPageChange(value)}
				/>
			</article>
			{showModal && selectedUser?.id && (
				<ManageUserModal user={selectedUser} onClose={handleCloseModal} />
			)}
			{showModal && !selectedUser?.id && (
				<RegisterUserModal onClose={handleCloseModal} />
			)}
			{isLoading && <LoadingProgress />}
		</>
	);
};
