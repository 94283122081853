import {
	EmptyList,
	LoadingList,
	Modal,
	Paginator,
	Search
} from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { ClientContext } from "../../context";
import { ClientModel } from "../../models";
import "./styles.scss";

interface ClientSearchProps {
	onClose: (value: boolean) => void;
	onChangeClient: (item: any) => void;
}

export const ClientSearch = ({
	onClose,
	onChangeClient
}: ClientSearchProps) => {
	const {
		list,
		total,
		offset,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetClientsList
	} = useContext(ClientContext);

	const handleChangeType = (type: string) => {};

	useEffect(() => {
		onGetClientsList();
	}, []);

	const ClientSearchBodyWeb = () => {
		return (
			<div className="clients-body-list">
				{list.clients?.map((client: ClientModel, index: number) => {
					return (
						<div
							className="clients-body-item"
							key={index}
							onClick={() => {
								onChangeClient(client);
							}}
						>
							<div className="clients-details-list">
								<ul className="clients-details-list-item clients-details-list-name">
									<span title={client.name}>{client.name}</span>
								</ul>
								<ul className="clients-details-list-item clients-details-list-email">
									<span title={client.email}>{client.email}</span>
								</ul>
								<ul className="clients-details-list-item clients-details-list-city">
									<span title={client.city}>{client.city}</span>
								</ul>
								<ul className="clients-details-list-item clients-details-list-city">
									<span
										title={t(`searchClientModal.typeOptions.${client.type}`)}
									>
										{client.type
											? t(`searchClientModal.typeOptions.${client.type}`)
											: "-"}
									</span>
								</ul>
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<Modal.root>
			<Modal.header
				title={t("searchClientModal.title")}
				onClose={() => {
					onClose(false);
				}}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="clients-list-container">
						<div className="client-input-container">
							<div>
								<Search.root>
									<Search.icon>
										<SearchSvg />
									</Search.icon>
									<Search.input
										name="order_customer"
										placeholder={t("searchClientModal.searchPlaceholder")}
										onChangeValues={onSearchParams}
									/>
								</Search.root>
							</div>
						</div>
						{isLoading ? (
							<LoadingList />
						) : (
							<>
								<div className="clients-header-list">
									<div className="clients-details-list">
										<ul className="clients-details-list-item clients-details-list-name">
											<span>{t("searchClientModal.tableColumns.name")}</span>
										</ul>
										<ul className="clients-details-list-item clients-details-list-email">
											<span>{t("searchClientModal.tableColumns.email")}</span>
										</ul>
										<ul className="clients-details-list-item clients-details-list-city">
											<span>{t("searchClientModal.tableColumns.city")}</span>
										</ul>
										<ul className="clients-details-list-item clients-details-list-type">
											<span>{t("searchClientModal.tableColumns.type")}</span>
										</ul>
									</div>
								</div>
								{list?.clients?.length > 0 ? (
									ClientSearchBodyWeb()
								) : (
									<EmptyList text={t("emptyList.text")} />
								)}
								<div className="paginator">
									<Paginator
										totalPages={total}
										currentPage={offset}
										onPageChange={onPageChange}
									/>
								</div>
							</>
						)}
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	);
};
