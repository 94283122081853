import {
	GenericTable,
	Input,
	LoadingList,
	Paginator,
	Search,
	Select
} from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { PartsContext } from "../../context";
import i18n from "../../i18n/i18n";
import { ReplacedPartForm } from "../../models";
import { AddService } from "../../models/usecases/add-service";
import { store } from "../../store";
import { StepServices } from "../../utils/JoyrideSteps";
import { JoyRide } from "../JoyRide";

type Props = {
	client: {
		name: string;
		email: string;
	};
	selectedItens: any[];
	partsForm: UseFormReturn<ReplacedPartForm, any, undefined>;
	partsField: UseFieldArrayReturn<ReplacedPartForm, "replacedParts", "id">;
	technicalSupportsForm: UseFormReturn<
		Pick<AddService.Params, "technicalSupports">,
		any,
		undefined
	>;
	valuePartsField: any[];
	setValuePartsField: React.Dispatch<React.SetStateAction<any[]>>;
};

export const ServicePartsInfo = ({
	partsForm,
	partsField,
	selectedItens,
	technicalSupportsForm,
	valuePartsField,
	setValuePartsField
}: Props) => {
	const {
		list,
		onSearchParams,
		total,
		offset,
		onPageChange,
		isLoading,
		onGetPartsList
	} = useContext(PartsContext);

	const [itemSelected, setItemSelected] = useState<any>(
		selectedItens[0].equipmentId
	);
	const [partsSelected, setPartsSelected] = useState<any>([]);
	const [itemIndex, setItemIndex] = useState<number>(0);

	const { getFieldState, getValues } = partsForm;

	const { fields, remove, append } = partsField;

	const { t } = useTranslation();

	const userStore = store.getState().auth;

	const chileDistributors = ["104825"];

	const { distributorId, availableDistributors } = useSelector(
		(state: any) => state.auth
	);

	const codeDistributor = availableDistributors?.filter(
		(item: any) => item.distributor.id === distributorId
	)[0]?.distributor?.codeProtheus;

	const language =
		store.getState().auth.language || navigator.language?.split("-")[0] || "pt";

	useEffect(() => {
		onGetPartsList();
	}, [offset]);

	const partReplacementOptions = [
		{
			label: t("newServiceModal.partsContain.partReplacementOptions.empty"),
			value: ""
		},
		{
			label: t("newServiceModal.partsContain.partReplacementOptions.credit"),
			value: "false"
		},
		{
			label: t(
				"newServiceModal.partsContain.partReplacementOptions.partReplacement"
			),
			value: "true"
		}
	];

	const handleSearchParts = (searchTerm: string) => {
		onSearchParams(searchTerm);
	};

	const validate = () => {
		technicalSupportsForm
			.getValues()
			.technicalSupports.forEach((item, index) => {
				if (!item.replacedParts) {
					technicalSupportsForm.setError(
						`technicalSupports.${index}.replacedParts`,
						{
							type: "required",
							message: t(
								"newServiceModal.technicalContain.technicalParts.requiredAlert"
							)
						}
					);
				} else {
					technicalSupportsForm.clearErrors(
						`technicalSupports.${index}.replacedParts`
					);
				}
			});
	};

	const handleSelectItems = (item: any) => {
		const currentIndex = fields.findIndex(
			(part: any) => part.code === item.code && part.item === itemIndex
		);

		const newChecked = [...partsSelected];

		if (currentIndex === -1 && item.item === itemIndex) {
			newChecked.push(item);
			append({ id: item.id, ...item });
		} else {
			fields.splice(currentIndex, 1);
			remove(currentIndex);
			newChecked.splice(currentIndex, 1);
			if (valuePartsField.length) {
				const currentIndexField = valuePartsField[itemIndex].findIndex(
					(part: any) => part.code === item.code && part.item === itemIndex
				);
				valuePartsField[itemIndex].splice(currentIndexField, 1);
			}
		}

		setPartsSelected(newChecked);
	};

	const compareItems = (item1: any, item2: any) => {
		return item1.code === item2.code;
	};

	const onHandleValue = (
		item: any,
		field: string,
		index: number,
		code: string
	) => {
		setValuePartsField((prevValueField) => {
			const newValueField = [...prevValueField];
			if (!newValueField[itemIndex]) {
				newValueField[itemIndex] = [];
			}
			if (!newValueField[itemIndex][index]) {
				newValueField[itemIndex][index] = {};
			}

			if (field === "amount") {
				newValueField[itemIndex][index].amount = Number(item);
			}

			if (field === "reportedDefect") {
				newValueField[itemIndex][index].reportedDefect = item;
			}

			if (field === "partReplacement") {
				newValueField[itemIndex][index].partReplacement = item;
			}

			newValueField[itemIndex][index].code = code;

			return newValueField;
		});
	};

	const validateFields = (value: number) => {
		const technicalSupportsErrors =
			technicalSupportsForm.getFieldState("technicalSupports").error;
		if (Array.isArray(technicalSupportsErrors)) {
			if (technicalSupportsErrors[value]) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	return (
		<div className="parts-contain">
			<div className="items" id="joyride-support-parts">
				<Select
					id="selected-itens"
					variant="light"
					options={[
						...selectedItens.map((item, index) => {
							const technicalSupportsErrors =
								technicalSupportsForm.getFieldState("technicalSupports").error;
							if (Array.isArray(technicalSupportsErrors)) {
								const newListItem = {
									value: item.equipmentId,
									label: `${
										i18n.language === "en"
											? item.equipmentModel.descriptionEn
											: i18n.language === "es"
												? item.equipmentModel.descriptionEs
												: item.equipmentModel.descriptionPt || "-"
									} - NS: ${item.serialNumber}${
										technicalSupportsErrors[index] ? "    ❌" : "    ✅"
									}`
								};
								return newListItem;
							} else {
								return {
									value: item.equipmentId,
									label: `${
										i18n.language === "en"
											? item.equipmentModel.descriptionEn
											: i18n.language === "es"
												? item.equipmentModel.descriptionEs
												: item.equipmentModel.descriptionPt || "-"
									} - NS: ${item.serialNumber}`
								};
							}
						})
					]}
					selectedOption={[itemSelected]}
					onSelectOptions={(selectOptions) => {
						technicalSupportsForm.handleSubmit(() => {})();
						validate();
						setItemSelected(selectOptions);
						setItemIndex(
							selectedItens.findIndex(
								(item) => item.equipmentId === selectOptions[0]
							)
						);
					}}
				/>
			</div>
			<div className="search">
				<Search.root>
					<Search.icon>
						<SearchSvg />
					</Search.icon>
					<Search.input
						onChangeValues={(term) => handleSearchParts(term)}
						placeholder={t("newServiceModal.partsContain.searchPlaceholder")}
					/>
				</Search.root>
			</div>
			{isLoading ? (
				<div style={{ height: "263px" }}>
					<LoadingList />
				</div>
			) : (
				<div className="list">
					<div className="available-parts">
						<GenericTable
							onRowClicked={handleSelectItems}
							data={[
								...fields.filter((item: any) => item.item === itemIndex),
								...(list?.parts.filter(
									(item: any) =>
										!fields?.some(
											(part: any) =>
												part.item === itemIndex && compareItems(part, item)
										)
								) ?? [])
							]?.map((item: any, index: number) => ({
								...item,
								code: item.code,
								description:
									language === "en"
										? item.descriptionEn
										: language === "es"
											? item.descriptionEs
											: item.descriptionPt,
								amount: fields.some(
									(part: any) =>
										part.code === item.code && part.item === itemIndex
								) ? (
									<Input
										variant="light"
										type="number"
										onChange={(e: any) =>
											onHandleValue(e.target.value, "amount", index, item.code)
										}
										min={0.01}
										maxLength={9}
										errors={
											validateFields(itemIndex)
												? getFieldState(`replacedParts.${index}.amount`).error
												: undefined
										}
										defaultValue={
											valuePartsField.length > 0
												? valuePartsField[itemIndex]?.length > 0
													? valuePartsField[itemIndex][index]?.amount
													: 0
												: 0
										}
									/>
								) : (
									"-"
								),
								value: chileDistributors.includes(codeDistributor)
									? item.chileValue
									: item.value,
								partDefect: fields.some(
									(part: any) =>
										part.code === item.code && part.item === itemIndex
								) ? (
									<Input
										variant="light"
										onChange={(e: any) =>
											onHandleValue(
												e.target.value,
												"reportedDefect",
												index,
												item.code
											)
										}
										errors={
											validateFields(itemIndex)
												? getFieldState(`replacedParts.${index}.reportedDefect`)
														.error
												: undefined
										}
										defaultValue={
											valuePartsField.length > 0
												? valuePartsField[itemIndex]?.length > 0
													? valuePartsField[itemIndex][index]?.reportedDefect
													: ""
												: ""
										}
									/>
								) : (
									"-"
								),
								item: itemIndex,
								index,
								actionTable: fields.some(
									(part: any) =>
										part.code === item.code && part.item === itemIndex
								) ? (
									<Select
										autoComplete="off"
										options={partReplacementOptions}
										errors={
											validateFields(itemIndex)
												? getFieldState(
														`replacedParts.${index}.partReplacement`
													).error
												: undefined
										}
										selectedOption={[
											`${
												valuePartsField.length > 0
													? valuePartsField[itemIndex]?.length > 0
														? valuePartsField[itemIndex][index]?.partReplacement
														: ""
													: ""
											}`
										]}
										onSelectOptions={(e) =>
											onHandleValue(e[0], "partReplacement", index, item.code)
										}
									/>
								) : (
									"-"
								)
							}))}
							columns={[
								{
									key: "code",
									checkbox: true,
									header: t("newServiceModal.partsContain.tableColumns.code"),
									width: "12%",
									selected: fields
								},
								{
									key: "description",
									header: t(
										"newServiceModal.partsContain.tableColumns.description"
									),
									width: "24%"
								},
								{
									key: "amount",
									header: t(
										"newServiceModal.partsContain.tableColumns.quantity"
									),
									width: "10%",
									input: true
								},
								{
									key: "value",
									header: t("newServiceModal.partsContain.tableColumns.value"),
									width: "12%"
								},
								{
									key: "partDefect",
									header: t(
										"newServiceModal.partsContain.tableColumns.partDefect"
									),
									width: "24%",
									input: true
								},
								{
									key: "actionTable",
									header: t(
										"newServiceModal.partsContain.tableColumns.partReplacement"
									),
									width: "20%"
								}
							]}
						/>
					</div>
					<div
						className="pagination"
						style={{
							height: "25px",
							marginTop: "8px",
							marginBottom: "20px",
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<Paginator
							totalPages={total}
							currentPage={offset}
							onPageChange={onPageChange}
						/>
					</div>
				</div>
			)}
			{!userStore.tutorials?.some((e: any) => e.id === 3) &&
				userStore.role === "distributor_user" && (
					<JoyRide steps={StepServices} run={true} id={3} backButton={true} />
				)}{" "}
		</div>
	);
};
