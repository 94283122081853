import React from "react";
import "./styles.scss";

interface ServiceIconProps {
	width?: string;
	height?: string;
	fill?: string;
}

const ServiceIcon: React.FC<ServiceIconProps> = ({
	fill = "#F8F9FA",
	width,
	height
}) => {
	return (
		<svg
			width="30"
			height="25"
			viewBox="0 0 30 25"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1_34)">
				<path
					d="M27.192 25H2.814C2.07756 24.9852 1.37495 24.688 0.851408 24.1699C0.327867 23.6517 0.0234211 22.9522 0.000999999 22.216V12.861H12.251V15.646C12.2523 15.8935 12.3519 16.1304 12.5277 16.3046C12.7036 16.4788 12.9415 16.5761 13.189 16.575H16.814C17.0614 16.5758 17.299 16.4784 17.4746 16.3043C17.6503 16.1301 17.7497 15.8934 17.751 15.646V12.861H30.001V22.216C29.9786 22.9516 29.6747 23.6505 29.152 24.1685C28.6294 24.6866 27.9277 24.9842 27.192 25ZM1.923 14.761V20.79C1.98089 21.4437 2.28656 22.0505 2.77739 22.4861C3.26822 22.9217 3.90705 23.1532 4.563 23.133H25.44C26.0959 23.1532 26.7348 22.9217 27.2256 22.4861C27.7164 22.0505 28.0221 21.4437 28.08 20.79V14.761H19.52L19.465 16.571C19.4685 16.7719 19.4304 16.9713 19.3529 17.1567C19.2754 17.342 19.1604 17.5093 19.015 17.648C18.6316 18.0363 18.1278 18.2831 17.586 18.348H12.307C11.8392 18.3052 11.3995 18.1062 11.0585 17.7832C10.7175 17.4601 10.4951 17.0318 10.427 16.567L10.482 14.757L1.923 14.761ZM30 11.292H0V7.364C0.0221798 6.6274 0.326655 5.92752 0.850434 5.40914C1.37421 4.89076 2.07721 4.59355 2.814 4.579H7.501V2.785C7.52316 2.04874 7.82735 1.34915 8.35071 0.830816C8.87406 0.312487 9.57656 0.015055 10.313 0L19.692 0C20.4286 0.0148018 21.1314 0.312122 21.6549 0.830478C22.1785 1.34883 22.4828 2.04857 22.505 2.785V4.579H27.192C27.9286 4.5938 28.6314 4.89112 29.1549 5.40948C29.6785 5.92783 29.9828 6.62757 30.005 7.364V11.29L30 11.292ZM3.571 6.251C2.186 6.251 1.933 6.9 1.933 8.178V9.472H28.07V8.178C28.07 6.9 27.817 6.251 26.432 6.251H20.892V4.244C20.892 3.08 20.706 1.744 19.259 1.744H10.468C10.3088 1.73159 10.1487 1.7518 9.99757 1.80338C9.84643 1.85496 9.70741 1.93684 9.589 2.044C9.25314 2.58615 9.1269 3.23231 9.234 3.861V3.869C9.234 3.994 9.234 4.12 9.234 4.246V6.251H3.571Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_34">
					<rect width="30" height="25" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ServiceIcon;
