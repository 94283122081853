import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
	AddExtraCost,
	AddTechnicalSupport,
	GetTechnicalSupportById,
	GetTechnicalSupportList
} from "../models/usecases";
import { AddComment } from "../models/usecases/add-comment";
import { AddFinancialApproval } from "../models/usecases/add-financial-approval";
import { AddFinancialReprove } from "../models/usecases/add-financial-reprove";
import { AddTechnicalApproval } from "../models/usecases/add-technical-approval";
import { AddTechnicalReprove } from "../models/usecases/add-technical-reprove";
import {
	addExtraCost,
	getListTechnicalSupports,
	getTechnicalSupportById,
	newCommentary,
	newFinancialApproval,
	newFinancialReprove,
	newTechnicalApproval,
	newTechnicalReprove,
	newTechnicalSupport
} from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const TechnicalSupportsContext = createContext({
	list: {} as GetTechnicalSupportList.Return | undefined,
	technicalSupport: {} as GetTechnicalSupportById.Return | undefined,
	isLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onLegendChange: (legend: string) => {},
	onGetTechnicalSupportsLists: (status?: string) => {},
	onGetTechnicalSupport: (data: GetTechnicalSupportById.Params) => {},
	onAddComment: (data: AddComment.Params, callback?: () => void) => {},
	onAddExtraCost: (data: AddExtraCost.Params, callback?: () => void) => {},
	onAddFinancialReprove: (
		data: AddFinancialReprove.Params,
		callback?: () => void
	) => {},
	onAddTechnicalReprove: (
		data: AddTechnicalReprove.Params,
		callback?: () => void
	) => {},
	onAddTechnicalApproval: (
		data: AddTechnicalApproval.Params,
		callback?: () => void
	) => {},
	onAddFinancialApproval: (
		data: AddFinancialApproval.Params,
		callback?: () => void
	) => {},
	handleCleanup: () => {},
	handleNewTechnicalSupport: (
		data: AddTechnicalSupport.Params,
		callback?: () => void
	) => {}
});

const defaultParams = defaultListParams();

export const TechnicalSupportsProvider = ({
	children
}: {
	children: ReactNode;
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [technicalSupports, setTechnicalSupportsLists] =
		useState<GetTechnicalSupportList.Return>();
	const [technicalSupport, setTechnicalSupport] =
		useState<GetTechnicalSupportById.Return>();
	const listParams = useRef<ListParams>(defaultParams);
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const handleGetTechnicalSupportsLists = async () => {
		try {
			const { limit, offset, term, filter } = { ...listParams.current };
			const currentPage = offset - 1;
			setIsLoading(true);

			const response = await getListTechnicalSupports({
				limit,
				offset: currentPage,
				term: term || "",
				status: filter || ""
			});
			setTechnicalSupportsLists(response.data);
			updateParams(Math.ceil(response.data.total / limit), "total");
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast.error(t("technicalSupportContext.getTechnicalSupportListsError"));
		}
	};

	const onSearchParams = (term: string) => {
		updateParams(term, "search");
		handleGetTechnicalSupportsLists();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetTechnicalSupportsLists();
	};
	const onLegendChange = (legend: string) => {
		updateParams(legend, "filter");
		handleGetTechnicalSupportsLists();
	};

	const handleNewTechnicalSupport = async (
		data: AddTechnicalSupport.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await newTechnicalSupport(data);
			toast.success(t("technicalSupportContext.newTechnicalSupportSuccess"));
			callback && callback();
		} catch (error) {
			toast.error(t("technicalSupportContext.newTechnicalSupportError"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleGetTechnicalSupportById = async (
		data: GetTechnicalSupportById.Params
	) => {
		setIsLoading(true);
		getTechnicalSupportById(data)
			.then((response) => setTechnicalSupport(response.data))
			.catch((err) => {
				if (
					err?.response?.data?.error?.includes(
						"Data not found with value provided on"
					)
				) {
					navigate(`/${i18n.language}/services`);
				} else {
					toast.error(
						t("technicalSupportContext.getTechnicalSupportListsError")
					);
				}
			})
			.finally(() => setIsLoading(false));
	};

	const handleAddComment = async (
		data: AddComment.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await newCommentary(data);
			handleGetTechnicalSupportById({
				id: data.technicalSupportId
			});
			callback && callback();
		} catch (error) {
			toast.error(t("technicalSupportContext.addCommentError"));
		}
	};

	const handleAddExtraCost = async (
		data: AddExtraCost.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await addExtraCost(data);
			handleGetTechnicalSupportById({
				id: data.technicalSupportId
			});
			callback && callback();
		} catch (error) {
			toast.error(t("technicalSupportContext.addCommentError"));
		}
	};

	const handleAddFinancialReprove = async (
		data: AddFinancialReprove.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await newFinancialReprove(data);
			handleGetTechnicalSupportById({
				id: data.technicalSupportId
			});
			callback && callback();
		} catch (error) {
			toast.error(t("technicalSupportContext.addFinancialReproveError"));
		}
	};

	const handleAddTechnicalReprove = async (
		data: AddTechnicalReprove.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await newTechnicalReprove(data);
			handleGetTechnicalSupportById({
				id: data.technicalSupportId
			});
			callback && callback();
		} catch (error) {
			toast.error(t("technicalSupportContext.addTechnicalReproveError"));
		}
	};

	const handleAddTechnicalApproval = async (
		data: AddTechnicalApproval.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await newTechnicalApproval(data);
			handleGetTechnicalSupportById({
				id: data.technicalSupportId
			});
			callback && callback();
		} catch (error) {
			toast.error(t("technicalSupportContext.addTechnicalApprovalError"));
		}
	};

	const handleAddFinancialApproval = async (
		data: AddFinancialApproval.Params,
		callback?: () => void
	) => {
		try {
			setIsLoading(true);
			await newFinancialApproval(data);
			handleGetTechnicalSupportById({
				id: data.technicalSupportId
			});
			callback && callback();
		} catch (error) {
			toast.error(t("technicalSupportContext.addFinancialApprovalError"));
		}
	};

	const value = {
		list: technicalSupports,
		technicalSupport,
		...listParams.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onLegendChange,
		onAddComment: handleAddComment,
		onAddExtraCost: handleAddExtraCost,
		onAddFinancialReprove: handleAddFinancialReprove,
		onAddTechnicalReprove: handleAddTechnicalReprove,
		onAddTechnicalApproval: handleAddTechnicalApproval,
		onAddFinancialApproval: handleAddFinancialApproval,
		onGetTechnicalSupportsLists: handleGetTechnicalSupportsLists,
		onGetTechnicalSupport: handleGetTechnicalSupportById,
		handleCleanup: () => setTechnicalSupport(undefined),
		handleNewTechnicalSupport
	};

	return (
		<TechnicalSupportsContext.Provider value={value}>
			{children}
		</TechnicalSupportsContext.Provider>
	);
};
