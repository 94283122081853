import { GenericTable, LoadingList, Paginator, Search } from "@praticabr/ppsa";
import React, { SetStateAction, useContext, useEffect } from "react";
import { UseFieldArrayReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { EquipmentContext } from "../../context";
import i18n from "../../i18n/i18n";
import { AddService } from "../../models/usecases/add-service";

type Props = {
	selectedItens: any[];
	setSelectedEquipments: (value: SetStateAction<any[]>) => void;
	technicalSupportsField: UseFieldArrayReturn<
		Pick<AddService.Params, "technicalSupports">,
		"technicalSupports",
		"id"
	>;
	onClientChange: (id: number) => void;
};

export const ServiceItensInfo = ({
	selectedItens,
	setSelectedEquipments,
	technicalSupportsField,
	onClientChange
}: Props) => {
	const {
		list,
		onSearchParams,
		onSearchByClient,
		onSearchOnlySold,
		total,
		offset,
		onPageChange,
		isLoading
	} = useContext(EquipmentContext);

	const { t } = useTranslation();

	useEffect(() => {
		onSearchOnlySold();
	}, []);

	const handleSelectItems = (item: any) => {
		const currentIndex = selectedItens.findIndex(
			(equipment) => equipment.id === item.id && equipment.code === item.code
		);
		const newChecked = [...selectedItens];

		if (currentIndex === -1) {
			newChecked.push(item);
		} else {
			handleRemoveItems(currentIndex);
			newChecked.splice(currentIndex, 1);
		}

		if (newChecked.length > 0 && newChecked.length === 1) {
			onSearchByClient(newChecked[0].salesRecord.client.id);
			onClientChange(newChecked[0].salesRecord.client.id);
		} else if (newChecked.length === 0) {
			onSearchOnlySold();
		}

		setSelectedEquipments(newChecked);
	};

	const handleRemoveItems = (currentIndex: any) => {
		if (currentIndex >= 0) {
			technicalSupportsField.remove(currentIndex);
		}
	};

	return (
		<div className="itens-contain">
			<div className="search">
				<Search.root>
					<Search.icon>
						<SearchSvg />
					</Search.icon>
					<Search.input
						onChangeValues={onSearchParams}
						placeholder={t("newServiceModal.itensContain.searchPlaceholder")}
					/>
				</Search.root>
			</div>
			{isLoading ? (
				<div style={{ height: "328px" }}>
					<LoadingList />
				</div>
			) : (
				<div className="list">
					<div className="available-equipments">
						<GenericTable
							onRowClicked={handleSelectItems}
							data={list?.equipments?.map((equipment) => ({
								...equipment,
								code: equipment.equipmentModel.code,
								customer: equipment.salesRecord.client.name,
								serialNumber: equipment.serialNumber,
								acquisitionDate: new Date(
									equipment.acquisitionDate
								).toLocaleDateString(i18n.language, {
									timeZone: "UTC"
								}),
								description:
									i18n.language === "en"
										? equipment.equipmentModel.descriptionEn
										: i18n.language === "es"
											? equipment.equipmentModel.descriptionEs
											: equipment.equipmentModel.descriptionPt || "-"
							}))}
							columns={[
								{
									key: "serialNumber",
									checkbox: true,
									header: t(
										"newServiceModal.itensContain.tableColumns.serialNumber"
									),
									width: "15%",
									selected: selectedItens
								},
								{
									key: "customer",
									header: t(
										"newServiceModal.itensContain.tableColumns.customer"
									),
									width: "15%"
								},
								{
									key: "description",
									header: t(
										"newServiceModal.itensContain.tableColumns.description"
									),
									width: "55%"
								},
								{
									key: "acquisitionDate",
									header: t(
										"newServiceModal.itensContain.tableColumns.installationDate"
									),
									width: "15%"
								}
							]}
						/>
					</div>
					<div
						className="pagination"
						style={{
							height: "25px",
							marginTop: "30px",
							marginBottom: "28px",
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<Paginator
							totalPages={total}
							currentPage={offset}
							onPageChange={onPageChange}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
