import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../src/assets/styles/styles.scss";
import { AuthProvider } from "./context/AuthContext.tsx";
import SocketProvider from "./context/SocketContext.tsx";
import { LanguageSwitcher } from "./i18n/LanguageSwitcher.ts";
import "./i18n/i18n";
import Routes from "./routes/index.tsx";
import { useSentry } from "./services/useSentry.ts";

export function App() {
	useSentry();
	return (
		<SocketProvider>
			<AuthProvider>
				<LanguageSwitcher />
				<Routes />
				<ToastContainer
					closeButton={false}
					position="top-center"
					className="toast-main-content"
					bodyClassName="toast-body-content"
				/>
			</AuthProvider>
		</SocketProvider>
	);
}
