export const customNormalizeHelper = (value: string) => {
	if (!value) return "";
	return value
		.replace(/[./\\]/g, "")
		.replace(/\s+/g, " ")
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "");
};

export const customNormalizeStringHelper = (value: string) => {
	if (!value) return "";
	return value
		.replace(/[\u0300-\u036f]/g, "")
		.normalize("NFD")
		.replace(/[^\w\s]/gi, "");
};

export const parseQueryString = (queryString: string) => {
	const params: Record<string, string | number> = {};

	const keyValuePairs = queryString.split("&");

	for (const keyValuePair of keyValuePairs) {
		const [key, value] = keyValuePair.split("=");

		if (key && value) {
			const decodedValue = decodeURIComponent(value);
			params[key] = decodedValue;
		}
	}

	return params;
};
