import React from "react";
import { useRoutes } from "react-router-dom";
import {
	DefaultLayout,
	ProtectedRoute,
	UnprotectedRoutes
} from "../components";
import { DistributorDropdown } from "../components/DistributorDropdown";
import { Notification } from "../components/Notification";
import UserDropdown from "../components/UserDropdown";
import {
	ClientProvider,
	DashboardProvider,
	EquipmentProvider,
	PartsProvider,
	SalesProvider,
	TechnicalSupportsProvider,
	UserProvider
} from "../context";
import { CreditProvider } from "../context/CreditContext";
import { DefectsProvider } from "../context/DefectsContext";
import { DistributorProvider } from "../context/DistributorContext";
import { NotificationProvider } from "../context/NotificationContext";
import {
	AuthenticatePage,
	CreditsList,
	DashboardPage,
	SalesList,
	ServiceDetail,
	ServiceList,
	UsersList
} from "../pages";
import { PageError } from "../pages/PageError";

export default () =>
	useRoutes([
		{
			path: "/:lang/home",
			element: (
				<ProtectedRoute>
					<DefaultLayout.headerActions>
						<DistributorDropdown />
						<NotificationProvider>
							<Notification />
						</NotificationProvider>
						<UserDropdown />
					</DefaultLayout.headerActions>
					<DefaultLayout.root>
						<DefaultLayout.headerArticle />
						<DefaultLayout.sectionArticle>
							<DashboardProvider>
								<DashboardPage />
							</DashboardProvider>
						</DefaultLayout.sectionArticle>
					</DefaultLayout.root>
				</ProtectedRoute>
			)
		},
		{
			path: "/:lang/sales",
			element: (
				<ProtectedRoute>
					<DefaultLayout.headerActions>
						<DistributorDropdown />
						<NotificationProvider>
							<Notification />
						</NotificationProvider>
						<UserDropdown />
					</DefaultLayout.headerActions>
					<DefaultLayout.root>
						<DefaultLayout.headerArticle />
						<DefaultLayout.sectionArticle>
							<SalesProvider>
								<ClientProvider>
									<EquipmentProvider>
										<SalesList />
									</EquipmentProvider>
								</ClientProvider>
							</SalesProvider>
						</DefaultLayout.sectionArticle>
					</DefaultLayout.root>
				</ProtectedRoute>
			)
		},
		{
			path: "/:lang/sales/:id",
			element: (
				<ProtectedRoute>
					<DefaultLayout.headerActions>
						<DistributorDropdown />
						<NotificationProvider>
							<Notification />
						</NotificationProvider>
						<UserDropdown />
					</DefaultLayout.headerActions>
					<DefaultLayout.root>
						<DefaultLayout.headerArticle />
						<DefaultLayout.sectionArticle>
							<SalesProvider>
								<ClientProvider>
									<EquipmentProvider>
										<SalesList />
									</EquipmentProvider>
								</ClientProvider>
							</SalesProvider>
						</DefaultLayout.sectionArticle>
					</DefaultLayout.root>
				</ProtectedRoute>
			)
		},
		{
			path: "/:lang/services",
			element: (
				<ProtectedRoute>
					<DefaultLayout.headerActions>
						<DistributorDropdown />
						<NotificationProvider>
							<Notification />
						</NotificationProvider>
						<UserDropdown />
					</DefaultLayout.headerActions>
					<DefaultLayout.root>
						<DefaultLayout.headerArticle />
						<DefaultLayout.sectionArticle>
							<TechnicalSupportsProvider>
								<PartsProvider>
									<DefectsProvider>
										<ClientProvider>
											<EquipmentProvider>
												<ServiceList />
											</EquipmentProvider>
										</ClientProvider>
									</DefectsProvider>
								</PartsProvider>
							</TechnicalSupportsProvider>
						</DefaultLayout.sectionArticle>
					</DefaultLayout.root>
				</ProtectedRoute>
			)
		},
		{
			path: "/:lang/services/:id",
			element: (
				<ProtectedRoute>
					<DefaultLayout.headerActions>
						<DistributorDropdown />
						<NotificationProvider>
							<Notification />
						</NotificationProvider>
						<UserDropdown />
					</DefaultLayout.headerActions>
					<DefaultLayout.root>
						<DefaultLayout.headerArticle backButton />
						<DefaultLayout.sectionArticle>
							<TechnicalSupportsProvider>
								<ClientProvider>
									<EquipmentProvider>
										<ServiceDetail />
									</EquipmentProvider>
								</ClientProvider>
							</TechnicalSupportsProvider>
						</DefaultLayout.sectionArticle>
					</DefaultLayout.root>
				</ProtectedRoute>
			)
		},
		{
			path: "/:lang/credits",
			element: (
				<ProtectedRoute>
					<DefaultLayout.headerActions>
						<DistributorDropdown />
						<NotificationProvider>
							<Notification />
						</NotificationProvider>
						<UserDropdown />
					</DefaultLayout.headerActions>
					<DefaultLayout.root>
						<DefaultLayout.headerArticle />
						<DefaultLayout.sectionArticle>
							<CreditProvider>
								<CreditsList />
							</CreditProvider>
						</DefaultLayout.sectionArticle>
					</DefaultLayout.root>
				</ProtectedRoute>
			)
		},
		{
			path: "/:lang/error",
			element: (
				<ProtectedRoute>
					<DefaultLayout.headerActions>
						<DistributorDropdown />
						<NotificationProvider>
							<Notification />
						</NotificationProvider>
						<UserDropdown />
					</DefaultLayout.headerActions>
					<DefaultLayout.root>
						<DefaultLayout.headerArticle backButton />
						<DefaultLayout.sectionArticle>
							<PageError />
						</DefaultLayout.sectionArticle>
					</DefaultLayout.root>
				</ProtectedRoute>
			)
		},
		{
			path: "/:lang/login",
			element: (
				<UnprotectedRoutes>
					<AuthenticatePage />
				</UnprotectedRoutes>
			)
		},
		{
			path: "/:lang/recover-password",
			element: (
				<UnprotectedRoutes>
					<AuthenticatePage />
				</UnprotectedRoutes>
			)
		},
		{
			path: "/:lang/users",
			element: (
				<ProtectedRoute>
					<DefaultLayout.headerActions>
						<DistributorDropdown />
						<NotificationProvider>
							<Notification />
						</NotificationProvider>
						<UserDropdown />
					</DefaultLayout.headerActions>
					<DefaultLayout.root>
						<DefaultLayout.headerArticle />
						<DefaultLayout.sectionArticle>
							<UserProvider>
								<DistributorProvider>
									<UsersList />
								</DistributorProvider>
							</UserProvider>
						</DefaultLayout.sectionArticle>
					</DefaultLayout.root>
				</ProtectedRoute>
			)
		}
	]);
