import { Button, Input, LoadingProgress, Modal } from "@praticabr/ppsa";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LockIcon from "../../assets/SvgIcons/LockIcon";
import { AuthContext } from "../../context/AuthContext";
import { updateAccountRequest } from "../../models/usecases/users";
import { isPhoneValid } from "../../utils/ValidatedPhone";
import { InputPhoneIntl } from "../InputPhoneIntl";
import { UserPasswordModal } from "../UserPasswordModal";
import "./styles.scss";

type Props = {
	onClose: () => void;
};

export const UserConfigModal = ({ onClose }: Props) => {
	const { isLoading, onUpdateUser, user } = useContext(AuthContext);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

	const { t } = useTranslation();

	const {
		handleSubmit,
		register,
		formState: { errors },
		control
	} = useForm<updateAccountRequest>({
		defaultValues: {
			email: user.email,
			language: user.language,
			phone: user.phone,
			token: user.token
		}
	});

	return (
		<>
			<Modal.root>
				<Modal.header title={t("userConfigModal.title")} onClose={onClose} />
				<Modal.body>
					<Modal.body.main>
						<div className="user-config-modal">
							<div className="content">
								<div className="form">
									<div className="form-item">
										<label htmlFor="email">
											{t("userConfigModal.emailInput.label")}
										</label>
										<Input variant="light" disabled value={user.email} />
									</div>
									<div className="form-item">
										<label htmlFor="contact">
											{t("userConfigModal.contactInput.label")}
										</label>
										<Input
											variant="light"
											defaultValue={user.contact}
											errors={errors.contact}
											placeholder={t(
												"userConfigModal.contactInput.placeholder"
											)}
											{...register("contact", {
												required: {
													value: true,
													message: t(
														"userConfigModal.contactInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="phone">
											{t("userConfigModal.phoneInput.label")}
										</label>
										<InputPhoneIntl
											name="phone"
											control={control}
											inputRef={{
												...register("phone", {
													required: {
														value: true,
														message: t(
															"userConfigModal.phoneInput.requiredAlert"
														)
													},
													validate: (value: any) => {
														if (!isPhoneValid(value)) {
															return t(
																"userConfigModal.phoneInput.invalidAlert"
															);
														}
													}
												})
											}}
											errors={errors.phone}
										/>
									</div>
								</div>
								<div className="change-password-action">
									<LockIcon width="16" height="16" />
									<span onClick={() => setShowChangePasswordModal(true)}>
										{t("userConfigModal.changePasswordAction")}
									</span>
								</div>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="actions">
							<Button
								title={t("userConfigModal.submitAction")}
								size="lg"
								variant="confirmation-solid"
								onClick={handleSubmit((e) => onUpdateUser(e, onClose))}
							/>
							<Button
								size="lg"
								title={t("userConfigModal.cancelAction")}
								variant="cancellation"
								onClick={onClose}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{showChangePasswordModal && (
				<UserPasswordModal onClose={() => setShowChangePasswordModal(false)} />
			)}
			{isLoading && <LoadingProgress />}
		</>
	);
};
