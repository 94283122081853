import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import customLanguageDetector from "./customLanguageDetector";
import enJson from "./translations/en.json";
import esJson from "./translations/es.json";
import ptBRJson from "./translations/ptBR.json";
import ruJson from "./translations/ru.json";

i18n
	.use(initReactI18next)
	.use(customLanguageDetector)
	.init({
		interpolation: {
			escapeValue: false
		},
		resources: {
			en: enJson,
			pt: ptBRJson,
			es: esJson,
			ru: ruJson
		}
	});

export default i18n;
