import { Button, Input, Modal, Textarea } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AuthContext, TechnicalSupportsContext } from "../../context";
import { AddExtraCost } from "../../models/usecases";
import "./styles.scss";

interface Props {
	onClose: () => void;
}

export const ExtraCostModal = ({ onClose }: Props) => {
	const { user } = useContext(AuthContext);
	const { onAddExtraCost, technicalSupport } = useContext(
		TechnicalSupportsContext
	);
	const canNotifyFinancialUser =
		user?.role?.split("_")[0] === "comex" ||
		user?.role?.split("_")[0] === "distributor";

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		formState: { errors }
	} = useForm<AddExtraCost.Params>({
		defaultValues: {
			technicalSupportId: Number(technicalSupport?.id)
		}
	});

	useEffect(() => {
		reset({
			reportedDefect: "",
			historicalValue: 0,
			technicalSupportId: Number(technicalSupport?.id)
		});
	}, [technicalSupport?.id]);

	const handleAddExtraCost = (data: AddExtraCost.Params) => {
		onAddExtraCost(data, onClose);
	};

	return (
		<Modal.root>
			<Modal.header title={t("extraCostModal.title")} onClose={onClose} />
			<Modal.body>
				<Modal.body.main>
					<div className="comment">
						<div className="content">
							<div className="form-item">
								<Textarea>
									<Textarea.Label
										title={t("extraCostModal.reportedDefect.label")}
										htmlFor="reportedDefect"
									/>
									<Textarea.Field
										rows={3}
										id="reportedDefect"
										placeholder={t("extraCostModal.reportedDefect.placeholder")}
										{...register("reportedDefect", {
											required: {
												value: true,
												message: t(
													"extraCostModal.reportedDefect.requiredAlert"
												)
											},
											maxLength: 240
										})}
										maxLength={240}
									/>
									<Textarea.Error
										hasError={!!errors.reportedDefect}
										errorMessage={errors.reportedDefect?.message}
									/>
								</Textarea>
							</div>
							<div className="form-item">
								<label htmlFor="">{t("extraCostModal.valueCost.label")}</label>
								<Input
									type="number"
									variant={"light"}
									{...register("historicalValue", {
										required: {
											value: true,
											message: t("extraCostModal.valueCost.requiredAlert")
										},
										valueAsNumber: true
									})}
								/>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="actions">
						<Button
							size="lg"
							variant="confirmation-solid"
							title={t("extraCostModal.submitAction")}
							onClick={handleSubmit(handleAddExtraCost)}
						/>
						<Button
							size="lg"
							variant="cancellation"
							title={t("extraCostModal.cancelAction")}
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	);
};
