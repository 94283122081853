import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../../context";
import { history } from "../../../routes/history";
import { updateLanguage } from "../../../store/modules/auth/actions";
import "./styles.scss";

interface Params {
	options: any[];
}

export const LanguageSelector: React.FC<Params> = ({ options }) => {
	const { onUpdateUserLanguage, user } = React.useContext(AuthContext);
	const { i18n } = useTranslation();
	const dispatch = useDispatch();

	const pathname = window.location.pathname;

	const onChangeLanguage = (language: string) => {
		user?.token && onUpdateUserLanguage({ language });

		i18n.changeLanguage(language);
		dispatch(updateLanguage(language));
		history.replace(`/${language}${pathname.replace(/^\/[a-zA-Z0-9]+/, "")}`);
	};

	return (
		<section className="language-area-selector">
			{options.map((option) => (
				<div
					key={option.value}
					className="language-area-selector-item"
					onClick={() => onChangeLanguage(option.value)}
				>
					<span>{option.label}</span>
				</div>
			))}
		</section>
	);
};
