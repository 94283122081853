import axios, { AxiosRequestConfig } from "axios";
import i18n from "../i18n/i18n";
import {
	AddExtraCost,
	AddTechnicalSupport,
	AuthZendesk,
	GetClientById,
	GetTechnicalSupportById,
	GetTechnicalSupportList,
	updateNotificationsTypes
} from "../models/usecases";
import { AddAccount } from "../models/usecases/add-accounts";
import { AddComment } from "../models/usecases/add-comment";
import { AddFinancialApproval } from "../models/usecases/add-financial-approval";
import { AddFinancialReprove } from "../models/usecases/add-financial-reprove";
import { AddSaleRecord } from "../models/usecases/add-sale-record";
import { AddTechnicalApproval } from "../models/usecases/add-technical-approval";
import { AddTechnicalReprove } from "../models/usecases/add-technical-reprove";
import { DeleteSaleRecordById } from "../models/usecases/delete-sale-record-by-id";
import { GetAccounts } from "../models/usecases/get-accounts";
import { GetClients } from "../models/usecases/get-clients";
import { GetCountries } from "../models/usecases/get-countries";
import { GetCredits } from "../models/usecases/get-credits";
import { GetDefects } from "../models/usecases/get-defects";
import { GetDistributorDashboard } from "../models/usecases/get-distributor-dashboard";
import { GetDistributors } from "../models/usecases/get-distributors";
import { GetEquipments } from "../models/usecases/get-equipments";
import { GetNotifications } from "../models/usecases/get-notifications";
import { GetNotificationsTypes } from "../models/usecases/get-notifications-types";
import { GetPartsList } from "../models/usecases/get-parts-list";
import { GetSaleRecordById } from "../models/usecases/get-sale-record-by-id";
import { PatchAccountDistributorById } from "../models/usecases/patch-account-distributor-by-id";
import { GetSalesParams, GetSalesResponse } from "../models/usecases/sales";
import { UpdateNotifications } from "../models/usecases/update-notifications";
import { UpdateSaleRecordById } from "../models/usecases/update-sale-record-by-id";
import {
	authenticateResponse,
	forgotPasswordRequest,
	loginRequest,
	newPasswordRequest,
	registerRequest,
	updateAccountLanguageRequest,
	updateAccountLanguageResponse,
	updateAccountRequest
} from "../models/usecases/users";
import { store } from "../store";

const isLogged = store.getState().auth.token;

const axiosConfig: AxiosRequestConfig = {
	baseURL: import.meta.env.VITE_REACT_APP_API_URL,
	headers: { "X-Custom-Header": "foobar" },
	validateStatus: (status) => {
		if (status >= 400 && isLogged) {
			window.location.href = `/${i18n.language}/error`;
		}
		return status >= 200 && status < 400;
	},
	transformResponse: [
		function (data) {
			try {
				return JSON.parse(data);
			} catch (error) {
				return data;
			}
		}
	]
};

export const api = axios.create(axiosConfig);

// users-routes
export const login = (data: loginRequest) =>
	api.post<authenticateResponse>(`/login`, data);

export const register = (data: registerRequest) =>
	api.post<authenticateResponse>(`/signup`, data);

export const forgotPassword = (data: forgotPasswordRequest) =>
	api.post<authenticateResponse>(`/send-mail-recover-account-password`, data);

export const newPassword = (data: newPasswordRequest) =>
	api.post<authenticateResponse>(`/recover-account-password`, data);

export const updateAccountLanguage = ({
	data,
	id
}: {
	data: updateAccountLanguageRequest;
	id: number;
}) => api.put<updateAccountLanguageResponse>(`/accounts/${id}/language`, data);

export const updateSubscriptionAccount = (subscription: any) =>
	api.put(`/subscription`, { subscription });

export const updateAccount = ({
	data,
	id
}: {
	data: updateAccountRequest;
	id: number;
}) => api.put<authenticateResponse>(`/accounts/${id}`, data);

export const getAccountsList = (params: GetAccounts.Params) => {
	const search = params?.term || "";
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;

	return api.get<GetAccounts.Return>(
		`/accounts?term=${search}${limit}${offset}`
	);
};

export const newUser = (data: AddAccount.Params) => {
	return api.post<AddAccount.Return>(`/distributors`, data);
};

export const getListDistributors = (params: GetDistributors.Params) => {
	const search = params.term ? params.term : ``;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	return api.get<GetDistributors.Return>(
		`/distributors?term=${search}${limit}${offset}`
	);
};

export const updateAccountTutorials = (
	data: { tutorials: Array<number> } | []
) => {
	return api.put<AddAccount.Return>(`/accounts-tutorials`, { tutorials: data });
};

export const updateAccountDistributorById = ({
	userId,
	distributorId
}: PatchAccountDistributorById.Params) => {
	return api.patch<PatchAccountDistributorById.Return>(
		`/accounts/${userId}/distributor`,
		{
			distributorId
		}
	);
};

// sales-routes
export const getSaleRecordById = (data: GetSaleRecordById.Params) => {
	return api.get<GetSaleRecordById.Return>(`/sales-records/${data.id}`);
};

export const newSaleRecord = (data: AddSaleRecord.Params) => {
	return api.post<AddSaleRecord.Return>(`/sales-records`, data);
};

export const updateSaleRecord = (
	data: UpdateSaleRecordById.Params,
	id: number
) => {
	return api.put<UpdateSaleRecordById.Return>(`/sales-records/${id}`, data);
};

export const deleteSaleRecord = (params: DeleteSaleRecordById.Params) =>
	api.delete<DeleteSaleRecordById.Return>(`/sales-records/${params.id}`);

export const getListSales = (params: GetSalesParams) => {
	const search = params.term ? params.term : ``;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	const sold = params.sold ? `&sold=${params.sold}` : ``;

	return api.get<GetSalesResponse>(
		`/equipments?term=${search}${limit}${offset}${sold}`
	);
};

// technical-support-routes
export const getTechnicalSupportById = (
	data: GetTechnicalSupportById.Params
) => {
	return api.get<GetTechnicalSupportById.Return>(
		`/technical-support/${data?.id}`
	);
};

export const getListTechnicalSupports = (
	params: GetTechnicalSupportList.Params
) => {
	const search = params.term ? params.term : ``;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	const status = params.status ? `&status=${params.status}` : ``;

	return api.get<GetTechnicalSupportList.Return>(
		`/technical-support-list?term=${search}${limit}${offset}${status}`
	);
};

export const newTechnicalSupport = (data: AddTechnicalSupport.Params) => {
	return api.post<AddTechnicalSupport.Return>(`/technical-support`, data);
};

export const getListParts = (params: any) => {
	const search = params.term ? params.term : ``;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;

	return api.get<GetPartsList.Return>(`parts?term=${search}${limit}${offset}`);
};

export const getListDefects = (params: any) => {
	const search = params.term ? params.term : ``;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;

	return api.get<GetDefects.Return>(`defects?term=${search}${limit}${offset}`);
};

export const addExtraCost = (param: AddExtraCost.Params) => {
	return api.post<AddExtraCost.Return>(`/technical-support/extra-cost`, param);
};

// clients
export const getClientsList = (params: GetClients.Params) => {
	const search = params?.term || "";
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;

	return api.get<GetClients.Return>(`/clients?term=${search}${limit}${offset}`);
};

export const getClientById = (params: GetClientById.Params) => {
	return api.get<GetClientById.Return>(`/client/${params.id}`);
};

// countries
export const getCountriesList = (params: GetCountries.Params) => {
	const search = params?.term || "";
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;

	return api.get<GetCountries.Return>(
		`/countries?term=${search}${limit}${offset}`
	);
};

// equipments
export const getEquipmentsList = (params: GetEquipments.Params) => {
	const search = params?.term || "";
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	const clientId = params.clientId ? `&clientId=${params?.clientId}` : ``;
	const sold = params.sold ? `&sold=${params.sold}` : ``;

	return api.get<GetEquipments.Return>(
		`/equipments?term=${search}${limit}${offset}${clientId}${sold}`
	);
};

// upload aws
export const upload = (
	file: any,
	callback: (percentCompleted: number) => void
) => {
	const formData = new FormData();
	formData.append("file", file.file, file.name);
	return api.post(`/aws`, formData, {
		onUploadProgress: (progressEvent) => {
			if (progressEvent.total !== null && progressEvent.total !== undefined) {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				callback(percentCompleted);
			} else {
				console.log("Upload Progress: Total size unknown.");
			}
		}
	});
};

// Credit
export const getCreditList = (params: GetCredits.Params) => {
	const search = params?.term || "";
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	const status = params.status ? `&status=${params.status}` : ``;

	return api.get<GetCredits.Return>(
		`/credits?term=${search}${limit}${offset}${status}`
	);
};

// Dashboard

export const getDashboardList = (data: GetDistributorDashboard.Params) => {
	return api.post<GetDistributorDashboard.Return>(
		`/distributors/dashboard`,
		data
	);
};

export const newCommentary = (data: AddComment.Params) => {
	return api.post<AddComment.Return>(
		`/timeline-events/comment/${data.technicalSupportId}`,
		data
	);
};

export const newFinancialReprove = (data: AddFinancialReprove.Params) => {
	return api.post<AddFinancialReprove.Return>(
		`/timeline-events/financial-reprove/${data.technicalSupportId}`,
		data
	);
};

export const newTechnicalReprove = (data: AddTechnicalReprove.Params) => {
	return api.post<AddTechnicalReprove.Return>(
		`/timeline-events/technical-reprove/${data.technicalSupportId}`,
		data
	);
};

export const newTechnicalApproval = (data: AddTechnicalApproval.Params) => {
	return api.post<AddTechnicalApproval.Return>(
		`/approvals/technical/${data.technicalSupportId}`,
		data
	);
};

export const newFinancialApproval = (data: AddFinancialApproval.Params) => {
	return api.post<AddFinancialApproval.Return>(
		`/approvals/financial/${data.technicalSupportId}`,
		data
	);
};

export const authenticateZendesk = (data: AuthZendesk.Params) => {
	return api.post<AuthZendesk.Return>(`/auth/zendesk`, data);
};

export const authenticateZendeskSunco = (data: AuthZendesk.Params) => {
	return api.post(`/auth/zendesk-sunco`, data);
};

// Notifications

export const getNotificationsList = (params: GetNotifications.Params) => {
	const search = params?.term || "";
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	const status = params.status ? `&status=${params.status}` : "";

	return api.get<GetNotifications.Return>(
		`/notifications?term=${search}${limit}${offset}${status}`
	);
};

export const getNotificationsTypes = () => {
	return api.get<GetNotificationsTypes.Return>(`/notifications/types`);
};

export const updateNotificationUserType = (
	data: updateNotificationsTypes.Params
) => {
	return api.put<updateNotificationsTypes.Return>(`/notification/update`, data);
};

export const updateNotificationStatusById = (
	data: UpdateNotifications.Params
) => {
	return api.put(`/notifications`, data);
};

export const updateNotificationStatusAll = () => {
	return api.put(`/notifications/all`);
};

export const getCreditDetails = (creditId: string, distributorCode: string) => {
	return api.get(`/credits/writeOff/${distributorCode}/${creditId}`);
};
