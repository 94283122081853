import { LoadingProgress } from "@praticabr/ppsa";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ArrowDropIcon from "../../assets/SvgIcons/ArrowDropIcon";
import { updateAccountDistributorById } from "../../services";
import { updateDistributor } from "../../store/modules/auth/actions";
import ClickAwayListener from "../../utils/ClickAwayListener";
import { DistributorSelector } from "./DistributorSelector";
import "./styles.scss";

export const DistributorDropdown = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [showDistributorSelector, setShowDistributorSelector] =
		useState<boolean>(false);

	const dispatch = useDispatch();

	const handlerUserSelectorToggle = () => {
		setShowDistributorSelector(!showDistributorSelector);
	};

	const {
		availableDistributors = [],
		distributorId = null,
		id = null
	} = useSelector((state: any) => state.auth);

	const handleCatchDistributorName = (): string => {
		if (!availableDistributors) {
			return "";
		}

		const distributorName = availableDistributors?.filter(
			(item: any) => item.distributor.id === distributorId
		)[0]?.distributor?.name;

		return distributorName;
	};

	const handleChangeDistributor = async (distributorId: number) => {
		setIsLoading(true);
		await updateAccountDistributorById({ userId: id, distributorId })
			.then((response) => {
				dispatch(updateDistributor(response?.data?.distributorId));
				window.location.reload();
			})
			.catch(() => {
				toast.error("Erro ao atualizar o distribuidor");
				setIsLoading(false);
			});
	};

	const handleDropDownIcon = () => {
		return showDistributorSelector ? (
			<ArrowDropIcon width="10px" height="10px" fill="#025CB7" direction="up" />
		) : (
			<ArrowDropIcon
				width="10px"
				height="10px"
				fill="#025CB7"
				direction="down"
			/>
		);
	};

	const distributorSelectorContent = () => {
		return showDistributorSelector ? (
			<DistributorSelector
				{...{
					handleChangeDistributor,
					onClose: handlerUserSelectorToggle
				}}
			/>
		) : (
			<></>
		);
	};

	return (
		<>
			{availableDistributors?.length > 1 && (
				<>
					<ClickAwayListener
						onClickAway={() => setShowDistributorSelector(false)}
					>
						<div
							onClick={handlerUserSelectorToggle}
							className="distributor-area"
						>
							<span className="distributor-name">
								{handleCatchDistributorName()}
							</span>
							{handleDropDownIcon()}
							{distributorSelectorContent()}
						</div>
					</ClickAwayListener>
					{isLoading && <LoadingProgress />}
				</>
			)}
		</>
	);
};
