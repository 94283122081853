import {
	Button,
	Divider,
	GenericTable,
	Input,
	LoadingProgress,
	Modal,
	Select
} from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputPhoneIntl } from "..";
import SearchIcon from "../../assets/SvgIcons/SearchIcon";
import TrashIcon from "../../assets/SvgIcons/TrashIcon";
import { UserContext } from "../../context";
import { GetAccounts } from "../../models/usecases/get-accounts";
import { updateAccountRequest } from "../../models/usecases/users";
import { store } from "../../store";
import { isPhoneValid } from "../../utils/ValidatedPhone";
import { AddButton } from "../AddButton";
import { DistributorSearch } from "../DistributorSearchModal";
import "./styles.scss";

type Props = {
	onClose: () => void;
	user: GetAccounts.Account;
};

const roleOptions = [
	{
		value: "distributor_user",
		label: "Distributor User"
	},
	{
		value: "comex_user",
		label: "Comex User"
	},
	{
		value: "financial_user",
		label: "Financial User"
	},
	{
		value: "quality_user",
		label: "Quality User"
	},
	{
		value: "digital_administrator",
		label: "Administrator"
	}
];

const languageOptions = [
	{
		value: "en",
		label: t("language.english")
	},
	{
		value: "pt",
		label: t("language.portuguese")
	},
	{
		value: "es",
		label: t("language.spanish")
	}
];

export const ManageUserModal = ({ onClose, user }: Props) => {
	const [showModalSearchDistributors, setShowModalSearchDistributors] =
		useState(false);
	const [dealerIndex, setDealerIndex] = useState<number | null>(null);
	const { isLoading, onUpdateAccount } = useContext(UserContext);

	const { t } = useTranslation();

	const userRole = store.getState().auth.role;

	const {
		handleSubmit,
		register,
		setValue,
		formState: { errors },
		getFieldState,
		control
	} = useForm<updateAccountRequest>({
		defaultValues: {
			availableDistributors: user.availableDistributors.length
				? user.availableDistributors
						.filter((ad) => ad.distributor.deletedAt === null)
						.map((distributor) => ({
							codeProtheus: distributor.distributor.codeProtheus,
							name: distributor.distributor.name,
							idDistributor: distributor.distributor.id
						}))
				: [
						{
							codeProtheus: user.distributor.codeProtheus,
							name: user.distributor.name
						}
					],
			email: user.email,
			language: user.language,
			phone: user.phone,
			contact: user.contact,
			role: user.role
		}
	});

	const { fields, remove, append, update } = useFieldArray({
		control,
		name: "availableDistributors"
	});

	return (
		<>
			<Modal.root>
				<Modal.header title={t("manageUserModal.title")} onClose={onClose} />
				<Modal.body>
					<Modal.body.main>
						<div className="manage-user-modal">
							<div className="content">
								<Divider title={t("manageUserModal.divider.distributor")} />
								<div className="manage-user-modal-distributors-table">
									<GenericTable
										data={[
											...fields.map((ad, index) => ({
												...ad,
												codeProtheus: ad.codeProtheus || (
													<div className="input-code-protheus">
														<Input
															{...register(
																`availableDistributors.${index}.codeProtheus`,
																{
																	required: true
																}
															)}
															errors={
																getFieldState(
																	`availableDistributors.${index}.codeProtheus`
																).error
															}
															variant={"light"}
														/>
														<div
															onClick={() => {
																setShowModalSearchDistributors(true);
																setDealerIndex(index);
															}}
														>
															<SearchIcon
																width={"33px"}
																height={"33px"}
																fill="var(--primary-light)"
															/>
														</div>
													</div>
												),
												name: ad.name || (
													<Input
														{...register(
															`availableDistributors.${index}.name`,
															{
																required: true
															}
														)}
														errors={
															getFieldState(
																`availableDistributors.${index}.name`
															).error
														}
														variant={"light"}
													/>
												),
												selected:
													user.distributor.id === ad.idDistributor
														? "Selecionado"
														: "",
												actionTable: (
													<div
														onClick={() => remove(index)}
														className="action-icon"
													>
														<TrashIcon width="24" height="24" fill="#5daeff" />
													</div>
												)
											}))
										]}
										columns={[
											{
												key: "codeProtheus",
												header: t("registerUserModal.inputCodeProtheus.label"),
												width: "30%"
											},
											{
												key: "name",
												header: t(
													"registerUserModal.inputDistributorName.label"
												),
												width: "50%"
											},
											{
												key: "selected",
												header: "",
												width: "calc(20% - 24px)"
											},
											{
												key: "actionTable",
												header: "",
												width: "24px"
											}
										]}
									/>
								</div>
								<div>
									<AddButton
										label={t("manageUserModal.addButton")}
										onAddItem={() =>
											append({
												codeProtheus: "",
												name: ""
											})
										}
									/>
								</div>
								<Divider title={t("manageUserModal.divider.account")} />
								<div className="form">
									<div className="form-item">
										<label htmlFor="email">
											{t("manageUserModal.emailInput.label")}
										</label>
										<Input variant="light" disabled value={user.email} />
									</div>
									<div className="form-item">
										<label htmlFor="contact">
											{t("manageUserModal.contactInput.label")}
										</label>
										<Input
											variant="light"
											defaultValue={user.contact}
											errors={errors.contact}
											placeholder={t(
												"manageUserModal.contactInput.placeholder"
											)}
											{...register("contact", {
												required: {
													value: true,
													message: t(
														"manageUserModal.contactInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="phone">
											{t("manageUserModal.phoneInput.label")}
										</label>
										<InputPhoneIntl
											name="phone"
											control={control}
											inputRef={{
												...register("phone", {
													required: {
														value: true,
														message: t(
															"manageUserModal.phoneInput.requiredAlert"
														)
													},
													validate: (value: any) => {
														if (!isPhoneValid(value)) {
															return t(
																"manageUserModal.phoneInput.invalidAlert"
															);
														}
													}
												})
											}}
											errors={errors.phone}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="language">
											{t("manageUserModal.languageInput.label")}
										</label>
										<Select
											id="language"
											onSelectOptions={(option) =>
												setValue("language", option[0])
											}
											options={languageOptions}
											placeholder={t(
												"manageUserModal.languageInput.placeholder"
											)}
											selectedOption={[user.language]}
											{...register("language", {
												required: {
													value: true,
													message: t(
														"manageUserModal.languageInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
									{userRole === "digital_administrator" && (
										<div className="form-item">
											<label htmlFor="role">
												{t("manageUserModal.roleInput.label")}
											</label>
											<Select
												id="role"
												onSelectOptions={(option) =>
													setValue("role", option[0])
												}
												options={roleOptions}
												placeholder={t("manageUserModal.roleInput.placeholder")}
												selectedOption={[user.role]}
												{...register("role", {
													required: {
														value: !!(userRole === "digital_administrator"),
														message: t(
															"manageUserModal.roleInput.requiredAlert"
														)
													}
												})}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="actions">
							<Button
								title={t("manageUserModal.submitAction")}
								size="lg"
								variant="confirmation-solid"
								onClick={handleSubmit((data) =>
									onUpdateAccount({ data, userId: user.id, callback: onClose })
								)}
							/>
							<Button
								size="lg"
								title={t("manageUserModal.cancelAction")}
								variant="cancellation"
								onClick={onClose}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{showModalSearchDistributors && (
				<DistributorSearch
					onClose={() => {
						setShowModalSearchDistributors(false);
					}}
					onChangeDistributor={(distributor) => {
						dealerIndex && update(dealerIndex, distributor);
						setShowModalSearchDistributors(false);
					}}
					arrayDistributors={fields}
				/>
			)}
			{isLoading && <LoadingProgress />}
		</>
	);
};
