import { GenericTable } from "@praticabr/ppsa";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

type Props = {
	list?: any[];
};

export const Equipments = ({ list }: Props) => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();

	return (
		<div
			className="equipments"
			onClick={() => navigate(`/${i18n.language}/sales`)}
		>
			<div className="header">
				<p>{t("dashboardPage.equipments.title")}</p>
			</div>
			<div className="content">
				<GenericTable
					data={[
						...(list?.map((item) => ({
							...item,
							code: item.code || "-",
							description:
								i18n.language === "en"
									? item.descriptionEn
									: i18n.language === "es"
										? item.descriptionEs
										: item.descriptionPt || "-",
							amount: item.amount
						})) || [])
					]}
					columns={[
						{
							key: "code",
							header: t("dashboardPage.equipments.tableColumns.code"),
							width: "15%"
						},
						{
							key: "description",
							header: t("dashboardPage.equipments.tableColumns.description"),
							width: "55%"
						},
						{
							key: "amount",
							header: t("dashboardPage.equipments.tableColumns.amount"),
							width: "30%"
						}
					]}
				/>
			</div>
		</div>
	);
};
