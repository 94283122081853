import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GetPartsList } from "../models/usecases/get-parts-list";
import { getListParts } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const PartsContext = createContext({
	list: {} as GetPartsList.Return | undefined,
	isLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onGetPartsList: () => {}
});

const defaultParams = defaultListParams();

export const PartsProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [partsList, setPartsLists] = useState<GetPartsList.Return>();

	const listParams = useRef<ListParams>(defaultParams);

	const { t } = useTranslation();

	const handleGetPartsList = async () => {
		try {
			const { limit, offset, term } = { ...listParams.current };
			const currentPage = offset - 1;
			setIsLoading(true);

			const response = await getListParts({
				limit,
				offset: currentPage,
				term: term || ""
			});
			updateParams(Math.ceil(response.data.total / limit), "total");
			setPartsLists(response.data);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast.error(t("partsContext.getPartsListError"));
		}
	};

	const onSearchParams = (term: string) => {
		updateParams(term, "search");
		handleGetPartsList();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetPartsList();
	};

	const value = {
		list: partsList,
		...listParams.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetPartsList: handleGetPartsList,
		partsList
	};

	return (
		<PartsContext.Provider value={value}>{children}</PartsContext.Provider>
	);
};
