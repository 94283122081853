import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../assets/SvgIcons/HomeIcon";
import MaterialSheetIcon from "../../assets/SvgIcons/MaterialSheetIcon";
import OrderIcon from "../../assets/SvgIcons/OrderIcon";
import PaymentIcon from "../../assets/SvgIcons/PaymentIcon";
import ServiceIcon from "../../assets/SvgIcons/ServiceIcon";
import UserIcon from "../../assets/SvgIcons/UserIcon";
import LogoPratica from "../../assets/images/logo-pratica.webp";
import { AuthContext } from "../../context";
import { store } from "../../store";
import { NavBar } from "../NavBar";
import "./styles.scss";

type TParams = {
	children?: React.ReactNode;
};

export const DefaultLayoutRoot = ({ children }: TParams) => {
	const { user } = useContext(AuthContext);

	const { t, i18n } = useTranslation();

	const isDigitalOrComexUser =
		user.role?.split("_")[0] === "digital" ||
		user.role?.split("_")[0] === "comex";
	const isNotDistributor =
		user.role?.split("_")[0] === "quality" ||
		user.role?.split("_")[0] === "comex" ||
		user.role?.split("_")[0] === "financial" ||
		isDigitalOrComexUser;

	const language = i18n.language || store.getState().auth.language;

	const listLinks = [
		{
			title: "HOME",
			link: `/${language}/home`,
			icon: <HomeIcon />,
			hasHole: !isNotDistributor,
			joyride: "joyride-dashboard"
		},
		{
			title: t("navbar.salesRecords"),
			link: `/${language}/sales`,
			icon: <OrderIcon />,
			hasHole: true,
			joyride: "joyride-salesrecord"
		},
		{
			title: t("navbar.assistance"),
			link: `/${language}/services`,
			icon: <ServiceIcon />,
			hasHole: true,
			joyride: "joyride-assistancerecords"
		},
		{
			title: t("navbar.creditConsultation"),
			link: `/${language}/credits`,
			icon: <PaymentIcon />,
			hasHole: true,
			nextToBottom: !isDigitalOrComexUser,
			joyride: "joyride-credits"
		},
		{
			title: t("navbar.users"),
			link: `/${language}/users`,
			icon: <UserIcon />,
			hasHole: isDigitalOrComexUser,
			joyride: "joyride-salesrecord",
			nextToBottom: true
		},
		{
			title: t("navbar.technicalMaterials"),
			link: `#`,
			icon: <MaterialSheetIcon />,
			hasHole: true,
			target: true,
			joyride: "joyride-technicalmaterials"
		}
	];

	return (
		<div className="main-content-module">
			<NavBar.root logo={{ src: LogoPratica, alt: "Prática" }}>
				{listLinks.map(
					(item, index) =>
						item.hasHole && (
							<NavBar.linkList
								key={index}
								link={item.link}
								title={item.title}
								icon={item.icon}
								nextToBottom={item?.nextToBottom}
								target={item?.target}
								joyride={item?.joyride}
							/>
						)
				)}
			</NavBar.root>
			<main className="main-article">{children}</main>
		</div>
	);
};
