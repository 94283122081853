import React, {
	ReactNode,
	createContext,
	useEffect,
	useRef,
	useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useZendeskWidget from "../components/SmoochWebChat/WebWidget";
import {
	authenticateResponse,
	forgotPasswordRequest,
	loginRequest,
	newPasswordRequest,
	updateAccountLanguageRequest,
	updateAccountRequest
} from "../models/usecases/users";
import {
	authenticateZendesk,
	forgotPassword,
	newPassword,
	updateAccount,
	updateAccountLanguage,
	updateSubscriptionAccount
} from "../services";
import { apiSocket } from "../services/webSocket";
import { store } from "../store";
import {
	signInSuccess,
	signOut,
	useSignInRequest
} from "../store/modules/auth/actions";

export const AuthContext = createContext({
	user: {} as authenticateResponse,
	isLoading: false,
	isCompleted: false,
	onLogin: (data: loginRequest) => {},
	onLogout: () => {},
	onForgotPassword: (data: forgotPasswordRequest) => {},
	onUpdateUser: (data: updateAccountRequest, callback?: () => void) => {},
	onUpdateUserLanguage: (
		data: updateAccountLanguageRequest,
		callback?: () => void
	) => {},
	onRecoverPassword: (data: newPasswordRequest, callback: () => void) => {},
	zendeskAuthentication: (callback: (token: string) => void) => {}
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCompleted, setIsCompleted] = useState<boolean>(false);

	const signInRequest = useSignInRequest();

	const userRef = useRef<authenticateResponse>(store.getState().auth);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useZendeskWidget(
		"d2a1436d-287b-424f-b946-e03829b93174",
		{
			webWidget: {
				color: { theme: "#61dafb" }
			}
		},
		userRef.current
	);

	useEffect(() => {
		const auth = store.getState().auth;
		if (auth.token) {
			userRef.current = auth;
			navigator.serviceWorker
				.register("/service-worker.js")
				.then(async (serviceWorker) => {
					let subscription = await serviceWorker.pushManager.getSubscription();

					if (!subscription) {
						const publicKeyResponse = await apiSocket.get("/push/public-key");

						subscription = await serviceWorker.pushManager.subscribe({
							userVisibleOnly: true,
							applicationServerKey: publicKeyResponse.data.publicKey
						});
					}

					updateSubscriptionAccount(subscription).catch((err) => {
						console.log(err);
					});

					return Notification.requestPermission();
				})
				.then(async (permission) => {
					if (permission === "granted") {
						console.log("Notification permission granted.");
					} else {
						console.log("Unable to get permission to notify.");
					}
				})
				.catch((err) => {
					console.log("Error during service worker registration:", err);
				});
		}
	}, []);

	useEffect(() => {}, []);

	const handleLogin = async (data: loginRequest) => {
		try {
			setIsLoading(true);
			await signInRequest(data);
			toast.success(t("authContext.loginSuccess"));
		} catch (error) {
			setIsLoading(false);
			toast.error(t("authContext.loginError"));
		}
	};

	const handleForgotPassword = (data: forgotPasswordRequest) => {
		try {
			setIsLoading(true);
			forgotPassword(data);
		} catch (error) {
			setIsLoading(false);
		} finally {
			setIsLoading(false);
			setIsCompleted(true);
		}
	};

	const handleRecoverPassword = (
		data: newPasswordRequest,
		callback: () => void
	) => {
		setIsLoading(true);
		newPassword(data)
			.then((response) => {
				userRef.current = response.data;
				dispatch(signInSuccess(response.data));
				toast.success(t("authContext.updatePasswordSuccess"));
				callback();
			})
			.catch(() => toast.error(t("authContext.updatePasswordError")))
			.finally(() => setIsLoading(false));
	};

	const handleUpdateUser = (
		data: updateAccountRequest,
		callback?: () => void
	) => {
		setIsLoading(true);
		updateAccount({ data, id: userRef.current.id })
			.then((response) => {
				dispatch(signInSuccess(response.data));
				userRef.current = response.data;
				toast.success(t("authContext.updateUserSuccess"));
				callback && callback();
			})
			.catch(() => toast.error(t("authContext.updateUserError")))
			.finally(() => setIsLoading(false));
	};

	const handleUpdateUserLanguage = (
		data: updateAccountLanguageRequest,
		callback?: () => void
	) => {
		setIsLoading(true);
		updateAccountLanguage({ data, id: userRef.current.id })
			.then((response) => {
				const newUser = {
					...userRef.current,
					language: response.data.language
				};

				dispatch(signInSuccess(newUser));
				userRef.current = newUser;
				toast.success(t("authContext.updateUserSuccess"));
				callback && callback();
			})
			.catch(() => toast.error(t("authContext.updateUserError")))
			.finally(() => setIsLoading(false));
	};

	const handleLogout = () => {
		userRef.current = {} as authenticateResponse;
		dispatch(signOut());
	};

	const zendeskAuthentication = (callback: (token: string) => void) => {
		const { email, contact } = userRef.current;

		const payload = {
			name: contact,
			email
		};

		authenticateZendesk(payload)
			.then((response) => {
				callback(response.data.token);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const value = {
		user: userRef.current,
		isLoading,
		isCompleted,
		onLogin: handleLogin,
		onLogout: handleLogout,
		onUpdateUser: handleUpdateUser,
		onUpdateUserLanguage: handleUpdateUserLanguage,
		onForgotPassword: handleForgotPassword,
		onRecoverPassword: handleRecoverPassword,
		zendeskAuthentication
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
