import { Input, Select } from "@praticabr/ppsa";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ClientSearch, InputPhoneIntl } from "..";
import { ClientContext } from "../../context";
import i18n from "../../i18n/i18n";
import { ClientModel } from "../../models";
import { AddSaleRecord } from "../../models/usecases/add-sale-record";
import { SelectionClient } from "./SelectionClient";

type Props = {
	clientForm: UseFormReturn<Pick<AddSaleRecord.Params, "client">>;
	nextItem: () => void;
	selectClient: boolean;
	setSelectClient: Dispatch<SetStateAction<boolean>>;
	setShowButton: Dispatch<SetStateAction<boolean>>;
};

export const RegisterSaleClientInfo = ({
	clientForm,
	nextItem,
	selectClient,
	setSelectClient,
	setShowButton
}: Props) => {
	const { countries } = useContext(ClientContext);
	const [searchClientModal, setSearchClientModal] = useState(false);
	const countriesOptions = countries.map((country) => ({
		value: String(country.id),
		label:
			i18n.language === "en"
				? country.nameEn
				: i18n.language === "es"
					? country.nameEs
					: country.namePt || "-"
	}));

	const { t } = useTranslation();

	const {
		register,
		setValue,
		getValues,
		control,
		formState: { errors },
		getFieldState
	} = clientForm;

	const handleSelectClient = async (client: ClientModel) => {
		setValue("client.city", client.city);
		setValue("client.countryId", client.countryId);
		setValue("client.email", client.email);
		setValue("client.id", client.id);
		setValue("client.name", client.name);
		setValue("client.phone", client.phone || "");
		setValue("client.type", client.type || "");
		await clientForm.handleSubmit(() => {})();
		nextItem();
		setShowButton(true);
		setSelectClient(false);
	};

	const handleNewClient = () => {
		setValue("client.city", "");
		setValue("client.countryId", 0);
		setValue("client.email", "");
		setValue("client.id", 0);
		setValue("client.name", "");
		setValue("client.phone", "");
		setValue("client.type", "");
		nextItem();
		setShowButton(false);
		setSelectClient(false);
	};

	return (
		<>
			{!selectClient ? (
				<div className="client-contain">
					<div className="form">
						<div className="form-item" id="joyride-sales-registration">
							<label htmlFor="name">
								{t("newSaleRecordModal.clientContain.nameInput.label")}
							</label>
							<div>
								<Input
									variant="light"
									placeholder={t(
										"newSaleRecordModal.clientContain.nameInput.placeholder"
									)}
									errors={errors.client?.name}
									{...register("client.name", {
										required: {
											value: true,
											message: t(
												"newSaleRecordModal.clientContain.nameInput.requiredAlert"
											)
										}
									})}
								/>
							</div>
						</div>
						<div className="form-item">
							<label htmlFor="email">
								{t("newSaleRecordModal.clientContain.emailInput.label")}
							</label>
							<Input
								variant="light"
								placeholder={t(
									"newSaleRecordModal.clientContain.emailInput.placeholder"
								)}
								errors={errors.client?.email}
								{...register("client.email", {
									required: {
										value: true,
										message: t(
											"newSaleRecordModal.clientContain.emailInput.requiredAlert"
										)
									},
									pattern: {
										value:
											/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: t("saleRecordModal.form.emailInput.patternAlert")
									}
								})}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="type">
								{t("newSaleRecordModal.clientContain.typeInput.label")}
							</label>
							<Select
								id="type"
								onSelectOptions={(option) => setValue("client.type", option[0])}
								options={[
									{
										value: "",
										label: t("newSaleRecordModal.clientContain.typeOptions.0")
									},
									{
										value: "finalCustomer",
										label: t("newSaleRecordModal.clientContain.typeOptions.1")
									},
									{
										value: "dealer",
										label: t("newSaleRecordModal.clientContain.typeOptions.2")
									}
								]}
								placeholder={t(
									"newSaleRecordModal.clientContain.typeInput.placeholder"
								)}
								selectedOption={[String(getValues("client.type"))]}
								{...register("client.type", {
									required: {
										value: true,
										message: t(
											"newSaleRecordModal.clientContain.typeInput.requiredAlert"
										)
									}
								})}
								errors={getFieldState("client.type").error}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="client.phone">
								{t("newSaleRecordModal.clientContain.phoneInput.label")}
							</label>
							<InputPhoneIntl
								name="client.phone"
								control={control}
								inputRef={{
									...register("client.phone")
								}}
								errors={errors.client?.phone}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="city">
								{t("newSaleRecordModal.clientContain.cityInput.label")}
							</label>
							<Input
								variant="light"
								placeholder={t(
									"newSaleRecordModal.clientContain.cityInput.placeholder"
								)}
								errors={errors.client?.city}
								{...register("client.city", {
									required: {
										value: true,
										message: t(
											"newSaleRecordModal.clientContain.cityInput.requiredAlert"
										)
									}
								})}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="country">
								{t("newSaleRecordModal.clientContain.countryInput.label")}
							</label>
							<Select
								id="countryId"
								autoComplete="none"
								onSelectOptions={(option) =>
									setValue("client.countryId", +option[0])
								}
								options={countriesOptions}
								selectedOption={[String(getValues("client.countryId"))]}
								placeholder={t(
									"newSaleRecordModal.clientContain.countryInput.placeholder"
								)}
								errors={errors.client?.countryId}
								{...register("client.countryId", {
									required: {
										value: true,
										message: t(
											"newSaleRecordModal.clientContain.countryInput.requiredAlert"
										)
									}
								})}
							/>
						</div>
					</div>
				</div>
			) : (
				<SelectionClient
					onChangeClient={handleSelectClient}
					handleNewClient={() => {
						handleNewClient();
					}}
				/>
			)}
			{searchClientModal && (
				<ClientSearch
					onClose={() => setSearchClientModal(false)}
					onChangeClient={handleSelectClient}
				/>
			)}
		</>
	);
};
