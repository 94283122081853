import { UploadFiles } from "./UploadFiles";
import { UploadFilesView } from "./UploadFilesView";
import { UploadModal } from "./UploadModal";
import { UploadRoot } from "./UploadRoot";

export const Upload = {
	root: UploadRoot,
	files: UploadFiles,
	modal: UploadModal,
	view: UploadFilesView
};
