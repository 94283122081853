import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
	listFiles: any[] | undefined;
};

export const UploadFilesView = ({ listFiles }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="upload-file-list">
			{listFiles?.map((file, index) => (
				<div className="upload-file-list-item" key={index}>
					<a rel="noreferrer" href={file.url} target="_blank" download>
						{t("uploadFiles.attachment")} {index + 1}
					</a>
				</div>
			))}
		</div>
	);
};
