import { GenericTable, Modal } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext } from "react";
import { TechnicalSupportsContext } from "../../context";
import i18n from "../../i18n/i18n";
import "./styles.scss";

interface Props {
	onClose: () => void;
}

export const AttachmentModal = ({ onClose }: Props) => {
	const { technicalSupport } = useContext(TechnicalSupportsContext);

	const handleSelectService = (e: any) => {
		window.open(e.url, "_blank");
	};

	const filesList: any[] = [];

	return (
		<Modal.root>
			<Modal.header title={t("attachmentModal.title")} onClose={onClose} />
			<Modal.body>
				<Modal.body.main>
					<div className="attachment-modal">
						<span className="no-select info">{t("attachmentModal.info")}</span>
						<div className="files-list">
							<GenericTable
								onRowClicked={handleSelectService}
								data={filesList.concat(
									technicalSupport?.files.map((item) => ({
										...item,
										date: new Date(item.createdAt).toLocaleDateString(
											i18n.language,
											{
												timeZone: "UTC"
											}
										)
									})),
									technicalSupport?.events
										.map((event) =>
											event.files.map((item) => ({
												...item,
												date: new Date(item.createdAt).toLocaleDateString(
													i18n.language,
													{
														timeZone: "UTC"
													}
												)
											}))
										)
										.flat()
								)}
								columns={[
									{
										key: "description",
										header: t("attachmentModal.table.description"),
										width: "50%"
									},
									{
										key: "extension",
										header: t("attachmentModal.table.extension"),
										width: "30%"
									},
									{
										key: "date",
										header: t("attachmentModal.table.date"),
										width: "20%"
									}
								]}
							/>
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	);
};
