import { Input, Select } from "@praticabr/ppsa";
import React, { SetStateAction, useContext, useEffect, useState } from "react";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputPhoneIntl, Upload } from "..";
import { DefectsContext } from "../../context/DefectsContext";
import i18n from "../../i18n/i18n";
import { UploadFile } from "../../models";
import { AddService } from "../../models/usecases/add-service";
import { store } from "../../store";
import { StepServices } from "../../utils/JoyrideSteps";
import { JoyRide } from "../JoyRide";
type Props = {
	client: {
		name: string;
		email: string;
	};
	selectedItens: any[];
	setSelectedEquipments: (value: SetStateAction<any[]>) => void;
	technicalSupportsForm: UseFormReturn<
		Pick<AddService.Params, "technicalSupports">,
		any,
		undefined
	>;
	technicalSupportsField: UseFieldArrayReturn<
		Pick<AddService.Params, "technicalSupports">,
		"technicalSupports",
		"id"
	>;
	filesForm: UseFormReturn<UploadFile, "files", undefined>;
	fieldFiles: UseFieldArrayReturn<UploadFile, "files", "id">;
	setFileDeleted: (value: SetStateAction<any[]>) => void;
};
export const ServiceTechnicalInfo = ({
	selectedItens,
	technicalSupportsForm,
	technicalSupportsField,
	filesForm,
	fieldFiles,
	setFileDeleted
}: Props) => {
	const { list, onGetDefectsList } = useContext(DefectsContext);
	const [modalUploadFiles, setModalUploadFiles] = useState(false);
	const [itemSelected, setItemSelected] = useState<any>(
		selectedItens[0].equipmentId
	);
	const [itemIndex, setItemIndex] = useState<number>(0);

	const userStore = store.getState().auth;

	const language =
		store.getState().auth.language || navigator.language?.split("-")[0] || "pt";

	useEffect(() => {
		onGetDefectsList();
	}, []);

	const defectOptions =
		list?.defects?.map((defect) => {
			return {
				label:
					language === "en"
						? defect.descriptionEn
						: language === "es"
							? defect.descriptionEs
							: defect.descriptionPt,
				value: String(defect.id)
			};
		}) || [];

	const { t } = useTranslation();

	const validate = () => {
		const technicalSupports =
			technicalSupportsForm.getValues().technicalSupports;

		for (let index = 0; index < technicalSupports.length; index++) {
			const item = technicalSupports[index];

			if (
				!item.technician?.email ||
				!item.technician?.contact ||
				!item.technician?.phone ||
				!item.servicePerformed ||
				!item.defectId
			) {
				const { technician, servicePerformed, defectId } = item;
				const { email, contact, phone } = technician || {};

				if (!email) {
					technicalSupportsForm.setError(
						`technicalSupports.${index}.technician.email`,
						{
							type: "required",
							message: t(
								"newServiceModal.technicalContain.technicalEmail.requiredAlert"
							)
						}
					);
				}
				if (!contact) {
					technicalSupportsForm.setError(
						`technicalSupports.${index}.technician.contact`,
						{
							type: "required",
							message: t(
								"newServiceModal.technicalContain.technicalName.requiredAlert"
							)
						}
					);
				}
				if (!phone) {
					technicalSupportsForm.setError(
						`technicalSupports.${index}.technician.phone`,
						{
							type: "required",
							message: t(
								"newServiceModal.technicalContain.technicalPhone.requiredAlert"
							)
						}
					);
				}
				if (!servicePerformed) {
					technicalSupportsForm.setError(
						`technicalSupports.${index}.servicePerformed`,
						{
							type: "required"
						}
					);
				}
				if (!defectId) {
					technicalSupportsForm.setError(
						`technicalSupports.${index}.defectId`,
						{
							type: "required",
							message: t(
								"newServiceModal.technicalContain.technicalDefect.requiredAlert"
							)
						}
					);
				}
			}
			if (item) {
				technicalSupportsForm.clearErrors([
					`technicalSupports.${index}.technician.email`,
					`technicalSupports.${index}.technician.contact`,
					`technicalSupports.${index}.technician.phone`,
					`technicalSupports.${index}.servicePerformed`
				]);
				break;
			}
		}
	};

	const {
		register,
		formState: { errors },
		clearErrors,
		getValues,
		setValue,
		control,
		watch
	} = technicalSupportsForm;

	useEffect(() => {
		if (
			technicalSupportsForm
				.getValues()
				.technicalSupports.map((item) => !!item.defectId)[0]
		) {
			technicalSupportsField.replace(
				[...technicalSupportsForm.getValues().technicalSupports].map(
					(item, index) => {
						return { ...item, equipmentId: selectedItens[index]?.equipmentId };
					}
				)
			);
		} else {
			technicalSupportsField.replace(
				selectedItens.map((item) => {
					return {
						equipmentId: item.equipmentId,
						technician: {
							email: undefined,
							contact: undefined,
							phone: undefined
						},
						defectId: undefined
					} as any;
				})
			);
		}
	}, []);

	const onFileDeleted = (file: any) => {
		setFileDeleted((files) => [...files, file]);
	};

	const formContent = (item: number) => {
		return (
			item !== undefined && (
				<form className="form" autoComplete="off">
					<div className="form-item">
						<label htmlFor="name">
							{t("newServiceModal.technicalContain.technicalName.label")}
						</label>
						<Input
							variant="light"
							placeholder={t(
								"newServiceModal.technicalContain.technicalName.placeholder"
							)}
							errors={errors.technicalSupports?.[item]?.technician?.contact}
							{...register(`technicalSupports.${item}.technician.contact`, {
								required: {
									value: true,
									message: t(
										"newServiceModal.technicalContain.technicalName.requiredAlert"
									)
								}
							})}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="email">
							{t("newServiceModal.technicalContain.technicalEmail.label")}
						</label>
						<Input
							variant="light"
							placeholder={t(
								"newServiceModal.technicalContain.technicalEmail.placeholder"
							)}
							errors={errors.technicalSupports?.[item]?.technician?.email}
							{...register(`technicalSupports.${item}.technician.email`, {
								required: {
									value: true,
									message: t(
										"newServiceModal.technicalContain.technicalEmail.requiredAlert"
									)
								}
							})}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="phone">
							{t("newServiceModal.technicalContain.technicalPhone.label")}
						</label>
						<InputPhoneIntl
							name={`technicalSupports.${item}.technician.phone`}
							control={control}
							inputRef={{
								...register(`technicalSupports.${item}.technician.phone`, {
									required: {
										value: true,
										message: t(
											"newServiceModal.technicalContain.technicalPhone.requiredAlert"
										)
									}
								})
							}}
							errors={errors.technicalSupports?.[item]?.technician?.phone}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="defectId">
							{t("newServiceModal.technicalContain.technicalDefect.label")}
						</label>
						<Select
							autoComplete="none"
							options={defectOptions}
							selectedOption={[
								String(getValues(`technicalSupports.${item}.defectId`))
							]}
							onSelectOptions={(option) => {
								setValue(
									`technicalSupports.${item}.defectId`,
									Number(option[0])
								);
								clearErrors(`technicalSupports.${item}.defectId`);
							}}
							{...register(`technicalSupports.${item}.defectId`, {
								required: {
									value: true,
									message: t(
										"newServiceModal.technicalContain.technicalDefect.requiredAlert"
									)
								}
							})}
							errors={errors.technicalSupports?.[item]?.defectId}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="service-performed">
							{t("newServiceModal.technicalContain.technicalDescription.label")}
						</label>
						<Input
							variant="light"
							placeholder={t(
								"newServiceModal.technicalContain.technicalDescription.placeholder"
							)}
							errors={errors.technicalSupports?.[item]?.servicePerformed}
							{...register(`technicalSupports.${item}.servicePerformed`, {
								required: true
							})}
						/>
					</div>
					<div className="form-item">
						<Upload.root
							label={t("newServiceModal.technicalContain.technicalFiles.label")}
							text={t("newServiceModal.technicalContain.technicalFiles.text")}
							onAddItem={() => setModalUploadFiles(true)}
						>
							{modalUploadFiles && (
								<Upload.modal
									onClose={() => {
										setModalUploadFiles(false);
									}}
									filesForm={filesForm}
									fieldFiles={fieldFiles}
									indexItem={item}
								/>
							)}
							<Upload.files
								fieldFiles={fieldFiles}
								indexItem={item}
								{...{ onFileDeleted }}
							/>
						</Upload.root>
					</div>
				</form>
			)
		);
	};

	useEffect(() => {}, [itemIndex]);

	return (
		<div className="technical-contain">
			<form className="items" autoComplete="off" id="joyride-support-info-tech">
				<Select
					autoComplete="off"
					id="selected-itens"
					variant="light"
					options={[
						...selectedItens.map((item, index) => {
							const technicalSupportsErrors =
								technicalSupportsForm.getFieldState("technicalSupports").error;
							if (Array.isArray(technicalSupportsErrors)) {
								const newListItem = {
									value: item.equipmentId,
									label: `${
										i18n.language === "en"
											? item.equipmentModel.descriptionEn
											: i18n.language === "es"
												? item.equipmentModel.descriptionEs
												: item.equipmentModel.descriptionPt || "-"
									} - NS: ${item.serialNumber}${
										technicalSupportsErrors[index] ? "    ❌" : "    ✅"
									}`
								};
								return newListItem;
							} else {
								return {
									value: item.equipmentId,
									label: `${
										i18n.language === "en"
											? item.equipmentModel.descriptionEn
											: i18n.language === "es"
												? item.equipmentModel.descriptionEs
												: item.equipmentModel.descriptionPt || "-"
									} - NS: ${item.serialNumber}`
								};
							}
						})
					]}
					selectedOption={[itemSelected]}
					onSelectOptions={(selectOptions) => {
						const index = selectedItens.findIndex(
							(item) => item.equipmentId === selectOptions[0]
						);
						technicalSupportsForm.handleSubmit(() => {})();
						validate();
						setItemSelected(selectOptions[0]);
						setItemIndex(index);
						setValue(
							`technicalSupports.${index}.technician.contact`,
							getValues(`technicalSupports.${index}.technician.contact`) ||
								getValues(
									`technicalSupports.${
										index > 0 ? index - 1 : 0
									}.technician.contact`
								)
						);
						setValue(
							`technicalSupports.${index}.technician.email`,
							getValues(`technicalSupports.${index}.technician.email`) ||
								getValues(
									`technicalSupports.${
										index > 0 ? index - 1 : 0
									}.technician.email`
								)
						);
						setValue(
							`technicalSupports.${index}.technician.phone`,
							getValues(`technicalSupports.${index}.technician.phone`) ||
								getValues(
									`technicalSupports.${
										index > 0 ? index - 1 : 0
									}.technician.phone`
								)
						);
						setValue(
							`technicalSupports.${index}.servicePerformed`,
							getValues(`technicalSupports.${index}.servicePerformed`) ||
								getValues(
									`technicalSupports.${
										index > 0 ? index - 1 : 0
									}.servicePerformed`
								)
						);
					}}
				/>
			</form>
			{formContent(itemIndex)}
			{!userStore.tutorials?.some((e: any) => e.id === 3) &&
				userStore.role === "distributor_user" && (
					<JoyRide steps={StepServices} run={true} id={3} backButton={true} />
				)}
		</div>
	);
};
