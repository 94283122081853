import React from "react";
import { Controller } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "./styles.scss";

type Props = {
	name: string;
	inputRef?: any;
	control?: any;
	errors?: any;
	disabled?: boolean;
};

export const InputPhoneIntl = ({
	name,
	inputRef,
	control,
	errors,
	disabled
}: Props) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange, value } }) => (
					<PhoneInput
						value={value}
						disabled={disabled}
						onChange={onChange}
						inputProps={{ ...inputRef }}
						className={errors ? "input-error" : ""}
						defaultCountry={`${navigator.language
							?.split("-")[1]
							?.toLocaleLowerCase()}`}
					/>
				)}
			/>
			<div className="span-error-container">
				{errors && <span className="input-error-span">{errors.message}</span>}
			</div>
		</>
	);
};
