import { produce } from "immer";

const INITIAL_STATE = {
	menuShow: null
};

export default function user(state = INITIAL_STATE, action: any) {
	return produce(state, (draft) => {
		switch (action.type) {
			case "@user/SET_MENU_SHOW":
				draft.menuShow = action.payload;
				break;
			default:
		}
	});
}
