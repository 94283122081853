import React from "react";
import "./styles.scss";

interface ArrowDropIconProps {
	width: string;
	height: string;
	fill?: string;
	direction?: "up" | "right" | "down" | "left";
}
const ArrowDropIcon: React.FC<ArrowDropIconProps> = ({
	fill,
	width,
	height,
	direction
}) => {
	const onChangeDirection = () => {
		switch (direction) {
			case "up":
				return 180;
				break;
			case "right":
				return -90;
				break;
			case "down":
				return 0;
				break;
			case "left":
				return 90;
				break;
			default:
				return -90;
				break;
		}
	};

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 12.252 5.694"
			style={{
				transform: `rotate(${onChangeDirection()}deg)`
			}}
		>
			<path
				id="Caminho_442"
				data-name="Caminho 442"
				d="M6.126,0l6.126,5.694L6.126,5.216,0,5.694Z"
				transform="translate(12.252 5.694) rotate(180)"
				fill={fill}
			/>
		</svg>
	);
};

export default ArrowDropIcon;
