import { LoadingProgress } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import AttachmentIcon from "../../assets/SvgIcons/AttachmentIcon";
import { TimelineEvent } from "../../components";
import { AttachmentModal } from "../../components/AttachmentModal";
import { JoyRide } from "../../components/JoyRide";
import { AuthContext, TechnicalSupportsContext } from "../../context";
import i18n from "../../i18n/i18n";
import { store } from "../../store";
import { StepDetailsServices } from "../../utils/JoyrideSteps";
import { ActionButton } from "./ActionButton";
import "./styles.scss";

export const ServiceDetail = () => {
	const { user } = useContext(AuthContext);
	const { isLoading, technicalSupport, onGetTechnicalSupport } = useContext(
		TechnicalSupportsContext
	);

	const userStore = store.getState().auth;

	const isDistributor = userStore?.role === "distributor_user";

	const [showAttachmentModal, setShowAttachmentModal] = useState(false);

	const { t } = useTranslation();

	const serviceId = useLocation().pathname.split("services/")[1];

	useEffect(() => {
		if (serviceId) onGetTechnicalSupport({ id: Number(serviceId) });
	}, [serviceId]);

	const handleAttachment = () => {
		setShowAttachmentModal(true);
	};

	const handleCloseModal = () => {
		setShowAttachmentModal(false);
	};

	const dueDate = new Date();
	dueDate.setDate(dueDate.getDate() + 60);

	return (
		<>
			<article className="service-detail">
				<div className="information">
					<div className="header">
						<h1>
							{i18n.language === "en"
								? technicalSupport?.equipment.equipmentModel.descriptionEn
								: i18n.language === "es"
									? technicalSupport?.equipment.equipmentModel.descriptionEs
									: technicalSupport?.equipment.equipmentModel.descriptionPt ||
										"-"}
						</h1>
						{!isDistributor && (
							<div className="info">
								<span>{t("serviceDetailPage.headerInfos.billingDate")}</span>
								<span>
									{technicalSupport?.equipment?.acquisitionDate
										? new Date(
												technicalSupport?.equipment?.acquisitionDate
											).toLocaleDateString(i18n.language, { timeZone: "UTC" })
										: "-"}
								</span>
							</div>
						)}
						<div className="info">
							<span>{t("serviceDetailPage.headerInfos.serialNumber")}</span>
							<span>{technicalSupport?.equipment.serialNumber}</span>
						</div>
						<div className="info">
							<div onClick={handleAttachment}>
								<AttachmentIcon height="18" width="24" />
								<span className="info2 no-select" onClick={handleAttachment}>
									{t("serviceDetailPage.headerInfos.attachmentFiles")}
								</span>
							</div>
						</div>
					</div>
					<div className="main-contain">
						<div className="info">
							<span>
								{t("serviceDetailPage.headerInfos.technicalSupportId")}
							</span>
							<span>{technicalSupport?.id || "-"}</span>
						</div>
						<div className="double">
							<div className="info">
								<span>
									{t("serviceDetailPage.headerInfos.installationDate")}
								</span>
								<span>
									{technicalSupport?.equipment?.installationDate
										? new Date(
												technicalSupport?.equipment?.installationDate
											).toLocaleDateString(i18n.language, {
												timeZone: "UTC"
											})
										: "-"}
								</span>
							</div>
							<div className="info">
								<span>
									{t("serviceDetailPage.headerInfos.distributorOrder")}
								</span>
								<span>
									{technicalSupport?.equipment.salesRecord?.distributorOrder ||
										"-"}
								</span>
							</div>
						</div>
						<div className="info">
							<span>{t("serviceDetailPage.headerInfos.clientName")}</span>
							<span>
								{technicalSupport?.equipment.salesRecord?.client?.name || "-"}
							</span>
						</div>
						<div className="info">
							<span>{t("serviceDetailPage.headerInfos.clientEmail")}</span>
							<span>
								{technicalSupport?.equipment.salesRecord?.client?.email || "-"}
							</span>
						</div>
						<div className="double">
							<div className="info">
								<span>{t("serviceDetailPage.headerInfos.clientType")}</span>
								<span>
									{technicalSupport?.equipment.salesRecord?.client?.type
										? t(
												`serviceDetailPage.valueInfo.${technicalSupport?.equipment.salesRecord?.client?.type}`
											)
										: "-"}
								</span>
							</div>
							<div className="info">
								<span>{t("serviceDetailPage.headerInfos.clientPhone")}</span>
								<span>
									{technicalSupport?.equipment.salesRecord?.client?.phone ||
										"-"}
								</span>
							</div>
						</div>
						<div className="double">
							<div className="info">
								<span>{t("serviceDetailPage.headerInfos.clientCity")}</span>
								<span>
									{technicalSupport?.equipment.salesRecord?.client?.city || "-"}
								</span>
							</div>
							<div className="info">
								<span>{t("serviceDetailPage.headerInfos.clientCountry")}</span>
								<span>
									{i18n.language === "en"
										? technicalSupport?.equipment.salesRecord?.client?.country
												.nameEn
										: i18n.language === "es"
											? technicalSupport?.equipment.salesRecord?.client?.country
													.nameEs
											: technicalSupport?.equipment.salesRecord?.client?.country
													.namePt || "-"}
								</span>
							</div>
						</div>
						<div className="double">
							<div className="info">
								<span>{t("serviceDetailPage.headerInfos.requestedValue")}</span>
								<span>
									{technicalSupport?.replacedParts
										?.filter((f) => !f.partReplacement)
										?.reduce((a, b) => a + b.amount * b.historicalValue, 0)
										.toLocaleString("en", {
											style: "currency",
											currency: "USD"
										}) || "-"}
								</span>
							</div>
							<div className="info">
								<span>{t("serviceDetailPage.headerInfos.approvedValue")}</span>
								<span>
									{technicalSupport?.approvals
										?.filter((part) => part.type === "FINANCIAL-APPROVED")
										?.reduce(
											(a, b) =>
												a +
												b?.approvedParts
													?.filter((f) => !f.partReplacement)
													.reduce(
														(a, b) => a + b.amount * b.historicalValue,
														0
													),
											0
										)
										.toLocaleString("en", {
											style: "currency",
											currency: "USD"
										})}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="timeline" id="joyride-support-timeline">
					{technicalSupport?.events?.map((item, index) => (
						<TimelineEvent key={index} event={item} />
					))}
				</div>
				{!(user?.role?.split("_")[0] === "quality") && <ActionButton />}
			</article>
			{showAttachmentModal && <AttachmentModal onClose={handleCloseModal} />}
			{isLoading && <LoadingProgress />}
			{!userStore.tutorials?.some((e: any) => e.id === 4) &&
				userStore.role === "distributor_user" && (
					<JoyRide
						steps={StepDetailsServices}
						run={true}
						id={4}
						backButton={true}
					/>
				)}
		</>
	);
};
