import { Button, CheckBox, Divider, Modal } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NotificationContext } from "../../context/NotificationContext";
import { store } from "../../store";
import { updateNotificationManager } from "../../store/modules/auth/actions";
import "./styles.scss";

type INotificationManagerModal = {
	onClose: () => void;
};

export const NotificationManagerModal = ({
	onClose
}: INotificationManagerModal) => {
	const {
		notificationTypes,
		isLoading,
		onGetNotificationTypes,
		handleUpdateNotificationUserType
	} = useContext(NotificationContext);

	const [listServiceOptions, setListServiceOptions] = useState<any[]>([]);
	const [listSalesOptions, setListSalesOptions] = useState<any[]>([]);

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const user = store.getState().auth;
	const { notificationManager = [], allowEmailNotification } = user;
	const { register, handleSubmit, getValues, setValue } = useForm({
		defaultValues: {
			allowEmailNotification,
			notificationTypesId: notificationManager || []
		}
	});

	useEffect(() => {
		onGetNotificationTypes();
	}, []);

	const onSubmit = (data: any) => {
		handleUpdateNotificationUserType(data, (response) => {
			dispatch(updateNotificationManager(response));
			onClose();
		});
	};

	const handleSelectCheckbox = (item: number) => {
		const currentIndex = getValues("notificationTypesId")?.findIndex(
			(type: number) => type === item
		);
		const newChecked = [
			...getValues("notificationTypesId")?.map((type: number) => type)
		];

		if (currentIndex === -1) {
			newChecked.push(item);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setValue("notificationTypesId", newChecked);
	};

	const notificationTypesSales = ["updatedSaleRecord", "deletedSalesRecord"];

	useEffect(() => {
		if (!notificationTypes?.typesList?.length) return;
		setListSalesOptions(
			notificationTypes?.typesList?.filter((type) =>
				notificationTypesSales.includes(type.description)
			)
		);
		setListServiceOptions(
			notificationTypes?.typesList?.filter(
				(type) => !notificationTypesSales.includes(type.description)
			)
		);
	}, [notificationTypes?.typesList?.length]);

	return (
		<Modal.root>
			<Modal.header
				{...{ onClose }}
				title={t("notificationManagerModal.title")}
			/>
			<Modal.body>
				<Modal.body.main id="notification-manager-component" {...{ isLoading }}>
					<Divider title={t("notificationManagerModal.dividers.email")} />
					<div className="notification-manager-email">
						<label htmlFor="email-notifications">
							<CheckBox
								id="email-notifications"
								variant="standard"
								format="square"
								{...register("allowEmailNotification")}
							/>
							<span>
								{t(
									"notificationManagerModal.modalOptions.allowEmailNotifications"
								)}
							</span>
						</label>
					</div>
					<Divider title={t("notificationManagerModal.dividers.sales")} />
					<div className="notification-manager">
						{!!listSalesOptions?.length &&
							listSalesOptions?.map((type: any) => (
								<label key={type.id} htmlFor={type.id}>
									<CheckBox
										id={type.id}
										variant="standard"
										format="square"
										defaultChecked={getValues("notificationTypesId")?.some(
											(obj: number) => obj === type.id
										)}
										onChange={() => handleSelectCheckbox(type.id)}
									/>
									<span>
										{t(
											`notificationManagerModal.modalOptions.${type.description}`
										)}
									</span>
								</label>
							))}
					</div>
					<Divider title={t("notificationManagerModal.dividers.services")} />
					<div className="notification-manager">
						{!!listServiceOptions?.length &&
							listServiceOptions.map((type: any) => (
								<label key={type.id} htmlFor={type.id}>
									<CheckBox
										id={type.id}
										variant="standard"
										format="square"
										defaultChecked={getValues("notificationTypesId")?.some(
											(obj: number) => obj === type.id
										)}
										onChange={() => handleSelectCheckbox(type.id)}
									/>
									<span>
										{t(
											`notificationManagerModal.modalOptions.${type.description}`
										)}
									</span>
								</label>
							))}
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="notification-manager-action">
						<Button
							variant="confirmation-solid"
							title={t("notificationManagerModal.button.confirmation")}
							size={"lg"}
							onClick={handleSubmit(onSubmit)}
							disabled={isLoading}
						/>
						<Button
							variant="cancellation"
							title={t("notificationManagerModal.button.cancellation")}
							size={"lg"}
							onClick={onClose}
							disabled={isLoading}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	);
};
