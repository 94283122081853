import React from "react";

interface Props {}

const AlertSvg: React.FC<Props> = () => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M30 15C30 17.9667 29.1203 20.8668 27.472 23.3335C25.8238 25.8003 23.4811 27.7229 20.7403 28.8582C17.9994 29.9935 14.9834 30.2906 12.0736 29.7118C9.16393 29.133 6.49119 27.7044 4.3934 25.6066C2.29562 23.5088 0.867006 20.8361 0.288227 17.9264C-0.290551 15.0166 0.00649931 12.0006 1.14181 9.25975C2.27713 6.51885 4.19971 4.17618 6.66645 2.52796C9.13319 0.879734 12.0333 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0217 30 15ZM15 18.024C14.4495 18.0238 13.9112 18.1868 13.4534 18.4926C12.9955 18.7983 12.6387 19.233 12.4279 19.7416C12.2172 20.2502 12.1621 20.8099 12.2695 21.3498C12.3769 21.8898 12.6421 22.3857 13.0315 22.775C13.4209 23.1642 13.9169 23.4291 14.4569 23.5363C14.9969 23.6435 15.5566 23.5881 16.0651 23.3772C16.5736 23.1662 17.0082 22.8092 17.3137 22.3512C17.6192 21.8932 17.7821 21.3549 17.7816 20.8044C17.781 20.067 17.4877 19.36 16.9661 18.8386C16.4446 18.3173 15.7374 18.0243 15 18.024ZM12.36 8.0232L12.8088 16.2492C12.8175 16.4343 12.8971 16.6089 13.0312 16.7367C13.1653 16.8646 13.3435 16.9358 13.5288 16.9356H16.4652C16.6505 16.9358 16.8287 16.8646 16.9628 16.7367C17.0969 16.6089 17.1766 16.4343 17.1852 16.2492L17.634 8.0232C17.6402 7.92488 17.6262 7.82633 17.5929 7.73364C17.5595 7.64095 17.5074 7.5561 17.4399 7.48434C17.3725 7.41257 17.291 7.35542 17.2005 7.31642C17.11 7.27742 17.0125 7.2574 16.914 7.2576H13.0824C12.9835 7.25674 12.8855 7.27627 12.7944 7.31498C12.7034 7.35369 12.6213 7.41074 12.5533 7.48258C12.4853 7.55441 12.4328 7.63949 12.3991 7.73251C12.3655 7.82552 12.3513 7.92448 12.3576 8.0232H12.36Z"
				fill="black"
				className="alert-svg"
			/>
		</svg>
	);
};

export default AlertSvg;
