import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo-pratica.webp";
import Pratica from "../../assets/images/pratica-fab.webp";
import { SwitchLanguage } from "../SwitchLanguage";
import "./styles.scss";

export const AuthenticateRoot = ({ children }: { children: ReactNode }) => {
	const { t } = useTranslation();
	return (
		<div className="authenticate-page">
			<div className="content">
				<div className="logo">
					<img width={"100%"} src={logo} alt="Prática" />
				</div>
				{children}
			</div>
			<div className="background">
				<img width={"100%"} src={Pratica} alt="background" />
			</div>
			<div className="language">
				<p>{t("language.title")}</p>
				<SwitchLanguage />
			</div>
		</div>
	);
};
