import { LoadingList, Modal } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../context/NotificationContext";
import { NotificationManagerModal } from "../NotificationManagerModal";
import "./styles.scss";

interface Props {
	onClose: () => void;
}

const NotificationsModal: React.FC<Props> = ({ onClose }) => {
	const [showNotificationManagerModal, setShowNotificationManagerModal] =
		useState(false);
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const { list, onSearchParams, onChangeStatus, onChangeAllStatus, isLoading } =
		useContext(NotificationContext);

	useEffect(() => {
		onSearchParams("");
	}, []);

	const changeTitle = (title: string, readAt?: Date) => {
		if (readAt) {
			return <span>{t(`notificationModal.modalList.${title}`)}</span>;
		} else {
			return (
				<span>
					<b>{t(`notificationModal.modalList.${title}`)}</b>
				</span>
			);
		}
	};

	const handleUpdateAllAction = () => {
		return (
			<div
				className={`notify-center-option`}
				onClick={() => {
					onChangeAllStatus();
				}}
			>
				<span>{t("notificationModal.markAllAsRead")}</span>
			</div>
		);
	};

	const handleNotificationClick = (
		notificationId: number,
		technicalSupportId: number
	) => {
		onChangeStatus(notificationId, "read");
		technicalSupportId &&
			navigate(`/${i18n.language}/services/${technicalSupportId}`);
		onClose();
	};

	const changeAction = (notifyId: number, readAt?: Date) => {
		const actionName = !readAt
			? t("notificationModal.markAsRead")
			: t("notificationModal.markAsUnread");
		return (
			<>
				<span
					className="mark-as"
					onClick={() => {
						onChangeStatus(notifyId, !readAt ? "read" : "");
					}}
				>
					{actionName}
				</span>
			</>
		);
	};

	const notificationList = () => {
		return (
			<>
				{list?.notifications.map((notify, index) => (
					<div className={`notification-item`} key={index}>
						<div
							className="notification-item-title"
							onClick={() => {
								handleNotificationClick(
									notify.id,
									JSON.parse(notify.data).technicalSupportId
								);
							}}
						>
							{changeTitle(notify.type, notify.readAt)}
							<small>
								{JSON.parse(notify.data).technicalSupportId ||
									JSON.parse(notify.data).saleRecordId}
							</small>
						</div>
						<div className="notification-item-options">
							{changeAction(notify.id, notify.readAt)}
							<span className="notify-time">
								{`${new Date(notify.createdAt).toLocaleTimeString()} - 
								${new Date(notify.createdAt).toLocaleDateString(i18n.language, {
									timeZone: "UTC"
								})}`}
							</span>
						</div>
					</div>
				))}
			</>
		);
	};

	return (
		<>
			<div className="notifications-modal">
				<Modal.root>
					<Modal.header
						onClose={onClose}
						title={t("notificationModal.title")}
					/>
					<div className="notifications-content">
						{handleUpdateAllAction()}
						<div className="notify-center-content">
							{isLoading && !showNotificationManagerModal ? (
								<LoadingList />
							) : (
								notificationList()
							)}
						</div>
					</div>
					<div
						className="notify-center-footer"
						onClick={() => setShowNotificationManagerModal(true)}
					>
						<span>{t("notificationModal.manageNotifications")}</span>
					</div>
				</Modal.root>
			</div>
			{showNotificationManagerModal && (
				<NotificationManagerModal
					onClose={() => setShowNotificationManagerModal(false)}
				/>
			)}
		</>
	);
};

export default NotificationsModal;
