import {
	browserTracingIntegration,
	init,
	replayIntegration
} from "@sentry/react";
import packageJson from "../../package.json";

export const useSentry = () => {
	if (import.meta.env.VITE_SENTRY_DSN) {
		init({
			enabled: true,
			tracesSampleRate: 1.0,
			autoSessionTracking: true,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
			environment: import.meta.env.VITE_NODE_ENV,
			release: `${packageJson.name}@${packageJson.version}`,
			tracePropagationTargets: ["https://portal.praticabr.com/"],
			dsn: import.meta.env.VITE_SENTRY_DSN,
			integrations: [
				browserTracingIntegration(),
				replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
					maskAllInputs: false
				})
			]
		});
	}
};
