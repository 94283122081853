import React from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../../store";
import "./styles.scss";

interface Params {
	handleChangeDistributor: (distributorId: number) => void;
}

export const DistributorSelector = ({ handleChangeDistributor }: Params) => {
	const user = store.getState().auth;
	const { availableDistributors = [], distributorId } = user;

	const {
		i18n: { language },
		t
	} = useTranslation();

	return (
		<section className="distributor-area-selector">
			{availableDistributors?.map((item: any, index: number) => (
				<div
					key={index}
					className="distributor-area-selector-item"
					onClick={() =>
						distributorId !== item?.distributor?.id &&
						handleChangeDistributor(item?.distributor?.id)
					}
				>
					<span>{item?.distributor?.name}</span>
				</div>
			))}
		</section>
	);
};
