import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from "chart.js";
import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "./styles.scss";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";
import { DashboardContext } from "../../context";
import DateRangeSelection from "../DateRangeSelection";

type Props = {
	list:
		| {
				amount: number;
				descriptionEn: string;
				descriptionEs: string;
				descriptionPt: string;
		  }[]
		| undefined;
};

export const PartsRegistration = ({ list }: Props) => {
	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend,
		ChartDataLabels
	);

	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	const { t, i18n } = useTranslation();

	const [listParts, setListParts] = React.useState<any[]>([]);

	const { onChangeDate } = useContext(DashboardContext);

	useEffect(() => {
		if (startDate && endDate) {
			onChangeDate(startDate, endDate);
		}
	}, [startDate, endDate]);

	const options = {
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: false
			}
		},
		scales: {
			x: {
				display: false
			}
		}
	};

	const colors = [
		"#423592",
		"#9D94D5",
		"#5288D5",
		"#A6CEE3",
		"#134997",
		"#858F94"
	];

	useEffect(() => {
		setListParts(
			list?.map((item, index) => ({
				description:
					i18n.language === "en"
						? item.descriptionEn
						: i18n.language === "es"
						  ? item.descriptionEs
						  : item.descriptionPt,
				amount: item.amount,
				color: colors[index],
				barPercentage: 0.5
			})) ?? []
		);
	}, [list]);

	const data = {
		labels: list?.map((item) =>
			i18n.language === "en"
				? item.descriptionEn
				: i18n.language === "es"
				  ? item.descriptionEs
				  : item.descriptionPt
		),
		datasets: [
			{
				data: list?.map((item) => item.amount),
				label: t("dashboardPage.partsRegistration.quantity"),
				backgroundColor: colors,
				barPercentage: 0.5
			}
		]
	};

	return (
		<div className="parts-registration">
			<div className="header">
				<h1>{t("dashboardPage.partsRegistration.title")}</h1>
				<DateRangeSelection
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					startDate={startDate}
					endDate={endDate}
				/>
			</div>
			<div className="graphic">
				<h1>{t("dashboardPage.partsRegistration.quantity")}</h1>
				<div className="graphic-content">
					<Bar className="bar" options={options} data={data} />
				</div>
			</div>
			<div className="legend-list">
				<ul className="list">
					{listParts?.map((item, index) => (
						<li key={index}>
							<div style={{ backgroundColor: item.color }} />
							<span title={item.description}>{item.description}</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
