import React, { cloneElement, useEffect, useRef, useState } from "react";

interface ClickAwayListenerProps {
	onClickAway?: () => void;
	children: React.ReactElement;
}

const ClickAwayListener: React.FC<ClickAwayListenerProps> = ({
	onClickAway,
	children
}) => {
	const [clickedAway, setClickedAway] = useState(false);
	const ref = useRef<HTMLElement | null>(null);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setClickedAway(true);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	useEffect(() => {
		if (clickedAway && onClickAway) {
			onClickAway();
		}
		setClickedAway(false);
	}, [clickedAway, onClickAway]);

	return cloneElement(children, {
		ref
	});
};

export default ClickAwayListener;
