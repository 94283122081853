import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GetDefects } from "../models/usecases/get-defects";
import { getListDefects } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const DefectsContext = createContext({
	list: {} as GetDefects.Return | undefined,
	isLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onGetDefectsList: () => {}
});

const defaultParams = defaultListParams();

export const DefectsProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [defectsList, setDefectsLists] = useState<GetDefects.Return>();

	const listParams = useRef<ListParams>(defaultParams);

	const { t } = useTranslation();

	const handleGetDefectsList = async () => {
		try {
			const { term } = { ...listParams.current };
			setIsLoading(true);

			const response = await getListDefects({
				limit: 5000,
				offset: 0,
				term: term || ""
			});
			updateParams(Math.ceil(response.data.total / 5000), "total");
			setDefectsLists(response.data);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast.error(t("defectsContext.getDefectsError"));
		}
	};

	const onSearchParams = (term: string) => {
		updateParams(term, "search");
		handleGetDefectsList();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetDefectsList();
	};

	const value = {
		list: defectsList,
		...listParams.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetDefectsList: handleGetDefectsList,
		defectsList
	};

	return (
		<DefectsContext.Provider value={value}>{children}</DefectsContext.Provider>
	);
};
