import { Input, Select } from "@praticabr/ppsa";
import React, { useContext } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputPhoneIntl } from "..";
import { ClientContext } from "../../context";
import i18n from "../../i18n/i18n";
import { AddService } from "../../models/usecases/add-service";

type Props = {
	clientForm: UseFormReturn<Pick<AddService.Params, "client">>;
};

export const ServiceClientInfo = ({ clientForm }: Props) => {
	const { countries } = useContext(ClientContext);
	const countriesOptions = countries.map((country) => ({
		value: String(country.id),
		label:
			i18n.language === "en"
				? country.nameEn
				: i18n.language === "es"
					? country.nameEs
					: country.namePt || "-"
	}));

	const { t } = useTranslation();

	const {
		register,
		setValue,
		getValues,
		control,
		formState: { errors },
		getFieldState
	} = clientForm;

	return (
		<>
			<div className="client-contain">
				<div className="form">
					<div className="form-item" id="joyride-support-inclusion">
						<label htmlFor="name">
							{t("newServiceModal.clientContain.nameInput.label")}
						</label>
						<div>
							<Input
								variant="light"
								placeholder={t(
									"newServiceModal.clientContain.nameInput.placeholder"
								)}
								errors={errors.client?.name}
								{...register("client.name", {
									required: {
										value: true,
										message: t(
											"newServiceModal.clientContain.nameInput.requiredAlert"
										)
									}
								})}
								disabled={!getValues("client.id")}
							/>
						</div>
					</div>
					<div className="form-item">
						<label htmlFor="email">
							{t("newServiceModal.clientContain.emailInput.label")}
						</label>
						<Input
							variant="light"
							placeholder={t(
								"newServiceModal.clientContain.emailInput.placeholder"
							)}
							errors={errors.client?.email}
							{...register("client.email", {
								required: {
									value: true,
									message: t(
										"newServiceModal.clientContain.emailInput.requiredAlert"
									)
								},
								pattern: {
									value:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: t("saleRecordModal.form.emailInput.patternAlert")
								}
							})}
							disabled={!getValues("client.id")}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="type">
							{t("newServiceModal.clientContain.typeInput.label")}
						</label>
						<Select
							id="type"
							onSelectOptions={(option) => setValue("client.type", option[0])}
							options={[
								{
									value: "",
									label: t("newServiceModal.clientContain.typeOptions.0")
								},
								{
									value: "finalCustomer",
									label: t("newServiceModal.clientContain.typeOptions.1")
								},
								{
									value: "dealer",
									label: t("newServiceModal.clientContain.typeOptions.2")
								}
							]}
							placeholder={t(
								"newServiceModal.clientContain.typeInput.placeholder"
							)}
							selectedOption={[String(getValues("client.type"))]}
							{...register("client.type", {
								required: {
									value: true,
									message: t(
										"newServiceModal.clientContain.typeInput.requiredAlert"
									)
								}
							})}
							errors={getFieldState("client.type").error}
							disabled={!getValues("client.id")}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="client.phone">
							{t("newServiceModal.clientContain.phoneInput.label")}
						</label>
						<InputPhoneIntl
							name="client.phone"
							control={control}
							inputRef={{
								...register("client.phone")
							}}
							errors={errors.client?.phone}
							disabled={!getValues("client.id")}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="city">
							{t("newServiceModal.clientContain.cityInput.label")}
						</label>
						<Input
							variant="light"
							placeholder={t(
								"newServiceModal.clientContain.cityInput.placeholder"
							)}
							errors={errors.client?.city}
							{...register("client.city", {
								required: {
									value: true,
									message: t(
										"newServiceModal.clientContain.cityInput.requiredAlert"
									)
								}
							})}
							disabled={!getValues("client.id")}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="country">
							{t("newServiceModal.clientContain.countryInput.label")}
						</label>
						<Select
							id="countryId"
							autoComplete="none"
							onSelectOptions={(option) =>
								setValue("client.countryId", +option[0])
							}
							options={countriesOptions}
							selectedOption={[String(getValues("client.countryId"))]}
							placeholder={t(
								"newServiceModal.clientContain.countryInput.placeholder"
							)}
							errors={errors.client?.countryId}
							{...register("client.countryId", {
								required: {
									value: true,
									message: t(
										"newServiceModal.clientContain.countryInput.requiredAlert"
									)
								}
							})}
							disabled={!getValues("client.id")}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="report">
							{t("newServiceModal.clientContain.reportInput.label")}
						</label>
						<Input
							variant="light"
							placeholder={t(
								"newServiceModal.clientContain.reportInput.placeholder"
							)}
							{...register("client.report")}
							disabled={!getValues("client.id")}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
