import {
	Button,
	Divider,
	Input,
	LoadingProgress,
	Modal,
	Select
} from "@praticabr/ppsa";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputPhoneIntl } from "..";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { UserContext } from "../../context";
import { AddAccount } from "../../models/usecases/add-accounts";
import { isPhoneValid } from "../../utils/ValidatedPhone";
import { DistributorSearch } from "../DistributorSearchModal";
import "./styles.scss";

type Props = {
	onClose: () => void;
};

export const RegisterUserModal = ({ onClose }: Props) => {
	const [showModal, setShowModal] = React.useState(false);
	const { isLoading, handleNewUser } = useContext(UserContext);

	const { t } = useTranslation();

	const languageOptions = [
		{
			value: "en",
			label: t("language.english")
		},
		{
			value: "pt",
			label: t("language.portuguese")
		},
		{
			value: "es",
			label: t("language.spanish")
		},
		{
			value: "ru",
			label: t("language.russian")
		}
	];

	const {
		handleSubmit,
		register,
		setValue,
		formState: { errors },
		control
	} = useForm<AddAccount.Params>({
		defaultValues: {
			distributor: {
				codeProtheus: "",
				name: ""
			},
			account: {
				email: "",
				language: "",
				phone: "",
				contact: ""
			}
		}
	});

	return (
		<>
			<Modal.root>
				<Modal.header title={t("registerUserModal.title")} onClose={onClose} />
				<Modal.body>
					<Modal.body.main>
						<div className="register-user-modal">
							<div className="content">
								<Divider title={t("registerUserModal.divider.distributor")} />
								<div className="form first">
									<div className="form-item">
										<label htmlFor="distributor.codeProtheus">
											{t("registerUserModal.inputCodeProtheus.label")}
										</label>
										<div className="search">
											<Input
												variant="light"
												{...register("distributor.codeProtheus", {
													required: true
												})}
												errors={errors.distributor?.codeProtheus}
											/>
											<div
												onClick={() => {
													setShowModal(true);
												}}
											>
												<SearchSvg />
											</div>
										</div>
									</div>
									<div className="form-item">
										<label htmlFor="distributor.name">
											{t("registerUserModal.inputDistributorName.label")}
										</label>
										<Input
											variant="light"
											{...register("distributor.name", { required: true })}
											errors={errors.distributor?.name}
										/>
									</div>
								</div>
								<Divider title={t("registerUserModal.divider.account")} />
								<div className="form">
									<div className="form-item">
										<label htmlFor="email">
											{t("registerUserModal.emailInput.label")}
										</label>
										<Input
											variant="light"
											{...register("account.email", {
												required: {
													value: true,
													message: t(
														"registerUserModal.emailInput.requiredAlert"
													)
												}
											})}
											errors={errors.account?.email}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="account.contact">
											{t("registerUserModal.contactInput.label")}
										</label>
										<Input
											variant="light"
											errors={errors.account?.contact}
											placeholder={t(
												"registerUserModal.contactInput.placeholder"
											)}
											{...register("account.contact", {
												required: {
													value: true,
													message: t(
														"registerUserModal.contactInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="account.phone">
											{t("registerUserModal.phoneInput.label")}
										</label>
										<InputPhoneIntl
											name="account.phone"
											control={control}
											inputRef={{
												...register("account.phone", {
													required: {
														value: true,
														message: t(
															"registerUserModal.phoneInput.requiredAlert"
														)
													},
													validate: (value: any) => {
														if (!isPhoneValid(value)) {
															return t(
																"registerUserModal.phoneInput.invalidAlert"
															);
														}
													}
												})
											}}
											errors={errors.account?.phone}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="account.language">
											{t("registerUserModal.languageInput.label")}
										</label>
										<Select
											id="account_language"
											onSelectOptions={(option) =>
												setValue("account.language", option[0])
											}
											options={languageOptions}
											placeholder={t(
												"registerUserModal.languageInput.placeholder"
											)}
											selectedOption={[]}
											{...register("account.language", {
												required: {
													value: true,
													message: t(
														"registerUserModal.languageInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
								</div>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="actions">
							<Button
								title={t("registerUserModal.submitAction")}
								size="lg"
								variant="confirmation-solid"
								onClick={handleSubmit((data) => handleNewUser(data, onClose))}
							/>
							<Button
								size="lg"
								title={t("registerUserModal.cancelAction")}
								variant="cancellation"
								onClick={onClose}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{isLoading && <LoadingProgress />}
			{showModal && (
				<DistributorSearch
					onClose={onClose}
					onChangeDistributor={(distributor) => {
						setValue("distributor.codeProtheus", distributor.codeProtheus);
						setValue("distributor.name", distributor.name);
						setShowModal(false);
					}}
				/>
			)}
		</>
	);
};
