import React from "react";

interface Props {}

const FinancialSvg: React.FC<Props> = () => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM12.3728 11.7492C12.3728 12.6996 13.1884 13.3092 15.7264 13.8996C18.263 14.49 20.9795 15.4596 20.9808 18.3C20.9808 20.3496 19.2477 21.48 17.0678 21.8496V24H13.7142V21.8304C12.6392 21.7158 11.6418 21.2715 10.8917 20.5731C10.1416 19.8748 9.68512 18.9655 9.6 18H12.0602C12.1823 19.1796 13.0878 20.1 15.391 20.1C17.8619 20.1 18.4093 18.9996 18.4093 18.3096C18.4093 17.3796 17.8499 16.5 15.0556 15.9C11.9368 15.2292 9.80122 14.0796 9.80122 11.7696C9.80122 9.84 11.5451 8.58 13.7142 8.16V6H17.0678V8.1888C18.0607 8.36487 18.9571 8.8381 19.6093 9.53059C20.2615 10.2231 20.6307 11.0936 20.6562 11.9988H18.1852C18.1182 10.7484 17.3804 9.8988 15.391 9.8988C13.5023 9.8988 12.3728 10.6596 12.3728 11.7492Z"
				fill="black"
				className="financial-svg"
			/>
		</svg>
	);
};

export default FinancialSvg;
