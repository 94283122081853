import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GetCreditDetails } from "../models/usecases/get-credit-details";
import { GetCredits } from "../models/usecases/get-credits";
import { getCreditDetails, getCreditList } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const CreditContext = createContext({
	list: {} as GetCredits.Return | undefined,
	isLoading: false,
	creditDetailLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	creditDetails: [] as GetCreditDetails.Return,
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onLegendChange: (legend: string) => {},
	onGetCreditList: (status?: string) => {},
	onGetCreditDetails: (creditId: string, distributorCode: string) => {},
	onClearCreditDetails: () => {}
});

const defaultParams = defaultListParams();

export const CreditProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [creditDetailLoading, setCreditDetailLoading] =
		useState<boolean>(false);
	const [creditsList, setCreditsList] = useState<GetCredits.Return>();
	const [creditDetails, setCreditDetails] = useState<GetCreditDetails.Return>(
		[] as GetCreditDetails.Return
	);

	const listParams = useRef<ListParams>(defaultParams);

	const { t } = useTranslation();

	const handleGetCreditList = async (status?: string) => {
		try {
			const { limit, offset, term, filter } = { ...listParams.current };
			const currentPage = offset - 1;
			setIsLoading(true);

			const response = await getCreditList({
				limit,
				offset: currentPage,
				term: term || "",
				status: filter || ""
			});
			updateParams(Math.ceil(response.data.total / limit), "total");
			setCreditsList(response.data);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast.error(t("creditContext.getCreditListsError"));
		}
	};

	const onSearchParams = (term: string) => {
		updateParams(term, "search");
		handleGetCreditList();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetCreditList();
	};

	const onLegendChange = (legend: string) => {
		updateParams(legend, "filter");
		handleGetCreditList();
	};

	const onGetCreditDetails = (creditId: string, distributorCode: string) => {
		setCreditDetailLoading(true);
		getCreditDetails(creditId, distributorCode)
			.then((response) => {
				setCreditDetails(response.data);
			})
			.catch((err) => {
				console.log("Erro: ", err);
				toast.error("Erro ao buscar baixas de crédito");
			})
			.finally(() => setCreditDetailLoading(false));
	};

	const onClearCreditDetails = () => {
		setCreditDetails([]);
	};

	const value = {
		list: creditsList,
		...listParams.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onLegendChange,
		onGetCreditList: handleGetCreditList,
		onGetCreditDetails,
		creditDetails,
		onClearCreditDetails,
		creditDetailLoading
	};

	return (
		<CreditContext.Provider value={value}>{children}</CreditContext.Provider>
	);
};
