import React from "react";

interface Props {}

const CircularDownloadSvg: React.FC<Props> = () => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM8.08545 23.917C8.14015 23.9701 8.21431 24 8.29167 24H21.7083C21.7857 24 21.8599 23.9701 21.9146 23.917C21.9692 23.8639 22 23.7918 22 23.7167V22.5833C22 22.5082 21.9692 22.4361 21.9146 22.383C21.8599 22.3299 21.7857 22.3 21.7083 22.3H8.29167C8.21431 22.3 8.14015 22.3299 8.08545 22.383C8.03075 22.4361 8 22.5082 8 22.5833V23.7167C8 23.7918 8.03075 23.8639 8.08545 23.917ZM10.1 15.1028L14.7667 21.1474C14.7938 21.1826 14.829 21.2117 14.8695 21.2314C14.91 21.2511 14.9547 21.2613 15 21.2613C15.0453 21.2613 15.09 21.2511 15.1305 21.2314C15.171 21.2117 15.2062 21.1832 15.2333 21.148L19.9 15.1034C19.9325 15.0613 19.9523 15.0112 19.9571 14.9588C19.962 14.9064 19.9518 14.8537 19.9276 14.8066C19.9033 14.7595 19.8661 14.72 19.82 14.6923C19.7739 14.6647 19.7208 14.65 19.6667 14.65H17.625V7.56667C17.625 7.41638 17.5636 7.27229 17.4542 7.16602C17.3448 7.05974 17.1964 7 17.0417 7H12.9583C12.8036 7 12.6552 7.05974 12.5458 7.16602C12.4364 7.27229 12.375 7.41638 12.375 7.56667V14.6494H10.3333C10.2792 14.6494 10.2261 14.6641 10.18 14.6918C10.1339 14.7194 10.0967 14.759 10.0724 14.8061C10.0482 14.8531 10.038 14.9058 10.0429 14.9582C10.0477 15.0106 10.0675 15.0607 10.1 15.1028Z"
				fill="black"
				className="circular-download-svg"
			/>
		</svg>
	);
};

export default CircularDownloadSvg;
