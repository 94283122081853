import {
	ConfirmModal,
	GenericTable,
	LoadingProgress,
	Paginator
} from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
	HeaderSection,
	MoreOptions,
	MoreOptionsItem,
	RegisterSaleModal,
	SaleRecordModal
} from "../../components";
import { JoyRide } from "../../components/JoyRide";
import { AuthContext, SalesContext } from "../../context";
import i18n from "../../i18n/i18n";
import { store } from "../../store";
import { StepsMainFunctions } from "../../utils/JoyrideSteps";
import "./styles.scss";

const distributorCodes = ["DE", "ITD", "FD"];

export const SalesList = () => {
	const { user } = useContext(AuthContext);

	const userStore = store.getState().auth;

	const {
		list,
		total,
		offset,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetSalesLists,
		onDeleteSaleRecord
	} = useContext(SalesContext);

	const { t } = useTranslation();

	const [showModal, setShowModal] = useState(false);
	const [selectedSaleRecordID, setSelectedSaleRecordID] = useState<any>();
	const [moreOptionsEdit, setMoreOptionsEdit] = useState<boolean>(false);
	const [moreOptionsDelete, setMoreOptionsDelete] = useState<boolean>(false);
	const isDistributor = user?.role?.split("_")[0] === "distributor";
	const isComexUser = user?.role?.split("_")[0] === "comex";

	const { availableDistributors = [], distributorId = null } = useSelector(
		(state: any) => state.auth
	);

	const pathname = location.pathname.split("/");

	useEffect(() => {
		if (pathname.length) {
			if (pathname.length === 4) {
				setSelectedSaleRecordID({
					saleRecordId: Number(pathname[3])
				});
			}
		}
	}, []);

	const optionsContent = (item: any) => {
		const optionEdit: MoreOptionsItem = {
			label: t("salesPage.moreOptions.editAction"),
			onClick: () => {
				setSelectedSaleRecordID({ saleRecordId: item.salesRecord.id });
				setMoreOptionsEdit(true);
			}
		};
		const optionDelete: MoreOptionsItem = {
			label: t("salesPage.moreOptions.deleteAction"),
			onClick: () => {
				setSelectedSaleRecordID({ saleRecordId: item.salesRecord.id });
				setMoreOptionsDelete(true);
			}
		};

		const moreOptions = [optionEdit, optionDelete];
		return (
			<div className="options">
				<MoreOptions id={`sales-options-${item.id}`} options={moreOptions} />
			</div>
		);
	};

	useEffect(() => {
		onGetSalesLists();
	}, [offset, total]);

	const onSearch = (search: string) => {
		onSearchParams(search);
	};

	const warrantyDate = (date: string) => {
		const initialDate = new Date(date);
		initialDate.setFullYear(initialDate.getFullYear() + 1);

		const finalDate = initialDate.toLocaleDateString();
		return finalDate;
	};

	const handleDeleteSaleRecord = (saleRecordId: number) => {
		onDeleteSaleRecord({ id: saleRecordId }, handleCloseModal);
	};

	const handleCloseModal = () => {
		onSearchParams("");
		setSelectedSaleRecordID(undefined);
		setMoreOptionsEdit(false);
		setMoreOptionsDelete(false);
		setShowModal(false);
	};

	const isDistributorAvailable = (): boolean => {
		if (!availableDistributors?.length) {
			return false;
		}

		const distributorCode = availableDistributors?.filter(
			(item: any) => item.distributor.id === distributorId
		)[0].distributor.codeProtheus;

		return !distributorCodes.includes(distributorCode);
	};

	return (
		<>
			<article className="sales-list">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={(search) => {
							onSearch(search);
						}}
						text={t("salesPage.searchHeader")}
					/>
					{(isDistributor || isComexUser) && isDistributorAvailable() && (
						<HeaderSection.button
							text={t("salesPage.buttonHeader")}
							onClick={() => setShowModal(true)}
						/>
					)}
				</HeaderSection.root>
				<GenericTable
					onRowClicked={(e) => {
						setSelectedSaleRecordID(e);
					}}
					data={
						list?.equipments && [
							...list.equipments.map((sale) => ({
								id: sale.id,
								saleRecordId: sale.salesRecord.id,
								createdAt: new Date(
									sale.salesRecord.createdAt
								).toLocaleDateString(i18n.language, {
									timeZone: "UTC"
								}),
								distributor: `${sale.distributor.codeProtheus} - ${sale.distributor.name}`,
								saleDate: new Date(
									sale.salesRecord.saleDate
								).toLocaleDateString(i18n.language, { timeZone: "UTC" }),
								client: sale.salesRecord.client.name,
								serialNumber: sale.serialNumber,
								description:
									i18n.language === "en"
										? sale.equipmentModel.descriptionEn
										: i18n.language === "es"
											? sale.equipmentModel.descriptionEs
											: sale.equipmentModel.descriptionPt || "-",
								warranty: warrantyDate(sale.installationDate),
								actionTable: <>{isComexUser && optionsContent(sale)}</>
							}))
						]
					}
					columns={
						isDistributor
							? [
									{
										key: "saleRecordId",
										header: t("salesPage.tableColumns.saleRecordId"),
										width: "10%"
									},
									{
										key: "createdAt",
										header: t("salesPage.tableColumns.createdAt"),
										width: "10%"
									},
									{
										key: "saleDate",
										header: t("salesPage.tableColumns.saleDate"),
										width: "10%"
									},
									{
										key: "client",
										header: t("salesPage.tableColumns.client"),
										width: "20%"
									},
									{
										key: "serialNumber",
										header: t("salesPage.tableColumns.serialNumber"),
										width: "10%"
									},
									{
										key: "description",
										header: t("salesPage.tableColumns.description"),
										width: "37%"
									},
									{
										key: "actionTable",
										header: "",
										width: "3%"
									}
								]
							: [
									{
										key: "saleRecordId",
										header: t("salesPage.tableColumns.saleRecordId"),
										width: "10%"
									},
									{
										key: "createdAt",
										header: t("salesPage.tableColumns.createdAt"),
										width: "10%"
									},
									{
										key: "distributor",
										header: t("salesPage.tableColumns.distributor"),
										width: "15%"
									},
									{
										key: "saleDate",
										header: t("salesPage.tableColumns.saleDate"),
										width: "10%"
									},
									{
										key: "client",
										header: t("salesPage.tableColumns.client"),
										width: "15%"
									},
									{
										key: "serialNumber",
										header: t("salesPage.tableColumns.serialNumber"),
										width: "10%"
									},
									{
										key: "description",
										header: t("salesPage.tableColumns.description"),
										width: "27%"
									},
									{
										key: "actionTable",
										header: "",
										width: "3%"
									}
								]
					}
				/>
				{!!list?.equipments?.length && (
					<Paginator
						totalPages={total}
						currentPage={offset}
						onPageChange={onPageChange}
					/>
				)}
			</article>
			{!moreOptionsDelete && selectedSaleRecordID?.saleRecordId && (
				<SaleRecordModal
					onClose={handleCloseModal}
					moreOptionsEdit={moreOptionsEdit}
					saleRecordID={selectedSaleRecordID.saleRecordId}
				/>
			)}
			{moreOptionsDelete && selectedSaleRecordID?.saleRecordId && (
				<ConfirmModal
					title={t("salesPage.deleteModal.title")}
					text={
						<p>
							<Trans>
								{t("salesPage.deleteModal.text", {
									val: selectedSaleRecordID?.saleRecordId
								})}
							</Trans>
						</p>
					}
					subText={t("salesPage.deleteModal.subText")}
					confirmLabel={t("salesPage.deleteModal.submitAction")}
					cancelLabel={t("salesPage.deleteModal.cancelAction")}
					onConfirm={() =>
						onDeleteSaleRecord({ id: selectedSaleRecordID?.saleRecordId }, () =>
							handleCloseModal()
						)
					}
					onCancel={() => handleCloseModal()}
				/>
			)}
			{showModal && <RegisterSaleModal onClose={handleCloseModal} />}
			{!userStore.tutorials?.some((e: any) => e.id === 1) &&
				userStore.role !== "distributor_user" && (
					<JoyRide
						steps={StepsMainFunctions.filter(
							(item) => item.target !== "#joyride-dashboard"
						)}
						run={true}
						id={1}
					/>
				)}
			{isLoading && <LoadingProgress />}
		</>
	);
};
