import React from "react";
import { MoreOptionsItem } from "..";
import "./styles.scss";

type Props = {
	options: MoreOptionsItem[];
};

export const MenuItem = ({ options }: Props) => {
	const onClick = (option: MoreOptionsItem) => {
		option.onClick(option.item);
	};

	return (
		<section className="more-options-area-selector">
			{options.map((option, index) => (
				<div
					key={index}
					className="more-options-area-selector-item"
					onClick={() => onClick(option)}
				>
					<span>{option.label}</span>
				</div>
			))}
		</section>
	);
};
