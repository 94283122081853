import { Button, CheckBox, GenericTable, Modal } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { TechnicalSupportsContext } from "../../context";
import { AddTechnicalApproval } from "../../models/usecases/add-technical-approval";
import { store } from "../../store";
import "./styles.scss";

interface Props {
	onClose: () => void;
}

export const TechnicalApprovalModal = ({ onClose }: Props) => {
	const { onAddTechnicalApproval, technicalSupport } = useContext(
		TechnicalSupportsContext
	);
	const [selectedItens, setSelectedEquipments] = useState<any[]>([]);

	const { handleSubmit, reset } = useForm<AddTechnicalApproval.Params>({
		defaultValues: {
			technicalSupportId: Number(technicalSupport?.id)
		}
	});

	const language =
		store.getState().auth.language || navigator.language?.split("-")[0] || "pt";

	useEffect(() => {
		reset({
			technicalSupportId: Number(technicalSupport?.id)
		});
	}, [technicalSupport?.id]);

	const handleSelectItems = (item: any) => {
		const currentIndex = selectedItens.findIndex(
			(equipment) => equipment.id === item.id && equipment.code === item.code
		);
		const newChecked = [...selectedItens];

		if (currentIndex === -1) {
			newChecked.push(item);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedEquipments(newChecked);
	};

	const handleSelectAll = () => {
		if (selectedItens.length === partsToApprove?.length) {
			setSelectedEquipments([]);
		} else {
			setSelectedEquipments(
				partsToApprove?.map((replacedPart) => ({
					...replacedPart,
					id: replacedPart.id,
					code: replacedPart.part?.code,
					description:
						language === "en"
							? replacedPart.part?.descriptionEn
							: language === "es"
								? replacedPart.part?.descriptionEs
								: replacedPart.part?.descriptionPt,
					amount: replacedPart.amount,
					historicalValue: replacedPart.part?.value,
					totalValue: (
						replacedPart.part?.value * replacedPart.amount
					).toLocaleString("en", {
						style: "currency",
						currency: "USD"
					}),
					partId: replacedPart.part?.id
				})) as any[]
			);
		}
	};

	const handleAddTechnicalApproval = (data: AddTechnicalApproval.Params) => {
		if (selectedItens.length === 0) {
			return toast.error(t("technicalApprovalModal.errorToast"));
		}

		const params: AddTechnicalApproval.Params = {
			...data,
			approvedParts: selectedItens.map((item) => ({
				amount: item.amount,
				historicalValue: item.historicalValue,
				partId: item.partId,
				partReplacement: item.partReplacement
			}))
		};

		onAddTechnicalApproval(params, onClose);
	};

	const partsToApprove = technicalSupport?.replacedParts?.filter(
		(item) =>
			!technicalSupport?.approvals
				.map((b) =>
					b.approvedParts.some((part) => part.part.id === item.part.id)
				)
				.includes(true)
	);

	return (
		<Modal.root>
			<Modal.header
				title={t("technicalApprovalModal.title")}
				onClose={onClose}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="technical-approve">
						<div className="content">
							<div>
								<span>{t("technicalApprovalModal.description1")}</span>
								<strong>{technicalSupport?.id}</strong>
								<span>{t("technicalApprovalModal.description2")}</span>
							</div>
							<label htmlFor="selectAll" className="select-all">
								<CheckBox
									id="selectAll"
									variant="blue"
									format="square"
									onChange={handleSelectAll}
									checked={selectedItens.length === partsToApprove?.length}
								/>
								<span>{t("technicalApprovalModal.selectAll")}</span>
							</label>
							<div className="replaced-parts-list">
								<GenericTable
									onRowClicked={handleSelectItems}
									data={partsToApprove?.map((replacedPart) => ({
										...replacedPart,
										id: replacedPart.id,
										code: replacedPart.part?.code,
										description:
											language === "en"
												? replacedPart.part?.descriptionEn
												: language === "es"
													? replacedPart.part?.descriptionEs
													: replacedPart.part?.descriptionPt,
										amount: replacedPart.amount,
										historicalValue: replacedPart?.historicalValue,
										totalValue: (
											replacedPart.part?.value * replacedPart.amount
										).toLocaleString("en", {
											style: "currency",
											currency: "USD"
										}),
										partId: replacedPart.part?.id
									}))}
									columns={[
										{
											key: "code",
											checkbox: true,
											selected: selectedItens,
											header: t("technicalApprovalModal.table.code"),
											width: "15%"
										},
										{
											key: "description",
											header: t("technicalApprovalModal.table.description"),
											width: "52%"
										},
										{
											key: "amount",
											header: t("technicalApprovalModal.table.quantity"),
											width: "15%"
										},
										{
											key: "totalValue",
											header: t("technicalApprovalModal.table.totalValue"),
											width: "18%"
										}
									]}
								/>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="actions">
						<Button
							size="lg"
							variant="confirmation-solid"
							title={t("technicalApprovalModal.submitAction")}
							onClick={handleSubmit(handleAddTechnicalApproval)}
						/>
						<Button
							size="lg"
							variant="cancellation"
							title={t("technicalApprovalModal.cancelAction")}
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	);
};
