import React, {
	ReactNode,
	createContext,
	useEffect,
	useRef,
	useState
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CountryModel } from "../models";
import { GetClientById } from "../models/usecases";
import { GetClients } from "../models/usecases/get-clients";
import { GetCountries } from "../models/usecases/get-countries";
import { getClientById, getClientsList, getCountriesList } from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export const ClientContext = createContext({
	list: {} as GetClients.Return,
	client: {} as GetClientById.Return,
	countries: [] as CountryModel[],
	isLoading: false,
	limit: {} as number,
	total: {} as number,
	offset: {} as number,
	onPageChange: (page: number) => {},
	onSearchParams: (searchTerm: string) => {},
	onGetClientsList: () => {},
	onGetClientById: (
		id: number,
		callback?: (client: GetClientById.Return) => void
	) => {}
});

const defaultParams = defaultListParams();

export const ClientProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [clientsList, setClientsList] = useState<GetClients.Return>(
		{} as GetClients.Return
	);
	const [client, setClient] = useState<GetClientById.Return>(
		{} as GetClientById.Return
	);

	const { t } = useTranslation();

	const countries = useRef<CountryModel[]>([]);
	const params = useRef<ListParams>(defaultParams);

	useEffect(() => {
		handleGetCountriesLists({ limit: 1000, offset: 0, term: "" });
	}, []);

	const handleGetClientsLists = async () => {
		try {
			const currentPage = params.current.offset - 1;
			setIsLoading(true);
			const response = await getClientsList({
				...params.current,
				offset: currentPage,
				term: params.current.term || ""
			});
			setClientsList(response.data);
			updateParams(
				Math.ceil(response.data.total / params.current.limit),
				"total"
			);
		} catch (error) {
			toast.error(t("clientContext.getClientsListsError"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleGetClientById = async (
		id: number,
		callback?: (client: GetClientById.Return) => void
	) => {
		try {
			setIsLoading(true);
			const response = await getClientById({ id });
			setClient(response?.data);
			callback && callback(response?.data);
		} catch (error) {
			toast.error(t("clientContext.getClientByIdError"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleGetCountriesLists = async (params: GetCountries.Params) => {
		try {
			setIsLoading(true);
			const response = await getCountriesList(params);
			countries.current = response.data.countries;
		} catch (error) {
			toast.error(t("clientContext.getCountriesListError"));
		} finally {
			setIsLoading(false);
		}
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		handleGetClientsLists();
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		params.current = updateListParams(value, field, params.current);
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		handleGetClientsLists();
	};

	const value = {
		list: clientsList,
		client,
		countries: countries.current,
		...params.current,
		isLoading,
		onPageChange,
		onSearchParams,
		onGetClientsList: handleGetClientsLists,
		onGetClientById: handleGetClientById
	};

	return (
		<ClientContext.Provider value={value}>{children}</ClientContext.Provider>
	);
};
