import { GenericTable } from "@praticabr/ppsa";
import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AuthContext, TechnicalSupportsContext } from "../../context";
import i18n from "../../i18n/i18n";
import { store } from "../../store";
import "./styles.scss";

type Props = {
	event: {
		account: {
			contact: string;
			id: number;
		};
		createdAt: Date;
		files: {
			description: string;
			extension: string;
			id: number;
			url: string;
		}[];
		id: number;
		text: string;
		type: string;
	};
};

export const TimelineEvent = ({ event }: Props) => {
	const { user } = useContext(AuthContext);
	const { technicalSupport } = useContext(TechnicalSupportsContext);
	const hasDistributorUser = user?.role?.split("_")[0] === "distributor";
	const { t } = useTranslation();

	const language =
		store.getState().auth.language || navigator.language?.split("-")[0] || "pt";

	let title: any = event.type;
	let description = event.text;
	let created = false;

	switch (title) {
		case "CREATED-TECHNICAL-SUPPORT":
			title = `${t("serviceDetailPage.events.created.title")} ${
				technicalSupport?.equipment?.distributor?.name
			}`;
			description = `${t(
				"serviceDetailPage.events.created.description1"
			)} ${technicalSupport?.id} ${t(
				"serviceDetailPage.events.created.description2"
			)} ${technicalSupport?.equipment.salesRecord?.client?.name} ${t(
				"serviceDetailPage.events.created.description3"
			)} ${event.account?.contact} ${t(
				"serviceDetailPage.events.created.description4"
			)} `;
			created = true;
			break;
		case "NEW-COMMENT":
			title = `${t("serviceDetailPage.events.newComment.title")} ${
				event.account?.contact
			}`;
			break;
		case "NEW-INTERNAL-COMMENT":
			title = `${t("serviceDetailPage.events.newInternalComment.title")} ${
				event?.account?.contact
			}`;
			break;
		case "FINANCIAL-REPROVED":
			title = t("serviceDetailPage.events.financialReproved.title");
			description = `${t(
				"serviceDetailPage.events.financialReproved.description1"
			)} ${technicalSupport?.id} ${t(
				"serviceDetailPage.events.financialReproved.description2"
			)} >> ${t("serviceDetailPage.events.financialReproved.description3")} ${
				event.text
			}`;
			break;
		case "TECHNICAL-REPROVED":
			title = t("serviceDetailPage.events.technicalReproved.title");
			description = `${t(
				"serviceDetailPage.events.technicalReproved.description1"
			)} ${technicalSupport?.id} ${t(
				"serviceDetailPage.events.technicalReproved.description2"
			)} >> ${t("serviceDetailPage.events.technicalReproved.description3")} ${
				event.text
			}`;
			break;
		case "TECHNICAL-APPROVED":
			title = `${t(
				"serviceDetailPage.events.technicalApproved.title1"
			)} ${technicalSupport?.id} ${t(
				"serviceDetailPage.events.technicalApproved.title2"
			)} ${Number(event.text)?.toLocaleString("en", {
				style: "currency",
				currency: "USD"
			})} ${t(
				"serviceDetailPage.events.technicalApproved.title3"
			)} ${technicalSupport?.replacedParts
				?.filter(
					(rp) =>
						new Date(rp.createdAt).getTime() <=
							new Date(event.createdAt).getTime() && !rp.partReplacement
				)
				?.reduce((a, b) => a + b.amount * b.historicalValue, 0)
				?.toLocaleString("en", {
					style: "currency",
					currency: "USD"
				})}`;
			description = "";
			break;
		case "FINANCIAL-APPROVED":
			title = `${t(
				"serviceDetailPage.events.financialApproved.title1"
			)} ${technicalSupport?.id} ${t(
				"serviceDetailPage.events.financialApproved.title2"
			)} ${Number(event.text)?.toLocaleString("en", {
				style: "currency",
				currency: "USD"
			})} ${t(
				"serviceDetailPage.events.financialApproved.title3"
			)} ${technicalSupport?.replacedParts
				?.filter(
					(rp) =>
						new Date(rp?.createdAt).getTime() <=
							new Date(event?.createdAt).getTime() && !rp?.partReplacement
				)
				?.reduce((a, b) => a + b?.amount * b?.historicalValue, 0)
				?.toLocaleString("en", {
					style: "currency",
					currency: "USD"
				})}`;
			description = "";
			break;
		case "EXTRA-COST":
			title = (
				<Trans>
					{t("serviceDetailPage.events.extraCost.title", {
						name: event.account?.contact,
						value: JSON.parse(event?.text)?.historicalValue?.toLocaleString(
							"en",
							{
								style: "currency",
								currency: "USD"
							}
						)
					})}
				</Trans>
			);
			description = JSON.parse(event?.text)?.reportedDefect || "";
			break;
	}

	const parts = technicalSupport?.replacedParts?.map((replacedPart) => {
		const part = { ...replacedPart, approved: "" };
		technicalSupport?.approvals?.map((approval) =>
			approval?.approvedParts?.map(
				(approvedPart) =>
					approvedPart.part.id === replacedPart?.part?.id &&
					(part.approved = new Date(
						approvedPart?.createdAt
					)?.toLocaleDateString(i18n.language, {
						timeZone: "UTC"
					}))
			)
		);
		return part;
	});

	return (
		<div className="event">
			<div className="map">
				<div className="line" />
				<div className="ball" />
			</div>
			<div className="info">
				<span className="event-date">
					{new Date(event.createdAt)?.toLocaleDateString(i18n.language, {
						timeZone: "UTC"
					})}
				</span>
				{title && <span className="event-title">{title}</span>}
				{description && (
					<>
						<span className="event-description">
							{description.split(">>")[0]}
						</span>
						{description.split(">>")[1] && (
							<span className="event-description">
								{description.split(">>")[1]}
							</span>
						)}
					</>
				)}
				{created && (
					<>
						{technicalSupport?.technician?.phone &&
							technicalSupport?.technician?.phone !== "" && (
								<div className="technician-info">
									<span className="technician-description">{`${t(
										"serviceDetailPage.events.technicalReport.description1"
									)}${technicalSupport?.technician.contact}${
										technicalSupport?.technician?.phone &&
										technicalSupport?.technician?.phone !== ""
											? `${t(
													"serviceDetailPage.events.technicalReport.description2"
												)}${technicalSupport?.technician?.phone}`
											: ""
									}${
										technicalSupport?.technician?.email &&
										technicalSupport?.technician?.email !== ""
											? `${t(
													"serviceDetailPage.events.technicalReport.description3"
												)}${technicalSupport?.technician?.email}`
											: ""
									}${t(
										"serviceDetailPage.events.technicalReport.description4"
									)}`}</span>
								</div>
							)}
						<div className="event-parts">
							<GenericTable
								data={parts
									?.filter((part) => part?.part?.code !== "extraCost")
									.map((part) => ({
										...part,
										code: `${part?.approved ? "✅" : "🔴"} ${part?.part?.code}`,
										description:
											language === "en"
												? part?.part?.descriptionEn
												: language === "es"
													? part?.part?.descriptionEs
													: part?.part?.descriptionPt,
										amount: part?.amount,
										totalValue: (
											part?.historicalValue * part?.amount
										)?.toLocaleString("en", {
											style: "currency",
											currency: "USD"
										}),
										reportedDefect: part?.reportedDefect,
										partReplacement: part?.partReplacement
											? t(
													"serviceDetailPage.events.partReplacementOptions.partReplacement"
												)
											: t(
													"serviceDetailPage.events.partReplacementOptions.credit"
												)
									}))}
								columns={[
									{
										key: "code",
										header: t("serviceDetailPage.events.tableColumns.code"),
										width: "12% "
									},
									{
										key: "description",
										header: t(
											"serviceDetailPage.events.tableColumns.description"
										),
										width: "24%"
									},
									{
										key: "amount",
										header: t("serviceDetailPage.events.tableColumns.amount"),
										width: "10%"
									},
									{
										key: "totalValue",
										header: t("serviceDetailPage.events.tableColumns.value"),
										width: "12%"
									},
									{
										key: "partReplacement",
										header: t(
											"serviceDetailPage.events.tableColumns.partReplacement"
										),
										width: "15%"
									},
									{
										key: "reportedDefect",
										header: t(
											"serviceDetailPage.events.tableColumns.reportedDefect"
										),
										width: "27%"
									}
								]}
							/>
						</div>
						{technicalSupport?.clientReport && (
							<span className="client-report-description">
								{t("serviceDetailPage.events.clientReport")}
								{technicalSupport?.clientReport}
							</span>
						)}
						{technicalSupport?.files && technicalSupport?.files?.length > 0 && (
							<div className="event-files">
								{technicalSupport?.files?.map((file, index) => (
									<a
										key={file?.id + String(index)}
										href={file?.url}
										target="_blank"
										rel="noreferrer"
									>
										{t("uploadFiles.attachment")} {index + 1}
									</a>
								))}
							</div>
						)}
					</>
				)}
				{event?.files && event?.files?.length > 0 && (
					<div className="event-files">
						{event?.files?.map((file, index) => (
							<a
								key={file?.id + String(index)}
								href={file?.url}
								target="_blank"
								rel="noreferrer"
							>
								{t("uploadFiles.attachment")} {index + 1}
							</a>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
