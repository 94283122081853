import { Button, CheckBox, Modal, Textarea } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Upload } from "..";
import { AuthContext, TechnicalSupportsContext } from "../../context";
import { UploadFile } from "../../models";
import { AddComment } from "../../models/usecases/add-comment";
import { onDeleteUpload } from "../../services/upload";
import "./styles.scss";

interface Props {
	type: "NEW-COMMENT" | "NEW-INTERNAL-COMMENT";
	onClose: () => void;
}

export const CommentModal = ({ onClose, type }: Props) => {
	const { user } = useContext(AuthContext);
	const { onAddComment, technicalSupport } = useContext(
		TechnicalSupportsContext
	);
	const [modalUploadFiles, setModalUploadFiles] = useState<boolean>(false);
	const [fileDeleted, setFileDeleted] = useState<any[]>([]);

	const canNotifyFinancialUser =
		user?.role?.split("_")[0] === "comex" ||
		user?.role?.split("_")[0] === "distributor";

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		formState: { errors }
	} = useForm<AddComment.Params>({
		defaultValues: {
			financialNotify: false,
			technicalSupportId: Number(technicalSupport?.id),
			files: []
		}
	});

	const filesForm = useForm<UploadFile, "files">();
	const filesFieldArray = useFieldArray({
		control: filesForm.control,
		name: "files"
	});

	useEffect(() => {
		reset({
			comment: "",
			files: [],
			technicalSupportId: Number(technicalSupport?.id)
		});
	}, [technicalSupport?.id]);

	const onFileDeleted = (file: any) => {
		setFileDeleted((files) => [...files, file]);
	};

	const handleAddComment = (data: AddComment.Params) => {
		if (fileDeleted.length > 0) {
			fileDeleted.forEach((file) => {
				onDeleteUpload(file);
			});
		}

		const files = filesFieldArray.fields;
		const params = {
			...data,
			type,
			files: files.map((file) => ({
				description: file.description,
				extension: file.type || file.extension,
				url: file.url
			}))
		};
		onAddComment(params, onClose);
	};

	return (
		<Modal.root>
			<Modal.header
				title={
					type === "NEW-COMMENT"
						? t("commentModal.title")
						: t("commentModal.newCommentTitle")
				}
				onClose={onClose}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="comment">
						<div className="content">
							<div className="commentInput">
								<Textarea>
									<Textarea.Label
										title={t("commentModal.commentInput.label")}
										htmlFor="comment"
									/>
									<Textarea.Field
										rows={3}
										id="comment"
										placeholder={t("commentModal.commentInput.placeholder")}
										{...register("comment", {
											required: {
												value: true,
												message: t("commentModal.commentInput.requiredAlert")
											},
											maxLength: 240
										})}
										maxLength={240}
									/>
									<Textarea.Error
										hasError={!!errors.comment}
										errorMessage={errors.comment?.message}
									/>
								</Textarea>
							</div>
							{canNotifyFinancialUser && (
								<div className="checkboxInput">
									<CheckBox
										id="financialNotify"
										name="financialNotify"
										format="square"
										variant="blue"
										onChange={(e) => setValue("financialNotify", !!e)}
									/>
									<label htmlFor="financialNotify">
										{t("commentModal.checkboxInput.label")}
									</label>
								</div>
							)}
							<div className="form-item">
								<Upload.root
									label={t("commentModal.attachmentFiles.label")}
									text={t("commentModal.attachmentFiles.supportedFiles")}
									onAddItem={() => setModalUploadFiles(true)}
								>
									{modalUploadFiles && (
										<Upload.modal
											onClose={() => {
												setModalUploadFiles(false);
											}}
											filesForm={filesForm}
											fieldFiles={filesFieldArray}
										/>
									)}
									{!!filesFieldArray?.fields?.length && (
										<Upload.files
											fieldFiles={filesFieldArray}
											{...{ onFileDeleted }}
										/>
									)}
								</Upload.root>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="actions">
						<Button
							size="lg"
							variant="confirmation-solid"
							title={t("commentModal.submitAction")}
							onClick={handleSubmit(handleAddComment)}
						/>
						<Button
							size="lg"
							variant="cancellation"
							title={t("commentModal.cancelAction")}
							onClick={onClose}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	);
};
