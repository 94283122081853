import { LoadingProgress, Modal } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SalesContext } from "../../context";
import { EditSaleRecord } from "./EditSaleRecord";
import { ViewSaleRecord } from "./ViewSaleRecord";
import "./styles.scss";

type Props = {
	saleRecordID: number;
	onClose: () => void;
	moreOptionsEdit?: boolean;
};

export const SaleRecordModal = ({
	saleRecordID,
	onClose,
	moreOptionsEdit = false
}: Props) => {
	const { isLoading, onGetSaleRecord, saleRecord, handleCleanup } =
		useContext(SalesContext);
	const [isEditing, setIsEditing] = useState(moreOptionsEdit);

	const { t } = useTranslation();

	useEffect(() => {
		if (saleRecordID) {
			onGetSaleRecord({ id: saleRecordID });
		}
	}, [saleRecordID]);

	const handleOnClose = () => {
		handleCleanup();
		onClose();
	};

	return saleRecord?.id && !isLoading ? (
		<Modal.root>
			<Modal.header
				title={t("saleRecordModal.title")}
				onClose={handleOnClose}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="modal-sale-record">
						{isEditing ? (
							<EditSaleRecord
								onClose={handleOnClose}
								onView={() => setIsEditing(false)}
							/>
						) : (
							<ViewSaleRecord onEdit={() => setIsEditing(true)} />
						)}
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	) : (
		isLoading && <LoadingProgress />
	);
};
