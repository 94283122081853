import { GenericTable, Input } from "@praticabr/ppsa";
import React, { SetStateAction, useState } from "react";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Upload } from "..";
import i18n from "../../i18n/i18n";
import { UploadFile } from "../../models";
import { AddSaleRecord } from "../../models/usecases/add-sale-record";
import { store } from "../../store";
import { StepSalesRecord } from "../../utils/JoyrideSteps";
import { JoyRide } from "../JoyRide";
type Props = {
	client: {
		name: string;
		email: string;
	};
	selectedItens: any[];
	setSelectedEquipments: (value: SetStateAction<any[]>) => void;
	saleForm: UseFormReturn<
		Pick<
			AddSaleRecord.Params,
			"distributorOrder" | "files" | "observation" | "saleDate" | "equipments"
		>,
		any,
		undefined
	>;
	itensForm: UseFormReturn<
		Pick<AddSaleRecord.Params, "equipments">,
		any,
		undefined
	>;
	filesForm: UseFormReturn<UploadFile, "files", undefined>;
	fieldFiles: UseFieldArrayReturn<UploadFile, "files", "id">;
	setFileDeleted: (value: SetStateAction<any[]>) => void;
};
export const RegisterSaleInfo = ({
	client,
	selectedItens,
	saleForm,
	itensForm,
	filesForm,
	fieldFiles,
	setFileDeleted
}: Props) => {
	const [modalUploadFiles, setModalUploadFiles] = useState(false);

	const { t } = useTranslation();

	const userStore = store.getState().auth;

	const {
		register,
		formState: { errors }
	} = saleForm;

	const onFileDeleted = (file: any) => {
		setFileDeleted((files) => [...files, file]);
	};

	function formatDateToDefaultValue(date: Date) {
		const year = date?.getFullYear();
		const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
		const day = date?.getDate()?.toString()?.padStart(2, "0");

		return `${year}-${month}-${day}`;
	}

	return (
		<div className="sale-contain">
			<div className="client">
				<div className="info">
					<span>{t("newSaleRecordModal.saleContain.clientName")}</span>
					<span>{client.name}</span>
				</div>
				<div className="info">
					<span>{t("newSaleRecordModal.saleContain.clientEmail")}</span>
					<span>{client.email}</span>
				</div>
			</div>
			<div className="selected-equipment-list">
				<GenericTable
					data={selectedItens.map((item, index) => ({
						...item,
						serialNumber: item.serialNumber,
						code: item.code,
						description:
							i18n.language === "en"
								? item.equipmentModel.descriptionEn
								: i18n.language === "es"
									? item.equipmentModel.descriptionEs
									: item.equipmentModel.descriptionPt || "-",
						installationDate: (
							<div className="installation-date-input">
								<Input
									variant="light"
									errors={
										itensForm?.formState?.errors?.equipments?.[index]
											?.installationDate
									}
									placeholder={t(
										"newSaleRecordModal.saleContain.saleDateInput.placeholder"
									)}
									type="date"
									{...itensForm.register(
										`equipments.${index}.installationDate`,
										{
											required: !modalUploadFiles
										}
									)}
								/>
							</div>
						)
					}))}
					columns={[
						{
							key: "serialNumber",
							header: t(
								"newSaleRecordModal.saleContain.tableColumns.serialNumber"
							),
							width: "15%"
						},
						{
							key: "code",
							header: t("newSaleRecordModal.saleContain.tableColumns.code"),
							width: "15%"
						},
						{
							key: "description",
							header: t(
								"newSaleRecordModal.saleContain.tableColumns.description"
							),
							width: "50%"
						},
						{
							key: "installationDate",
							header: t(
								"newSaleRecordModal.saleContain.tableColumns.installationDate"
							),
							width: "20%"
						}
					]}
				/>
			</div>
			<div className="form">
				<div className="form-item">
					<label htmlFor="saleDate">
						{t("newSaleRecordModal.saleContain.saleDateInput.label")}
					</label>
					<Input
						variant="light"
						placeholder={t(
							"newSaleRecordModal.saleContain.saleDateInput.placeholder"
						)}
						type="date"
						defaultValue={new Date().toISOString().split("T")[0]}
						errors={errors.saleDate}
						{...register("saleDate", {
							required: {
								value: true,
								message: t(
									"newSaleRecordModal.saleContain.saleDateInput.requiredAlert"
								)
							}
						})}
					/>
				</div>
				<div className="form-item">
					<label htmlFor="observation">
						{t("newSaleRecordModal.saleContain.observationInput.label")}
					</label>
					<Input
						variant="light"
						placeholder={t(
							"newSaleRecordModal.saleContain.observationInput.placeholder"
						)}
						errors={errors.observation}
						{...register("observation")}
					/>
				</div>
				<div className="form-item">
					<label htmlFor="distributorOrder">
						{t("newSaleRecordModal.saleContain.distributorOrderInput.label")}
					</label>
					<Input
						variant="light"
						placeholder={t(
							"newSaleRecordModal.saleContain.distributorOrderInput.placeholder"
						)}
						errors={errors.distributorOrder}
						{...register("distributorOrder")}
					/>
				</div>
				<div className="form-item" id="joyride-sales-info">
					<Upload.root
						label={t("uploadFiles.salesDocuments")}
						text={t("uploadFiles.filesAccepted")}
						onAddItem={() => {
							filesForm.clearErrors();
							itensForm.clearErrors();
							setModalUploadFiles(true);
						}}
					>
						{modalUploadFiles && (
							<Upload.modal
								onClose={() => setModalUploadFiles(false)}
								filesForm={filesForm}
								fieldFiles={fieldFiles}
							/>
						)}
						<Upload.files fieldFiles={fieldFiles} {...{ onFileDeleted }} />
					</Upload.root>
				</div>
			</div>
			{!userStore.tutorials?.some((e: any) => e.id === 2) &&
				userStore.role === "distributor_user" && (
					<JoyRide
						steps={StepSalesRecord}
						run={true}
						id={2}
						backButton={true}
					/>
				)}
		</div>
	);
};
