import { Search } from "@praticabr/ppsa";
import React from "react";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";

type Props = {
	text: string;
	onChangeValues: (value: string) => void;
};

export const SearchHeader = ({ text, onChangeValues }: Props) => {
	return (
		<Search.root>
			<Search.icon>
				<SearchSvg />
			</Search.icon>
			<Search.input {...{ onChangeValues }} placeholder={text} />
		</Search.root>
	);
};
