import { t } from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ArrowIcon from "../../../assets/SvgIcons/ArrowIcon";
import { AuthContext } from "../../../context";
import { history } from "../../../routes/history";
import { updateLanguage } from "../../../store/modules/auth/actions";
import "./styles.scss";

interface Params {
	handleClose: () => void;
	options: any[];
}

export const UserLanguageSelector = ({ options, handleClose }: Params) => {
	const { onUpdateUserLanguage, user } = useContext(AuthContext);
	const { i18n } = useTranslation();
	const dispatch = useDispatch();

	const pathname = window.location.pathname;

	const onChangeLanguage = (language: string) => {
		user?.token && onUpdateUserLanguage({ language });

		i18n.changeLanguage(language);
		dispatch(updateLanguage(language));
		history.replace(`/${language}${pathname.replace(/^\/[a-zA-Z0-9]+/, "")}`);
	};

	return (
		<>
			<div
				className="language-area-selector-item arrow-icon"
				onClick={handleClose}
			>
				<ArrowIcon fill="var(--primary-pure)" style={{ height: "10px" }} />
				<span>{t("navbar.userDropdown.changeLanguage")}</span>
			</div>
			{options.map((option, index) => (
				<div
					key={index}
					className="language-area-selector-item"
					onClick={() => onChangeLanguage(option.value)}
				>
					<span>{option.label}</span>
				</div>
			))}
		</>
	);
};
