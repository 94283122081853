import { GenericTable, LoadingProgress, Paginator } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CircularDownloadSvg from "../../assets/SvgIcons/CircularDownloadSvg";
import CreditSvg from "../../assets/SvgIcons/CreditSvg";
import PartialSvg from "../../assets/SvgIcons/PartialSvg";
import { HeaderSection } from "../../components";
import { CreditDetailsViewModal } from "../../components/CreditDetailsViewModal";
import { CreditContext } from "../../context/CreditContext";
import i18n from "../../i18n/i18n";
import "./styles.scss";

export const CreditsList = () => {
	const [legendSelect, setLegendSelect] = useState<string>("");
	const [showModal, setShowModal] = useState<boolean>(false);
	const [dataCredit, setDataCredit] = useState<any>({});

	const {
		list,
		total,
		offset,
		isLoading,
		onPageChange,
		onSearchParams,
		onLegendChange
	} = useContext(CreditContext);

	const isDistributor =
		useSelector((state: any) => state.auth.role) === "distributor_user";

	const { t } = useTranslation();

	const legends = {
		openCredit: {
			key: "OPEN",
			status: <CreditSvg />,
			label: t("creditsPage.legends.open")
		},
		applied: {
			key: "CONSUMED",
			status: <CircularDownloadSvg />,
			label: t("creditsPage.legends.applied")
		},
		partialOpen: {
			key: "PARTIAL",
			status: <PartialSvg />,
			label: t("creditsPage.legends.partlyOpen")
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const legend = urlParams.get("legends") as
			| "applied"
			| "openCredit"
			| "partialOpen";
		if (legend) {
			setTimeout(() => {
				setLegendSelect(legends[legend].key);
			}, 100);
		}
	}, []);

	useEffect(() => {
		onLegendChange(legendSelect);
	}, [legendSelect]);

	const onSearch = (search: string) => {
		onSearchParams(search);
	};

	const statusChange = (value: number, balance: number) => {
		if (balance <= 1) {
			return "applied";
		}
		if (value === balance) {
			return "openCredit";
		} else {
			return "partialOpen";
		}
	};

	const getCreditId = (value: string) => {
		const lastChar = value[value.length - 1];

		const isNumber = /[0-9]/.test(lastChar);

		if (isNumber) {
			return value;
		} else {
			return value.slice(0, value.length - 1)[0];
		}
	};

	return (
		<>
			<article className="credits-list">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={(search) => {
							onSearch(search);
						}}
						text={t("creditsPage.searchHeader")}
					/>
					<HeaderSection.legend>
						{Object.values(legends).map((legend) => {
							return (
								<div
									className="legend"
									key={legend.key}
									onClick={() =>
										setLegendSelect((prevLegend) =>
											prevLegend === legend.key ? "" : legend.key
										)
									}
								>
									{legend.status}
									<span
										className={`${legendSelect === legend.key && "active"}`}
									>
										{legend.label}
									</span>
								</div>
							);
						})}
					</HeaderSection.legend>
				</HeaderSection.root>
				<div className="credits-table">
					<GenericTable
						// onRowClicked={(e: any) => {
						// 	if (statusChange(e?.value, e?.balance) === "openCredit") {
						// 		toast.info(t("creditDetailsModal.noExistsCredit"));
						// 	} else {
						// 		setDataCredit({
						// 			creditId: getCreditId(e?.externalId.slice(5, 14)),
						// 			externalId: e?.externalId,
						// 			distributorCode: e?.distributorId.split(" ")[0],
						// 			totalValue: e?.value,
						// 			totalBalance: e?.balance,
						// 			serviceOrderId: e?.supportId
						// 		});
						// 		setShowModal(true);
						// 	}
						// }}
						data={
							list?.credits && [
								...list.credits.map((credit) => ({
									status:
										legends[statusChange(credit?.value, credit?.balance)]
											?.status,
									id: credit?.id,
									supportId: credit?.approval?.technicalSupport?.id
										? credit?.approval?.technicalSupport?.id
										: "-",
									createdAt: new Date(credit?.createdAt).toLocaleDateString(
										i18n.language,
										{
											timeZone: "UTC"
										}
									),
									distributorId: `${credit?.distributor?.codeProtheus || ""} - ${credit?.distributor?.name || ""}`,
									client: credit?.approval?.technicalSupport?.equipment
										?.salesRecord?.client?.name
										? credit?.approval?.technicalSupport?.equipment?.salesRecord
												?.client?.name
										: "-",
									observation: credit?.observation || "-",
									value: credit?.value?.toLocaleString("en", {
										style: "currency",
										currency: "USD"
									}),
									externalId: credit?.externalId,
									balance:
										credit?.balance <= 1
											? 0
											: credit?.balance?.toLocaleString("en", {
													style: "currency",
													currency: "USD"
												})
								}))
							]
						}
						columns={
							isDistributor
								? [
										{
											key: "status",
											header: "",
											width: "32px"
										},
										{
											key: "supportId",
											header: t("creditsPage.tableColumns.creditId"),
											width: "calc( 13% - 32px)"
										},
										{
											key: "createdAt",
											header: t("creditsPage.tableColumns.createdAt"),
											width: "10%"
										},
										{
											key: "client",
											header: t("creditsPage.tableColumns.client"),
											width: "12%"
										},
										{
											key: "observation",
											header: t("creditsPage.tableColumns.observation"),
											width: "40%"
										},
										{
											key: "value",
											header: t("creditsPage.tableColumns.value"),
											width: "10%"
										},
										{
											key: "balance",
											header: t("creditsPage.tableColumns.balance"),
											width: "10%"
										}
									]
								: [
										{
											key: "status",
											header: "",
											width: "32px"
										},
										{
											key: "supportId",
											header: t("creditsPage.tableColumns.creditId"),
											width: "calc( 13% - 32px)"
										},
										{
											key: "createdAt",
											header: t("creditsPage.tableColumns.createdAt"),
											width: "10%"
										},
										{
											key: "distributorId",
											header: t("creditsPage.tableColumns.distributorId"),
											width: "15%"
										},
										{
											key: "client",
											header: t("creditsPage.tableColumns.client"),
											width: "17%"
										},
										{
											key: "observation",
											header: t("creditsPage.tableColumns.observation"),
											width: "25%"
										},
										{
											key: "value",
											header: t("creditsPage.tableColumns.value"),
											width: "10%"
										},
										{
											key: "balance",
											header: t("creditsPage.tableColumns.balance"),
											width: "10%"
										}
									]
						}
					/>
					{!!list?.credits?.length && (
						<div className="total-value">
							<p>TOTAL:</p>
							<p>{list?.totalValue?.toLocaleString("en")}</p>
							<p>{list?.totalBalance?.toLocaleString("en")}</p>
						</div>
					)}
				</div>
				{!!list?.credits?.length && (
					<Paginator
						totalPages={total}
						currentPage={offset}
						onPageChange={onPageChange}
					/>
				)}
			</article>
			{isLoading && <LoadingProgress />}
			{showModal && (
				<CreditDetailsViewModal
					onClose={() => setShowModal(false)}
					data={dataCredit}
				/>
			)}
		</>
	);
};
