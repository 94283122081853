import { useDispatch } from "react-redux";
import { DistributorModel } from "../../../models/distributor";
import { updateNotificationsTypes } from "../../../models/usecases";

export const useSignInRequest = () => {
	const dispatch = useDispatch();

	const signInRequest = async ({
		email,
		password
	}: {
		email: string;
		password: string;
	}) => {
		return new Promise((resolve, reject) => {
			try {
				dispatch({
					type: "@auth/SIGN_IN_REQUEST",
					payload: { email, password },
					resolve,
					reject
				});
			} catch (error) {
				reject(error);
			}
		});
	};

	return signInRequest;
};

export const signInSuccess = ({
	token,
	contact,
	id,
	distributorId,
	email,
	language,
	phone,
	role,
	tutorials,
	availableDistributors,
	notificationManager,
	allowEmailNotification
}: {
	token: string;
	contact: string;
	id: number;
	distributorId: number;
	email: string;
	language: string;
	phone: string;
	role: string;
	tutorials: Array<number>;
	availableDistributors: {
		distributor: Omit<
			DistributorModel,
			"accounts" | "createdAt" | "equipments" | "updatedAt"
		>;
	}[];
	notificationManager: { notificationTypeId: number }[];
	allowEmailNotification: boolean;
}) => ({
	type: "@auth/SIGN_IN_SUCCESS",
	payload: {
		token,
		contact,
		id,
		distributorId,
		email,
		language,
		phone,
		role,
		tutorials,
		availableDistributors,
		notificationManager: notificationManager?.map(
			(notification) => notification?.notificationTypeId
		),
		allowEmailNotification
	}
});

export const signOut = () => ({
	type: "@auth/SIGN_OUT"
});

export function updateLanguage(language: string) {
	return {
		type: "@auth/SET_LANGUAGE",
		payload: language
	};
}
export function updateDistributor(distributorId: number) {
	return {
		type: "@auth/SET_DISTRIBUTOR",
		payload: distributorId
	};
}

export function updateTutorials(tutorials: Array<number>) {
	return {
		type: "@auth/SET_TUTORIALS",
		payload: tutorials
	};
}

export function updateNotificationManager(
	notificationManager: updateNotificationsTypes.Return
) {
	return {
		type: "@auth/SET_UPDATE_NOTIFICATION_MANAGER",
		payload: notificationManager
	};
}
