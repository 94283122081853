import React from "react";

interface Props {}

const SearchSvg: React.FC<Props> = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 25 30"
			fill="none"
		>
			<path
				d="M24.5088 9.70017C24.8101 11.5882 24.5807 13.5228 23.8462 15.288C23.1117 17.0532 21.901 18.5796 20.3494 19.6966C18.7977 20.8136 16.9661 21.4774 15.059 21.6138C13.152 21.7502 11.2446 21.3539 9.54971 20.4691L3.60282 28.6574C3.3636 28.9825 3.00545 29.1998 2.6066 29.2617C2.20774 29.3236 1.80058 29.2252 1.47404 28.988C1.14749 28.7507 0.928085 28.3939 0.863736 27.9954C0.799387 27.597 0.895323 27.1892 1.13059 26.8612L7.07948 18.6743C5.91439 17.53 5.03744 16.1255 4.52069 14.5763C4.00394 13.0271 3.86215 11.3774 4.10695 9.76277C4.35175 8.14811 4.97617 6.61459 5.92889 5.28818C6.88162 3.96178 8.13546 2.88036 9.5874 2.13277C11.0393 1.38518 12.6479 0.992758 14.281 0.987747C15.9141 0.982736 17.5251 1.36528 18.9816 2.10395C20.4381 2.84261 21.6986 3.91632 22.6594 5.23685C23.6203 6.55738 24.2541 8.08705 24.5088 9.70017ZM21.5958 10.1615C21.411 8.98809 20.9454 7.87666 20.2387 6.92183C19.532 5.96699 18.6051 5.19699 17.5368 4.67735C16.4686 4.15772 15.2907 3.90381 14.1032 3.93725C12.9158 3.97069 11.754 4.29048 10.7167 4.86941C9.67937 5.44834 8.79727 6.26927 8.14542 7.26237C7.49357 8.25547 7.09126 9.39133 6.97273 10.5733C6.85419 11.7553 7.02294 12.9484 7.4646 14.0512C7.90627 15.154 8.60779 16.1337 9.50947 16.9071L10.4786 17.6112C11.6804 18.3456 13.0675 18.7202 14.4756 18.6908C15.8838 18.6614 17.254 18.2291 18.4241 17.4452C19.5943 16.6612 20.5152 15.5584 21.0781 14.2673C21.6409 12.9762 21.8221 11.5508 21.6 10.16L21.5958 10.1615Z"
				fill="black"
			/>
		</svg>
	);
};

export default SearchSvg;
