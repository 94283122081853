import { DefaultLayoutRoot } from "./DefaultLayout";
import { HeaderActions } from "./HeaderActions";
import { HeaderArticle } from "./HeaderArticle";
import { SectionArticle } from "./SectionArticle";

export const DefaultLayout = {
	root: DefaultLayoutRoot,
	headerArticle: HeaderArticle,
	sectionArticle: SectionArticle,
	headerActions: HeaderActions
};
