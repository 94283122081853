import {
	Button,
	GenericTable,
	Modal,
	Paginator,
	Search
} from "@praticabr/ppsa";
import React, { SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { EquipmentContext } from "../../context";
import i18n from "../../i18n/i18n";
import "./styles.scss";

interface EquipmentSearchProps {
	onClose: (value: boolean) => void;
	previousSelectedItens: any[];
	onConfirmSetSelectedEquipments: (value: SetStateAction<any[]>) => void;
	distributorId?: number;
}

export const EquipmentSearch = ({
	distributorId,
	onClose,
	previousSelectedItens,
	onConfirmSetSelectedEquipments
}: EquipmentSearchProps) => {
	const {
		list,
		onSearchParams,
		total,
		offset,
		onPageChange,
		isLoading,
		onSearchByDistributor,
		onGetEquipmentsList
	} = useContext(EquipmentContext);
	const [selectedItens, setSelectedEquipments] = useState<any[]>([
		...previousSelectedItens
	]);

	useEffect(() => {
		onGetEquipmentsList();
	}, []);

	const { t } = useTranslation();

	useEffect(() => {
		distributorId && onSearchByDistributor(distributorId);
	}, [distributorId]);

	useEffect(() => {
		setSelectedEquipments(previousSelectedItens);
	}, [previousSelectedItens]);

	const handleSelectItems = (item: any) => {
		const currentIndex = selectedItens.findIndex(
			(equipment) => equipment.id === item.id && equipment.code === item.code
		);

		const newChecked = [...selectedItens];

		if (currentIndex === -1) {
			newChecked.push(item);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setSelectedEquipments(newChecked);
	};

	return (
		<Modal.root>
			<Modal.header
				title={t("searchEquipmentModal.title")}
				onClose={() => {
					onClose(false);
				}}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="equipment-search-modal">
						<div className="search">
							<Search.root>
								<Search.icon>
									<SearchSvg />
								</Search.icon>
								<Search.input
									onChangeValues={onSearchParams}
									placeholder={t("searchEquipmentModal.searchPlaceholder")}
								/>
							</Search.root>
						</div>
						<div className="list">
							<GenericTable
								onRowClicked={handleSelectItems}
								data={list.equipments?.map((equipment, index) => ({
									...equipment,
									code: equipment.equipmentModel.code,
									serialNumber: equipment.serialNumber,
									description:
										i18n.language === "en"
											? equipment.equipmentModel.descriptionEn
											: i18n.language === "es"
												? equipment.equipmentModel.descriptionEs
												: equipment.equipmentModel.descriptionPt || "-",
									installationDate: null
								}))}
								columns={[
									{
										key: "serialNumber",
										checkbox: true,
										header: t("searchEquipmentModal.tableColumns.serialNumber"),
										width: "20%",
										selected: selectedItens
									},
									{
										key: "code",
										header: t("searchEquipmentModal.tableColumns.code"),
										width: "25%"
									},
									{
										key: "description",
										header: t("searchEquipmentModal.tableColumns.description"),
										width: "55%"
									}
								]}
							/>
						</div>
						<div
							className="pagination"
							style={{
								height: "25px",
								marginTop: "20px",
								marginBottom: "20px",
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<Paginator
								totalPages={total}
								currentPage={offset}
								onPageChange={onPageChange}
							/>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="actions">
						<Button
							title={t("searchEquipmentModal.submitAction")}
							size="lg"
							variant="confirmation-solid"
							onClick={() => onConfirmSetSelectedEquipments(selectedItens)}
						/>
						<Button
							title={t("searchEquipmentModal.cancelAction")}
							size="lg"
							variant="cancellation"
							onClick={() => onClose(false)}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	);
};
