import { Input } from "@praticabr/ppsa";
import React, { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { forgotPasswordRequest } from "../../models/usecases/users";
import "./styles.scss";

type Props = {
	children: ReactNode;
	form: UseFormReturn<forgotPasswordRequest>;
};

export const RecoverPasswordContain = ({ children, form }: Props) => {
	const { t } = useTranslation();

	const {
		register,
		formState: { errors }
	} = form;

	return (
		<div className="reset-pass-form">
			<form className="main-form-content">
				<div>
					<div className="form-item-content">
						<p>{t("loginPage.loginInput.label")}</p>
						<Input
							variant="light"
							placeholder={t("loginPage.loginInput.placeholder")}
							{...register("email", {
								required: {
									value: true,
									message: t("loginPage.loginInput.requiredAlert")
								},
								pattern: {
									value:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: t("saleRecordModal.form.emailInput.patternAlert")
								}
							})}
							errors={errors.email}
						/>
					</div>
				</div>
				<div className="actions">{children}</div>
			</form>
		</div>
	);
};
