import React from "react";
import "./styles.scss";

type TParams = {
	children?: React.ReactNode;
};

export const SectionArticle = ({ children }: TParams) => {
	return <div className="section">{children}</div>;
};
