import { Step } from "react-joyride";

export const StepsMainFunctions: Step[] = [
	{
		title: "joyrideStep.mainFunctions.stepOne.title",
		content: "joyrideStep.mainFunctions.stepOne.content",
		target: "#joyride-ringbell",
		placement: "left",
		disableOverlayClose: true
	},
	{
		title: "joyrideStep.mainFunctions.stepTwo.title",
		content: "joyrideStep.mainFunctions.stepTwo.content",
		target: "#joyride-dashboard",
		placement: "right",
		disableOverlayClose: true
	},
	{
		title: "joyrideStep.mainFunctions.stepThree.title",
		content: "joyrideStep.mainFunctions.stepThree.content",
		target: "#joyride-salesrecord",
		placement: "right",
		disableOverlayClose: true
	},
	{
		title: "joyrideStep.mainFunctions.stepFour.title",
		content: "joyrideStep.mainFunctions.stepFour.content",
		target: "#joyride-assistancerecords",
		placement: "right",
		disableOverlayClose: true
	},
	{
		title: "joyrideStep.mainFunctions.stepFive.title",
		content: "joyrideStep.mainFunctions.stepFive.content",
		target: "#joyride-credits",
		placement: "right",
		disableOverlayClose: true
	},
	{
		title: "joyrideStep.mainFunctions.stepSix.title",
		content: "joyrideStep.mainFunctions.stepSix.content",
		target: "#joyride-technicalmaterials",
		placement: "right",
		disableOverlayClose: true
	}
];

export const StepSalesRecord: Step[] = [
	{
		title: "joyrideStep.salesRecord.stepOne.title",
		content: "joyrideStep.salesRecord.stepOne.content",
		target: "#joyride-sales-registration",
		placement: "top",
		disableOverlayClose: true
	},
	{
		title: "joyrideStep.salesRecord.stepTwo.title",
		content: "joyrideStep.salesRecord.stepTwo.content",
		target: "#joyride-sales-info",
		placement: "top",
		disableOverlayClose: true
	}
];

export const StepServices: Step[] = [
	{
		title: "joyrideStep.services.stepOne.title",
		content: "joyrideStep.services.stepOne.content",
		target: "#joyride-support-inclusion",
		placement: "top",
		disableOverlayClose: true
	},
	{
		title: "joyrideStep.services.stepTwo.title",
		content: "joyrideStep.services.stepTwo.content",
		target: "#joyride-support-info-tech",
		placement: "top",
		disableOverlayClose: true
	},
	{
		title: "joyrideStep.services.stepThree.title",
		content: "joyrideStep.services.stepThree.content",
		target: "#joyride-support-parts",
		placement: "top",
		disableOverlayClose: true
	}
];

export const StepDetailsServices: Step[] = [
	{
		title: "joyrideStep.detailsServices.stepOne.title",
		content: "joyrideStep.detailsServices.stepOne.content",
		target: "#joyride-support-timeline",
		placement: "left",
		disableOverlayClose: true
	}
];

export const handleStepTitles = (steps: any[]) =>
	`"${steps.map(
		(step: { title: { props: any } }) => step?.title?.props?.children
	)}"`;
