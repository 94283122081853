import React from "react";
import "./styles.scss";

interface SvgProps extends React.SVGProps<SVGSVGElement> {
	width: string;
	height: string;
	fill?: string;
	id?: string;
}

const AddIcon: React.FC<SvgProps> = ({ fill }) => {
	return (
		<svg
			width="30"
			fill="none"
			height="30"
			viewBox="0 0 30 30"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 29.4999C13.0293 29.5053 11.0771 29.1329 9.25637 28.404C7.43567 27.675 5.78264 26.604 4.39299 25.2533C2.99569 23.91 1.88803 22.3122 1.13397 20.5522C0.379903 18.7922 -0.00552646 16.905 5.98657e-05 15C-0.00526313 13.0951 0.380344 11.2081 1.13458 9.44829C1.88881 7.68845 2.99673 6.09075 4.39409 4.74761C5.78354 3.39683 7.43633 2.32608 9.25686 1.59699C11.0774 0.8679 13.0294 0.49491 15 0.500055C16.9707 0.494782 18.9229 0.867424 20.7436 1.59652C22.5643 2.32561 24.2174 3.39672 25.607 4.74761C27.0042 6.09083 28.1119 7.68846 28.8659 9.44829C29.62 11.2081 30.0054 13.0952 29.9999 15C30.0053 16.9049 29.6197 18.7919 28.8654 20.5517C28.1112 22.3115 27.0033 23.9092 25.6059 25.2524C24.2165 26.6032 22.5637 27.6739 20.7431 28.403C18.9226 29.1321 16.9706 29.5051 15 29.4999ZM9.00002 14.0333C8.73481 14.0333 8.48041 14.1353 8.29287 14.3165C8.10534 14.4978 8.00003 14.7436 8.00003 15C8.00003 15.2564 8.10534 15.5022 8.29287 15.6835C8.48041 15.8647 8.73481 15.9667 9.00002 15.9667H14V20.8C14 21.0564 14.1053 21.3022 14.2929 21.4834C14.4804 21.6647 14.7348 21.7666 15 21.7666C15.2652 21.7666 15.5196 21.6647 15.7072 21.4834C15.8947 21.3022 16 21.0564 16 20.8V15.9667H21C21.2652 15.9667 21.5196 15.8647 21.7071 15.6835C21.8947 15.5022 22 15.2564 22 15C22 14.7436 21.8947 14.4978 21.7071 14.3165C21.5196 14.1353 21.2652 14.0333 21 14.0333H16V9.20002C16 8.94365 15.8947 8.69785 15.7072 8.51656C15.5196 8.33528 15.2652 8.23336 15 8.23336C14.7348 8.23336 14.4804 8.33528 14.2929 8.51656C14.1053 8.69785 14 8.94365 14 9.20002V14.0333H9.00002Z"
				fill="var(--primary-highlight)"
			/>
		</svg>
	);
};

export default AddIcon;
