import React from "react";
import { UseFieldArrayReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CloseCircleIcon from "../../assets/SvgIcons/CloseCircleIcon";
import { UploadFile } from "../../models";

type Props = {
	fieldFiles: UseFieldArrayReturn<UploadFile, "files", "id">;
	onFileDeleted: (file: any) => void;
	indexItem?: number;
};

export const UploadFiles = ({
	fieldFiles,
	onFileDeleted,
	indexItem
}: Props) => {
	const { fields, replace } = fieldFiles;

	const { t } = useTranslation();

	const onDeleteFile = (file: any, index: number) => {
		onFileDeleted(file);
		fields.splice(index, 1);
		replace(fields);
	};

	return (
		<div className="upload-file-list">
			{fields
				.filter((file, index) => {
					if (indexItem === undefined) {
						return file;
					} else {
						return file.item === indexItem;
					}
				})
				.map((file, index) => (
					<div className="upload-file-list-item" key={file.id}>
						<a rel="noreferrer" href={file.url} target="_blank" download>
							{t("uploadFiles.attachment")} {index + 1}
						</a>
						<div
							className="upload-file-list-item-remove"
							onClick={() => {
								onDeleteFile(file, index);
							}}
						>
							<CloseCircleIcon width={"10px"} height={"10px"} fill="red" />
						</div>
					</div>
				))}
		</div>
	);
};
