import React from "react";
import { useTranslation } from "react-i18next";
import CalendarSvg from "../../assets/SvgIcons/CalendarSvg";
import "./styles.scss";

type Props = {
	startDate: string;
	endDate: string;
	setStartDate: React.Dispatch<React.SetStateAction<string>>;
	setEndDate: React.Dispatch<React.SetStateAction<string>>;
};

const DateRangeSelection = ({
	startDate,
	endDate,
	setStartDate,
	setEndDate
}: Props) => {
	const { t } = useTranslation();
	return (
		<div className="date-range">
			<label htmlFor="start-date">
				{startDate ? (
					""
				) : (
					<>
						<CalendarSvg /> {t("dashboardPage.partsRegistration.input.start")}
					</>
				)}
				<input
					type="date"
					id="start-date"
					onChange={(e) => setStartDate(e.target.value)}
					style={{ opacity: startDate ? "1" : "0" }}
				/>
			</label>
			<div />
			<label>
				{endDate ? "" : t("dashboardPage.partsRegistration.input.end")}
				<input
					type="date"
					id="end-date"
					onChange={(e) => setEndDate(e.target.value)}
					style={{ opacity: endDate ? "1" : "0" }}
					min={startDate}
				/>
			</label>
		</div>
	);
};

export default DateRangeSelection;
