import React, { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context";
import { store } from "../../store";

export const UnprotectedRoutes = ({ children }: { children: ReactNode }) => {
	const { user } = useContext(AuthContext);

	const language =
		store.getState().auth.language || navigator.language?.split("-")[0] || "pt";

	return user.token ? <Navigate to={`/${language}/home`} /> : <>{children}</>;
};
